import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectCateringData,
  makeSelectCateringInfo,
  makeSelectDeliveryInfo,
  makeSelectDeliveryPeople,
  makeSelectSingleClient,
} from "./redux/selectors";
import { useInjectReducer } from "../../utils/injectReducer";
import reducer from "./redux/reducer";
import { fetchDeliveryPeople, setCateringInfo, setDeliveryInfo } from "./redux/actions";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { parseTime } from "../../utils/parseTime";

const daysOfWeek = [
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
  { id: 7, name: "Sunday" },
];

const key = "clients";

export default function DeliveryModal({ setShowDeliveryModal }) {
  const deliveryInfo = useSelector(makeSelectDeliveryInfo());
  const deliverers = useSelector(makeSelectDeliveryPeople());
  const client = useSelector(makeSelectSingleClient());

  // useEffect(() => {
  //   if (client && client.delivery === 1) {
  //     dispatch(setDeliveryInfo(client.deliveryInfo));
  //   }
  // }, [client]);


  const [deliveryDays, setDeliveryDays] = useState(
    daysOfWeek.map((day, index) => ({
      day: day.name,
      start_time:
        deliveryInfo !== 0 ? deliveryInfo.delivery_days[index]?.start_time : "",
      end_time:
        deliveryInfo !== 0 ? deliveryInfo.delivery_days[index]?.end_time : "",
    }))
  );

  useInjectReducer({ key, reducer });

  const dispatch = useDispatch();


  //const [cateringData, setCateringData] = useState();
  //console.log(cateringData);

  const [delivery_note, setNote] = useState(deliveryInfo.delivery_note);
  const [delivery_phone, setContact] = useState(deliveryInfo.delivery_phone);

  const handleDayTimeChange = (dayName, startOrEnd, value) => {
    const formattedValue = dayjs(value).format("HH:mm");

    setDeliveryDays((current) =>
      current.map((day) =>
        day.day === dayName ? { ...day, [startOrEnd]: formattedValue } : day
      )
    );
    const data = {
      delivery: 1,
      delivery_days: deliveryDays,
      delivery_note,
      delivery_phone,
    };
    //console.log("handleDayTimeChange", data);
    handleAddData(data);
  };

  useEffect(() => {
    dispatch(fetchDeliveryPeople())
  }, [dispatch]);


  const handleAddData = useCallback(
    (...args) => dispatch(setDeliveryInfo(...args)),
    [dispatch]
  );

  const handleSubmit = () => {
    const data = {
      delivery: 1,
      delivery_days: deliveryDays,
      delivery_note,
      delivery_phone,
      couriers: couriers,
    };

    //console.log(data);
    handleAddData(data);
    setShowDeliveryModal(false);
  };

  const [couriers, setCouriers] = useState(deliveryInfo.couriers || []);

  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="fixed z-20 inset-0 bg-black bg-opacity-50 flex justify-center items-center cursor-default">
        <div className="relative flex flex-col w-[800px] rounded-lg gap-8 bg-white p-12">
          <span
            className="absolute top-2 right-4 cursor-pointer text-xl"
            onClick={() => setShowDeliveryModal(false)}
          >
            &times;
          </span>
          <h2 className="text-center text-2xl">Dostava</h2>
          <div className="flex gap-6">
            <div className="w-1/2">
              <h3>Radno vreme</h3>
              {daysOfWeek.map((day, index) => (
                <div
                  key={day.id}
                  className="grid grid-cols-3 items-center gap-2 my-2 w-full"
                >
                  <label>{day.name}</label>
                  <TimePicker
                    ampm={false}
                    value={parseTime(deliveryDays[index].start_time)}
                    onChange={(newValue) =>
                      handleDayTimeChange(day.name, "start_time", newValue)
                    }
                  />
                  <TimePicker
                    ampm={false}
                    value={parseTime(deliveryDays[index].end_time)}
                    onChange={(newValue) =>
                      handleDayTimeChange(day.name, "end_time", newValue)
                    }
                  />
                  {/* <input
                  value={
                    deliveryInfo.delivery === 1
                      ? deliveryInfo.delivery_days[index].start_time
                      : deliveryDays[index].start_time
                  }
                  type="time"
                  placeholder="od"
                  className="flex items-center border border-black border-opacity-20 rounded-md pl-2 text-black text-opacity-30 h-10"
                  onChange={(e) =>
                    handleDayTimeChange(day.name, "start_time", e.target.value)
                  }
                ></input>
                <input
                  value={
                    deliveryInfo.catering === 1
                      ? deliveryInfo.delivery_days[index].end_time
                      : deliveryDays[index].end_time
                  }
                  type="time"
                  placeholder="do"
                  className="flex items-center border border-black border-opacity-20 rounded-md pl-2 text-black text-opacity-30 h-10"
                  onChange={(e) =>
                    handleDayTimeChange(day.name, "end_time", e.target.value)
                  }
                ></input> */}
                </div>
              ))}
            </div>
            <div className="w-1/2">
              <h3>Napomena</h3>
              <textarea
                className="border p-1 w-full h-1/2"
                value={delivery_note}
                onChange={(e) => setNote(e.target.value)}
              ></textarea>
              <h3>Kontakt telefon za dostavu</h3>
              <input
                type="text"
                className="border p-1 w-full"
                value={delivery_phone}
                onChange={(e) => setContact(e.target.value)}
              />
              <h3 className="mt-2">Dostavljači</h3>
              <div className="flex flex-col h-32 overflow-y-scroll p-2 rounded-xl shadow overflow-hidden border">
                {deliverers?.length && deliverers?.map((deliverer) => (
                  <div className="flex gap-2 items-center">
                    <input
                      type="checkbox"
                      defaultChecked={couriers?.map(i=>i.id)?.includes(deliverer.id) ? true : false}
                      value={couriers?.map(i=>i.id)?.includes(deliverer.id) ? true : false} 
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCouriers([
                            ...couriers,
                            deliverer,
                          ]);
                        } else {
                          setCouriers(
                            couriers.filter(
                              (courierId) => courierId.id !== deliverer.id
                            )
                          );
                        }
                      }}
                    />
                    <label>{deliverer.name}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button className="btn-primary mx-auto" onClick={handleSubmit}>
            Dodaj
          </button>
        </div>
      </div>
    </LocalizationProvider>
  );
}
