import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "../../utils/injectReducer";
import { useInjectSaga } from "../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import {
  clearSingleCategory,
  deleteCategory,
  fetchSingleCategory,
  setClientCategoriesIds,
  updateCategory,
} from "./redux/actions";
import EditCategory from "./editCategory";
import {
  makeSelectClientCategoriesIds,
  makeSelectClientConnect,
  makeSelectClients,
} from "./redux/selectors";
import { makeSelectUser } from "../../containers/App/selectors";

const key = "categories";

const TreeNode = ({ node, data, onEditClick, connect }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const [showEditCategory, setShowEditCategory] = useState(false);
  const [category_id, setCategory_id] = useState(null);

  const clients = useSelector(makeSelectClients()).data;
  const connectedClientId = useSelector(makeSelectClientConnect());
  const user = useSelector(makeSelectUser());

  let className;

  if (connect && clients) {
    const client = clients.find((c) => c.id === connectedClientId);
    //console.log("SELECTED CLIENT TREE NODE", client);

    const isNodeInCategories = client.categories.some(
      (category) => category.id === node.id
    );

    // Define the class name based on the condition
    className = isNodeInCategories ? "text-green-500 w-full " : "w-full";
  }

  const children = data.filter((item) => item.parent_id === node.id);

  const hasChildren = children.length > 0; // Check if the node has children

  const toggleChildren = () => {
    if (hasChildren) {
      setIsVisible(!isVisible);
    }
  };

  const handleDelete = useCallback(
    (...args) => dispatch(deleteCategory(...args)),
    [dispatch]
  );

  const handleEditCategory = useCallback(
    (...args) => dispatch(updateCategory(...args)),
    [dispatch]
  );

  function handleClose() {
    setShowEditCategory(false);
  }

  // const handleClick = (id) => {
  //   handleAddData(id);
  // };
  const getDescendantIds = (parentId) => {
    const descendants = data.filter((item) => item.parent_id === parentId);
    let descendantIds = [];

    for (const descendant of descendants) {
      descendantIds.push(descendant.id);
      descendantIds = [...descendantIds, ...getDescendantIds(descendant.id)];
    }

    return descendantIds;
  };

  const handleClick = (id) => {
    const descendantIds = getDescendantIds(id);
    handleAddData([...descendantIds, id]);
  };

  const handleAddData = useCallback(
    (...args) => dispatch(setClientCategoriesIds(...args)),
    [dispatch]
  );

  const clientCategoriesIds = useSelector(makeSelectClientCategoriesIds());

  function hasIdInChildren(obj, targetIds) {
    if (targetIds.includes(obj.id)) {
      return true;
    }

    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (hasIdInChildren(obj[key], targetIds)) {
          return true;
        }
      }
    }

    return false;
  }

  // useEffect(() => {
  //   dispatch(fetchSingleCategory(category_id));

  //   return () => dispatch(clearSingleCategory());
  // }, [dispatch, category_id]);

  // function handleEditClick(node) {
  //   onEditClick(node);
  //   console.log("EDIT:", node);
  // }

  return (
    <div className="bg-white categories_table relative">
      <div className="bg-white flex items-center justify-between p-5 cursor-pointer text-sm text-black border-b border-black border-opacity-20">
        <div className="flex items-center w-full">
          {connect && (
            <input
              type="checkbox"
              checked={clientCategoriesIds.includes(node.id)}
              className="absolute left-2 z-10"
              onClick={() => handleClick(node.id)}
            ></input>
          )}

          {hasChildren ? (
            isVisible ? (
              <MdKeyboardArrowUp size={20} onClick={toggleChildren} />
            ) : (
              <MdKeyboardArrowDown size={20} onClick={toggleChildren} />
            )
          ) : (
            <BsDashLg className="mr-1" />
          )}
          <p onClick={toggleChildren} className={connect ? className : ""}>
            {" "}
            {node.name}
          </p>
        </div>
        {!connect && (
          <div className="flex gap-3">
            <FiEdit
              size={20}
              color="gray"
              onClick={() => {
                setShowEditCategory(!showEditCategory);
                setCategory_id(node.id);
              }}
              className="z-20"
            />
            {user.roles[0] !== "Agent" && user.roles[0] !== "Korisnik" && (
              <RiDeleteBin6Line
                size={20}
                color="gray"
                onClick={() => handleDelete(node.id)}
              />
            )}
          </div>
        )}
      </div>
      {isVisible && children.length > 0 && (
        <div className="children ml-8 ">
          {children.map((child) => (
            <TreeNode
              key={child.id}
              node={child}
              data={data}
              connect={connect}
            />
          ))}
        </div>
      )}
      {showEditCategory && (
        <div>
          <EditCategory
            categories={data}
            setShowEditCategory={handleClose}
            category_id={category_id}
            //onEditCategory={handleEditCategory}
          />
        </div>
      )}
    </div>
  );
};

export default TreeNode;
