import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../../utils/request";
import {
  addClientError,
  addClientSuccess,
  createLogo,
  createMainPhoto,
  createPricelist,
  createQRCode,
  fetchClients,
  fetchSingleClient,
  getPhotos,
  sendPhotosRequest,
  setAttributes,
  setCateringInfo,
  setClients,
  setFacilityTypes,
  setKitchenTypes,
  setPaymentTypes,
  setPhotos,
  setSingleClient,
  setUsers,
  updateClientError,
  updateClientSuccess,
} from "./actions";
import types from "./constants";
import { type } from "@testing-library/user-event/dist/type";
import parseApiErrorsToFormik from "../../../utils/parseApiErrorsToFormik";
import { enqueueNotification } from "../../../notifications/actions";

const BASE_URL = "admin/clients";

export function* getClients({ payload }) {
  try {
    const res = yield call(request, {
      url: BASE_URL,
      method: "get",
      params: payload,
    });

    yield put(setClients(res));
  } catch (error) {}
}

export function* getUsers() {
  try {
    const res = yield call(request, {
      url: "/admin/users",
      method: "get",
    });

    yield put(setUsers(res.data));
  } catch (error) {}
}

export function* getSingleClient({ payload }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${payload}`,
      method: "get",
    });

    yield put(setSingleClient(res.data));
  } catch (error) {}
}

export function* addClient({
  payload,
  main_photo,
  photos,
  logo,
  QRCode,
  pricelist,
  meta: { setErrors },
}) {
  //console.log("SAGA addClient:", main_photo, photos);
  console.log("SAGA addClient:", payload);
  try {
    const res = yield call(request, {
      url: BASE_URL,
      method: "post",
      data: payload,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    });

    if (res) {
      let id = res.data.client.id;
      if (photos.length) yield put(sendPhotosRequest(id, photos));
      if (main_photo) yield put(createMainPhoto(id, main_photo));
      if (logo) yield put(createLogo(id, logo));
      if (QRCode) yield put(createQRCode(id, QRCode));
      if (pricelist) yield put(createPricelist(id, pricelist));
      yield put(
        enqueueNotification({ message: res.data.message, type: "success" })
      );
      yield put(addClientSuccess());

      yield put(fetchClients());
    }

    //yield put(push("/categories"));
  } catch (error) {
    const errorMessage = "Došlo je do greške";
    yield put(enqueueNotification({ message: errorMessage, type: "error" }));
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(addClientError());
  }
}

export function* uploadMainPhoto({ id, photo, method }) {
  //console.log("SAGA uploadMainPhoto method:", method);
  try {
    const formData = new FormData();
    formData.append(`image`, photo, photo.name);

    const res = yield call(request, {
      url: `${BASE_URL}/${id}/main-image`,
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (method === "update") {
      //console.log("SAGA INSIDE UPDATE MAIN IMG");
      yield put(fetchSingleClient(id));
      yield put(
        enqueueNotification({
          message: res.data.message,
          type: "success",
        })
      );
    }
  } catch (error) {}
}

export function* fetchDeliveryPeople() {
  console.log("fetchDeliveryPeople");
  try {
    const response = yield call(request, {
      url: `admin/deliveries`,
      method: "GET",
    });
    console.log(response);
    if (response) {
      yield put({
        type: types.SET_DELIVERY_PEOPLE,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* uploadLogo({ id, photo, method }) {
  try {
    const formData = new FormData();
    formData.append(`logo`, photo, photo.name);

    const res = yield call(request, {
      url: `${BASE_URL}/${id}/logo`,
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (method === "update") {
      //console.log("SAGA INSIDE UPDATE MAIN IMG");
      yield put(fetchSingleClient(id));
      yield put(
        enqueueNotification({
          message: res.data.message,
          type: "success",
        })
      );
    }
  } catch (error) {}
}

export function* deleteLogo({ client_id }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${client_id}/logo`,
      method: "delete",
    });

    yield put(fetchSingleClient(client_id));
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {}
}

export function* uploadQRCode({ id, photo, method }) {
  try {
    const formData = new FormData();
    formData.append(`qr_code`, photo, photo.name);

    const res = yield call(request, {
      url: `${BASE_URL}/${id}/qr-code`,
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (method === "update") {
      //console.log("SAGA INSIDE UPDATE MAIN IMG");
      yield put(fetchSingleClient(id));
      yield put(
        enqueueNotification({
          message: res.data.message,
          type: "success",
        })
      );
    }
  } catch (error) {}
}

export function* deleteQRCode({ client_id }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${client_id}/qr-code`,
      method: "delete",
    });

    yield put(fetchSingleClient(client_id));
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {}
}

export function* uploadPricelist({ id, file, method }) {
  try {
    const formData = new FormData();
    formData.append(`pricelist`, file, file.name);

    const res = yield call(request, {
      url: `${BASE_URL}/${id}/pricelist`,
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (method === "update") {
      //console.log("SAGA INSIDE UPDATE MAIN IMG");
      yield put(fetchSingleClient(id));
      yield put(
        enqueueNotification({
          message: res.data.message,
          type: "success",
        })
      );
    }
  } catch (error) {}
}

export function* deletePricelist({ client_id }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${client_id}/pricelist`,
      method: "delete",
    });

    yield put(fetchSingleClient(client_id));
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {}
}

export function* sendPhotos({ id, payload, method }) {
  console.log("sendPhotos payload: ", payload);
  try {
    let formData = new FormData();
    payload.map((photo) => formData.append(`images[]`, photo, photo.name));

    const { data } = yield call(request, {
      url: `${BASE_URL}/${id}/images`,
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (method === "update") {
      //yield put(getPhotos(id));
      yield put(fetchSingleClient(id));
      yield put(
        enqueueNotification({ message: data.message, type: "success" })
      );
    }
  } catch (error) {}
}

export function* fetchPhotos({ client_id }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${client_id}/images`,
      method: "get",
    });

    yield put(setPhotos(res.data));
  } catch (error) {}
}

export function* handleRemovePhoto({ client_id, img_id }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${client_id}/images/${img_id}`,
      method: "delete",
    });

    yield put(fetchSingleClient(client_id));
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {}
}

export function* updateClient({ id, payload, meta: { setErrors } }) {
  console.log("SAGA CLIENT: ", payload);
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${id}`,
      method: "patch",
      data: payload,
    });
    yield put(updateClientSuccess());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchClients());
    //yield put(push(routes.CMS_CATEGORIES));
  } catch (error) {
    const errorMessage = "Došlo je do greške";
    yield put(enqueueNotification({ message: errorMessage, type: "error" }));
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(updateClientError());
  }
}

export function* deleteClient({ payload }) {
  //console.log("INSIDE SAGA DELETE");
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${payload}`,
      method: "delete",
    });

    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchClients());
  } catch (error) {
    if (error.status === 422) {
      if (error.data.errors.message) {
        yield put();
        //enqueueSnackbar({ message: error.data.errors.message[0] }, "danger")
      }
    }
  }
}

export function* addtUserToClient({ client_id, payload }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${client_id}/add_user`,
      method: "post",
      data: payload,
    });

    yield put(fetchSingleClient(client_id));
    yield put(fetchClients());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {}
}

export function* removeUserFromClient({ client_id, payload }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${client_id}/remove_user`,
      method: "post",
      data: payload,
    });

    yield put(fetchSingleClient(client_id));
    yield put(fetchClients());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {}
}

export function* getAttributes() {
  try {
    const res = yield call(request, {
      url: "admin/attributes",
      method: "get",
    });

    yield put(setAttributes(res));
  } catch (error) {}
}

export function* getKitchenTypes() {
  try {
    const res = yield call(request, {
      url: "admin/kitchen-types",
      method: "get",
    });

    yield put(setKitchenTypes(res));
  } catch (error) {}
}

export function* getFacilityTypes() {
  try {
    const res = yield call(request, {
      url: "admin/facility-types",
      method: "get",
    });

    yield put(setFacilityTypes(res));
  } catch (error) {}
}

export function* getPaymentTypes() {
  try {
    const res = yield call(request, {
      url: "admin/payment-types",
      method: "get",
    });

    yield put(setPaymentTypes(res));
  } catch (error) {}
}

export function* activateClient({ id, payload }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${id}/status?active=${payload.active}`,
      method: "post",
      data: payload,
    });

    yield put(fetchClients());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {}
}

export default function* clientsSaga() {
  yield takeLatest(types.FETCH_CLIENTS, getClients);
  yield takeLatest(types.FETCH_DELIVERY_PEOPLE, fetchDeliveryPeople);
  yield takeLatest(types.FETCH_USERS, getUsers);
  yield takeLatest(types.GET_SINGLE_CLIENT, getSingleClient);
  yield takeLatest(types.ADD_CLIENT_REQUEST, addClient);
  yield takeLatest(types.SEND_PHOTOS_REQUEST, sendPhotos);
  yield takeLatest(types.CREATE_MAIN_PHOTO, uploadMainPhoto);
  yield takeLatest(types.REMOVE_PHOTO, handleRemovePhoto);
  yield takeLatest(types.GET_PHOTOS, fetchPhotos);
  yield takeLatest(types.ACTIVATE_CLIENT_REQUEST, activateClient);

  yield takeLatest(types.CREATE_LOGO, uploadLogo);
  yield takeLatest(types.REMOVE_LOGO, deleteLogo);
  yield takeLatest(types.CREATE_QR_CODE, uploadQRCode);
  yield takeLatest(types.REMOVE_QR_CODE, deleteQRCode);
  yield takeLatest(types.CREATE_PRICELIST, uploadPricelist);
  yield takeLatest(types.REMOVE_PRICELIST, deletePricelist);

  yield takeLatest(types.UPDATE_CLIENT_REQUEST, updateClient);
  yield takeLatest(types.DELETE_CLIENT_REQUEST, deleteClient);

  yield takeLatest(types.ADD_CLIENT_USER_REQUEST, addtUserToClient);
  yield takeLatest(types.REMOVE_CLIENT_USER_REQUEST, removeUserFromClient);

  yield takeLatest(types.FETCH_ATTRIBUTES, getAttributes);
  yield takeLatest(types.FETCH_KITCHEN_TYPES, getKitchenTypes);
  yield takeLatest(types.FETCH_FACILITY_TYPES, getFacilityTypes);
  yield takeLatest(types.FETCH_PAYMENT_TYPES, getPaymentTypes);
}
