// sagas/notificationSaga.js

import { takeLatest, put, delay } from "redux-saga/effects";
import {
  ENQUEUE_NOTIFICATION,
  removeNotification,
} from "../notifications/actions";

function* handleEnqueueNotification(action) {
  const delayTime = 3000; // Set delay time for the notification (e.g., 3000ms)
  yield delay(delayTime);
  yield put(removeNotification()); // Clear the notification after the delay
}

export default function* watchNotificationActions() {
  yield takeLatest(ENQUEUE_NOTIFICATION, handleEnqueueNotification);
}
