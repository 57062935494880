import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const BodyEditor = ({ value, setValue }) => {
  return (
    <Editor
      editorState={value}
      onEditorStateChange={(val) => setValue(val)}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName min-h-[200px]"
      editorClassName="border  rounded-md pl-4 mt-2 min-h-[200px]"
      placeholder="Opis"
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "list",
          "textAlign",
          "history",
          "link",
        ],
      }}
    />
  );
};

export default BodyEditor;
