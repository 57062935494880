import { useCallback, useEffect, useState } from "react";
import { BiPencil, BiSolidTruck, BiTrash } from "react-icons/bi";
import { useSelector } from "react-redux";
import { makeSelectUser } from "../../containers/App/selectors";
import { useInjectReducer } from "../../utils/injectReducer";

import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { useInjectSaga } from "../../utils/injectSaga";
import { useDispatch } from "react-redux";
import { makeSelectDeliveryPeople } from "./redux/selectors";
import {
  deleteDeliveryPersonRequest,
  fetchDeliveryPeople,
} from "./redux/actions";
import UploadMainPhoto from "../clients/UploadMainPhoto";
import AddDeliverer from "./addDeliverer";
import EditDeliverer from "./editDeliverer";

const key = "deliveryPeople";

export default function DeliveryPeoplePage() {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [currentDeliverer, setCurrentDeliverer] = useState({});
  const user = useSelector(makeSelectUser());

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const deliverers = useSelector(makeSelectDeliveryPeople());

  useEffect(() => {
    dispatch(fetchDeliveryPeople());
  }, [dispatch]);

  const handleDeleteDeliverer = useCallback(
    (id) => dispatch(deleteDeliveryPersonRequest(id)),
    [dispatch]
  );

  return (
    <div className="flex-col">
      {
        <>
          <div className="flex justify-between bg-white p-5 mb-4 rounded-md">
            <div className="left flex gap-1">
              <BiSolidTruck className="" size={25} />
              <div className=" flex flex-col">
                <span className="text-2xl">Dostavljači</span>
                <span className="text-opacity-30 text-sm">
                  {deliverers?.length} dostavljača ukupno
                </span>
              </div>
            </div>
            {user.roles[0] !== "Korisnik" && (
              <div className="right">
                <button
                  onClick={() => setShowCreateForm(true)}
                  className="btn-primary"
                >
                  Dodaj novog dostavljača
                </button>
              </div>
            )}
          </div>
          <div className="bg-white">
            <div className="bg-white flex justify-between p-5  border-b border-black border-opacity-20">
              <div className="grid grid-cols-4 w-[1100px]">
                <span>Ime</span>
              </div>

              <span>Akcija </span>
            </div>
            {deliverers?.length &&
              deliverers?.map((client) => (
                <div className="client_row px-5 flex justify-between items-center ">
                  <div className="grid grid-cols-4 w-[1100px] py-4 text-sm">
                    <span>{client.name}</span>
                    <div>
                      <img
                        src={client.image}
                        alt="client"
                        className="w-72 h-10 object-contain "
                      />
                    </div>
                  </div>
                  <div className="flex text-gray-500 gap-5">
                    <BiTrash
                      onClick={() => {
                        handleDeleteDeliverer(client.id);
                      }}
                      className="cursor-pointer"
                      size={22}
                    />
                    <BiPencil
                      className="cursor-pointer"
                      onClick={() => {
                        setShowUpdateForm(true);
                        setCurrentDeliverer(client);
                      }}
                      size={22}
                    />
                  </div>{" "}
                </div>
              ))}
          </div>
        </>
      }
      {showCreateForm && <AddDeliverer setOpen={(e) => setShowCreateForm(e)} />}
      {showUpdateForm && (
        <EditDeliverer
          deliverer={currentDeliverer}
          setOpen={(e) => setShowUpdateForm(e)}
        />
      )}
    </div>
  );
}
