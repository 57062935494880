import { useDispatch } from "react-redux";
import { useInjectReducer } from "../../../utils/injectReducer";
import { useInjectSaga } from "../../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { useSelector } from "react-redux";
import {
  makeSelectCategories,
  makeSelectCategoriesLoading,
} from "./redux/selectors";
import { useCallback, useEffect, useRef, useState } from "react";
import { changeOrder, deleteCategory, getCategories } from "./redux/actions";
import { TbSection } from "react-icons/tb";
import CategoriesDropdown from "./categories-dropdown";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import CreateCategory from "./create-category";
import { FiEdit } from "react-icons/fi";
import EditCategory from "./edit-category";
import Sortable from "sortablejs";

const key = "home_screen_categories";

export default function HomeCategoriesPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();

  const categories = useSelector(makeSelectCategories());
  const isLoading = useSelector(makeSelectCategoriesLoading());
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [category, setCategory] = useState(null);

  const sortableContainerRef = useRef(null);

  const [data, setData] = useState([]);

  //console.log("TEST:", categories, "data:", data, "isLoading:", isLoading);

  useEffect(() => {
    if (categories && !isLoading) {
      setData(categories);
    }
  }, [dispatch, isLoading]);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const handleDeleteCategory = useCallback(
    (...args) => dispatch(deleteCategory(...args)),
    [dispatch]
  );

  const closeEditForm = () => {
    setShowEditForm(false);
    setCategory(null);
  };

  const closeCreateForm = () => {
    setShowForm(false);
    setCategory(null);
  };

  useEffect(() => {
    if (sortableContainerRef.current) {
      const sortable = new Sortable(sortableContainerRef.current, {
        animation: 150,
        onEnd: (/**Event*/ evt) => {
          const { newIndex, oldIndex } = evt;
          const newData = [...data];
          newData.splice(newIndex, 0, newData.splice(oldIndex, 1)[0]);
          setData(newData);

          handleChangeCategoryOrder(newData);
        },
      });

      return () => {
        sortable.destroy();
      };
    }
  }, [data]);

  const handleChangeCategoryOrder = (newOrder) => {
    const categoryPayload = newOrder.map((item, index) => ({
      id: item.id,
      order: index,
    }));

    dispatch(changeOrder({ categories: categoryPayload }));
  };

  if (isLoading) {
    return <div>Učitavanje kategorija</div>;
  }

  return (
    <div className="flex-col">
      <div className="flex justify-between bg-white p-5 mb-4 rounded-md">
        <div className="left flex gap-1">
          <TbSection size={25} />
          <div className=" flex flex-col">
            <span className="text-2xl">Kategorije</span>
            <span className="text-opacity-30 text-sm">
              {/* {sections && sections.length} sekcija ukupno */}
              kategorija ukupno
            </span>
          </div>
        </div>
        <div className="right flex items-center gap-1 ">
          <button className="btn-primary" onClick={() => setShowForm(true)}>
            Dodaj kategoriju
          </button>
        </div>
      </div>
      <div className="bg-white">
        <div className="bg-white flex justify-between p-5  border-b border-black border-opacity-20">
          <div className="grid grid-cols-2 w-[1200px]">
            <span>Ime</span>
            <span>Redosled</span>
          </div>
          <span>Akcija </span>
        </div>

        <div className="category-list" ref={sortableContainerRef}>
          {data.map((category, index) => {
            return (
              <div
                key={category.id}
                data-id={category.id}
                className="sortable-item category_row px-5 flex justify-between items-center cursor-pointer"
              >
                <div className="grid grid-cols-2 w-[1200px] py-4 text-sm">
                  <span>{category.name}</span>
                  <span>{category.order}</span>
                </div>
                <div className="flex gap-3">
                  <FiEdit
                    size={20}
                    color="gray"
                    className="cursor-pointer"
                    onClick={() => {
                      setCategory(category);
                      setShowEditForm(true);
                    }}
                  />
                  <RiDeleteBin6Line
                    size={20}
                    color="gray"
                    onClick={() => handleDeleteCategory(category.id)}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {showForm && (
        <CreateCategory setShowForm={setShowForm} close={closeCreateForm} />
      )}
      {showEditForm && (
        <EditCategory
          category={category}
          setShowEditForm={setShowEditForm}
          close={closeEditForm}
        />
      )}
    </div>
  );
}
