import types from "./constants";

export const fetchUsers = (payload) => ({
  type: types.FETCH_USERS,
  payload,
});

export const setUsers = (payload) => ({
  type: types.SET_USERS,
  payload,
});

export const fetchSingleUser = (payload) => ({
  type: types.FETCH_SINGLE_USER,
  payload,
});

export const setSingleUser = (payload) => ({
  type: types.SET_SINGLE_USER,
  payload,
});

export const clearSingleUser = (payload) => ({
  type: types.CLEAR_SINGLE_USER,
  payload,
});

export const fetchSingleUserError = (error) => ({
  type: types.FETCH_SINGLE_USER_ERROR,
  error,
});

export const addUser = (payload, setErrors) => ({
  type: types.ADD_USER_REQUEST,
  payload,
  meta: { setErrors },
});

// export function addUserSuccess() {
//   return function (dispatch) {
//     dispatch({
//       type: types.ADD_USER_SUCCESS,
//     });
//     toast.success("KORISNIK DODAT");
//   };
// }

export const addUserSuccess = () => ({
  type: types.ADD_USER_SUCCESS,
});

export const addUserError = () => ({
  type: types.ADD_USER_ERROR,
});

export const updateUser = (id, payload, setErrors) => ({
  type: types.UPDATE_USER_REQUEST,
  id,
  payload,
  meta: { setErrors },
});

export const updateUserSuccess = () => ({
  type: types.UPDATE_USER_SUCCESS,
});

export const updateUserError = () => ({
  type: types.UPDATE_USER_ERROR,
});

export const deleteUser = (payload) => ({
  type: types.DELETE_USER_REQUEST,
  payload,
});
