import { Formik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import ClientsDropdown from "./clients-dropdown";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FaTimes } from "react-icons/fa";

import { useInjectReducer } from "../../../../utils/injectReducer";
import { useInjectSaga } from "../../../../utils/injectSaga";
import reducer from "../redux/reducer";
import saga from "../redux/saga";
import { useDispatch } from "react-redux";
import {
  changeCategoryOrder,
  changeClientOrder,
  clearSection,
  createCategory,
  createClient,
  deleteCategory,
  deleteClient,
  getSection,
} from "../redux/actions";
import { useSelector } from "react-redux";
import {
  makeSelectSection,
  makeSelectSectionLoading,
} from "../redux/selectors";
import Sortable from "sortablejs";
import CategoriesDropdown from "./categories-dropdown";

const key = "home_screen_sections";

export default function EditSection({
  setShowEditSection,
  onSubmit,
  sectionId,
}) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();

  const [showClients, setShowClients] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [categoriesData, setCategoriesData] = useState();
  const [clientsData, setClientsData] = useState();
  console.log("STATE data", categoriesData);

  const sortableContainerRefCategory = useRef(null);
  const sortableContainerRefClients = useRef(null);

  const section = useSelector(makeSelectSection());
  console.log("section", section);
  const isLoading = useSelector(makeSelectSectionLoading());

  const handleSubmit = (values, { setErrors }) => {
    onSubmit(sectionId, values, setErrors);
  };

  useEffect(() => {
    dispatch(getSection(sectionId));

    return () => dispatch(clearSection());
  }, [dispatch, sectionId]);

  useEffect(() => {
    if (section && !isLoading) {
      setCategoriesData(section.categories);
      setClientsData(section.clients);
    }
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (sortableContainerRefClients.current) {
      const sortable = new Sortable(sortableContainerRefClients.current, {
        animation: 150,
        onEnd: (/**Event*/ evt) => {
          const { newIndex, oldIndex } = evt;
          const newData = [...clientsData];
          newData.splice(newIndex, 0, newData.splice(oldIndex, 1)[0]);
          setClientsData(newData);

          handleChangeClientOrder(newData);
        },
      });

      return () => {
        sortable.destroy();
      };
    }
  }, [clientsData]);

  useEffect(() => {
    if (sortableContainerRefCategory.current) {
      const sortable = new Sortable(sortableContainerRefCategory.current, {
        animation: 150,
        onEnd: (/**Event*/ evt) => {
          const { newIndex, oldIndex } = evt;
          const newData = [...categoriesData];
          newData.splice(newIndex, 0, newData.splice(oldIndex, 1)[0]);
          setCategoriesData(newData);

          handleChangeCategoryOrder(newData);
        },
      });

      return () => {
        sortable.destroy();
      };
    }
  }, [categoriesData]);

  const handleChangeClientOrder = (newOrder) => {
    const clientPayload = newOrder.map((item, index) => ({
      id: item.id,
      order: index,
    }));

    dispatch(changeClientOrder(sectionId, { clients: clientPayload }));
  };

  const handleChangeCategoryOrder = (newOrder) => {
    const categoryPayload = newOrder.map((item, index) => ({
      id: item.id,
      order: index,
    }));

    dispatch(changeCategoryOrder(sectionId, { categories: categoryPayload }));
  };

  const handleAddCategory = useCallback(
    (...args) => dispatch(createCategory(...args)),
    [dispatch]
  );

  const handleAddClient = useCallback(
    (...args) => dispatch(createClient(...args)),
    [dispatch]
  );

  const onDeleteCategory = (category_id) => {
    dispatch(deleteCategory(section.id, category_id));
  };

  const onDeleteClient = (client_id) => {
    dispatch(deleteClient(section.id, client_id));
  };

  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="relative flex flex-col w-3/4 rounded-lg gap-8 bg-white px-12 pt-12 pb-6 ">
        <span
          className="absolute top-2 right-4 cursor-pointer"
          onClick={() => setShowEditSection(false)}
        >
          &times;
        </span>
        <span className="text-2xl text-center">Ažuriraj sekciju</span>
        {!section && <div>Učitavanje sekcije..</div>}
        {section && (
          <Formik
            key={section.id}
            initialValues={{
              name: section.name,
            }}
            onSubmit={(values, { setErrors, resetForm }) => {
              handleSubmit(values, setErrors);
              //setSubmitting(true);
            }}
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = "Naziv sekcije je obavezno polje";
              }

              return errors;
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              //console.log("VALUES: ", values);
              return (
                <form onSubmit={handleSubmit} className="flex flex-col gap-8">
                  <div className="flex flex-col  ">
                    <label>Ime sekcije*</label>
                    <div className="flex gap-1  h-full w-full">
                      <input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        placeholder="Unesi ime sekcije"
                        className="w-full px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                      />
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn-primary mx-auto"
                      >
                        {/* {isCreatePending ? "Dodavanje..." : "Dodaj"} */}
                        Ažuriraj
                      </button>
                    </div>

                    <p className="text-red-500 text-sm">
                      {errors.name && touched.name && errors.name}
                    </p>
                  </div>

                  <div className="flex  gap-2">
                    <div className="flex flex-col w-1/2 relative">
                      <div>Klijenti*</div>
                      <div className="flex items-center gap-1">
                        <div
                          className="flex items-center w-full h-full px-4 py-2 border border-black border-opacity-20 rounded-md text-sm text-black text-opacity-40 cursor-pointer"
                          onClick={() => setShowClients(!showClients)}
                        >
                          {selectedClient
                            ? selectedClient.company_name
                            : "Izaberi klijente"}

                          {showClients ? (
                            <MdKeyboardArrowUp className="ml-auto" />
                          ) : (
                            <MdKeyboardArrowDown className="ml-auto" />
                          )}
                        </div>
                        <button
                          className="btn-primary "
                          onClick={() =>
                            handleAddClient(sectionId, {
                              client_id: selectedClient.id,
                            })
                          }
                        >
                          Dodaj
                        </button>
                      </div>
                      {showClients && (
                        <ClientsDropdown
                          setSelectedClient={setSelectedClient}
                          setShowClients={setShowClients}
                        />
                      )}

                      {section.clients.length ? (
                        <div className="flex flex-col gap-1 my-1">
                          <div className="bg-zinc-100 flex items-center justify-between p-1 rounded-md text-sm">
                            <span>Ime</span>
                            <span className="mr-4">Redosled</span>
                          </div>
                          <div
                            className="client-list"
                            ref={sortableContainerRefClients}
                          >
                            {section.clients.map((item) => (
                              <div
                                key={item.id}
                                data-id={item.id}
                                className="sortable-item flex items-center justify-between   border-b p-1  cursor-pointer text-sm"
                              >
                                {item.company_name}
                                <div className="flex items-center gap-8">
                                  <span>{item.order}</span>
                                  <FaTimes
                                    onClick={() => onDeleteClient(item.id)}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="flex flex-col w-1/2 relative">
                      <div>Kategorije*</div>
                      <div className="flex items-center gap-1">
                        <div
                          className="flex w-full h-full items-center px-4 py-2 border border-black border-opacity-20 rounded-md text-sm text-black text-opacity-40 cursor-pointer"
                          onClick={() => setShowCategories(!showCategories)}
                        >
                          {selectedCategory
                            ? selectedCategory.name
                            : "Izaberi kategorije"}

                          {showCategories ? (
                            <MdKeyboardArrowUp className="ml-auto" />
                          ) : (
                            <MdKeyboardArrowDown className="ml-auto" />
                          )}
                        </div>
                        <button
                          className="btn-primary"
                          onClick={() =>
                            handleAddCategory(sectionId, {
                              category_id: selectedCategory.id,
                            })
                          }
                        >
                          Dodaj
                        </button>
                      </div>
                      {showCategories && (
                        <CategoriesDropdown
                          setSelectedCategory={setSelectedCategory}
                          setShowCategories={setShowCategories}
                        />
                      )}
                      {section.categories.length > 0 && (
                        <div className="flex flex-col gap-1 my-1">
                          <div className="bg-zinc-100 flex items-center justify-between p-1 rounded-md text-sm">
                            <span>Ime</span>
                            <span className="mr-4">Redosled</span>
                          </div>
                          <div
                            className="category-list"
                            ref={sortableContainerRefCategory}
                          >
                            {section.categories.map((item) => (
                              <div
                                key={item.id}
                                data-id={item.id}
                                className="sortable-item flex items-center justify-between border-b p-1  cursor-pointer text-sm"
                              >
                                {item.name}
                                <div className="flex items-center gap-8">
                                  <span>{item.order}</span>
                                  <FaTimes
                                    onClick={(e) => onDeleteCategory(item.id)}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
}
