import { useSelector } from "react-redux";
import { makeSelectSingleClient, makeSelectUsers } from "./redux/selectors";
import { IoMdAddCircle, IoMdRemoveCircle } from "react-icons/io";

export default function UsersForm({
  setShowUsersForm,
  onAddUser,
  onRemoveUser,
}) {
  const client = useSelector(makeSelectSingleClient());
  const users = useSelector(makeSelectUsers());

  function handleAdd(client_id, payload) {
    onAddUser(client_id, payload);
  }

  function handleRemove(client_id, payload) {
    onRemoveUser(client_id, payload);
  }

  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-50 flex justify-center items-center cursor-default">
      <div className="relative flex flex-col w-[500px] rounded-lg gap-8 bg-white p-12">
        <span
          className="absolute top-2 right-4 cursor-pointer text-xl"
          onClick={() => setShowUsersForm(false)}
        >
          &times;
        </span>
        {!client && <div>Učitavanje</div>}
        {client && (
          <div>
            <span className="font-bold">
              Povezani korsisnici ({client.users.length})
            </span>
            <div className="my-5">
              {client.users.map((user) => (
                <div className="flex items-center justify-between text-sm cursor-pointer border-b border-gray-400 pb-1">
                  <div className="text-sm">
                    {user.name} (
                    <span className="text-gray-400">{user.email}</span>)
                  </div>
                  <span className="cursor-pointer">
                    <IoMdRemoveCircle
                      color="red"
                      size={20}
                      onClick={() =>
                        handleRemove(client.id, { user_id: user.id })
                      }
                    />
                  </span>
                </div>
              ))}
            </div>

            <span className=" font-bold">Poveži korisnike</span>
            <div className="flex flex-col gap-1">
              {users &&
                users
                  .filter(
                    (user) =>
                      !client.users.some(
                        (clientUser) => clientUser.id === user.id
                      )
                  )

                  .map((user) => (
                    <div className="flex items-center justify-between text-sm cursor-pointer border-b border-gray-400 pb-1">
                      <span>
                        {user.name} (
                        <span className="text-gray-400">{user.email}</span>)
                      </span>
                      <span>
                        <IoMdAddCircle
                          color="green"
                          size={20}
                          onClick={() =>
                            handleAdd(client.id, { user_id: user.id })
                          }
                        />
                      </span>
                    </div>
                  ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
