import produce from "immer";
import types from "./constants";

export const initialState = {
  banners: [],
  banner: null,
  bannerError: null,
  bannersPositions: [],
  params: {},
  position: null,
  category: null,
};

/* eslint-disable default-case */
const cmsBannersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_BANNERS:
        draft.banners = action.payload;
        break;
      case types.SET_BANNER:
        draft.banner = action.payload;
        break;
      case types.CLEAR_BANNER:
        draft.banner = null;
        break;
      case types.GET_BANNERS_REQUEST:
        draft.bannersLoading = true;
        break;
      case types.GET_BANNERS_SUCCESS:
        draft.bannersLoading = false;
        break;
      case types.SET_CATEGORY:
        draft.category = action.payload;
        break;
      case types.GET_BANNER_ERROR:
        draft.bannerError = action.error;
        break;
      case types.SET_BANNERS_POSITIONS:
        draft.bannersPositions = action.payload;
        break;
      case types.SET_PARAMS:
        draft.params = action.params;
        break;
      case types.DELETE_PARAMS:
        draft.params = {};
        draft.position = null;
        break;
      case types.SET_POSITION:
        draft.position = action.payload;
        break;
    }
  });

export default cmsBannersReducer;
