import dayjs from "dayjs";

export const parseTime = (timeString) => {
  //console.log("timeString", timeString);
  if (!timeString) return null;
  let [hours, minutes] = timeString.split(":").map(Number);
  // Convert 12-hour format to 24-hour format
  // if (timeString.includes("PM") && hours < 12) {
  //   hours += 12;
  // } else if (timeString.includes("AM") && hours === 12) {
  //   hours = 0;
  // }
  return dayjs().hour(hours).minute(minutes);
};
