// reducers/notificationReducer.js

import { ENQUEUE_NOTIFICATION, REMOVE_NOTIFICATION } from "./actions";

const initialState = {
  currentNotification: null,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENQUEUE_NOTIFICATION:
      return {
        ...state,
        currentNotification: {
          ...action.payload,
          key: new Date().getTime() + Math.random(),
        },
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        currentNotification: null,
      };
    default:
      return state;
  }
};

export default notificationReducer;
