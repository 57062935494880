import { Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectSingleCategory,
  makeSelectUpdateCategoryIsPending,
} from "./redux/selectors";
import { siteURL } from "../../env";
import { useInjectReducer } from "../../utils/injectReducer";
import { useInjectSaga } from "../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import {
  clearSingleCategory,
  fetchSingleCategory,
  updateCategory,
} from "./redux/actions";

const baseURL = siteURL + "/storage";

const key = "categories";

export default function EditCategory({
  setShowEditCategory,
  categories,
  category_id,
  //onEditCategory,
}) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [imageChanged, setImageChanged] = useState(false);

  const category = useSelector(makeSelectSingleCategory());
  const [fileName, setFileName] = useState("");

  const isUpdatePending = useSelector(makeSelectUpdateCategoryIsPending());

  useEffect(() => {
    dispatch(fetchSingleCategory(category_id));

    return () => dispatch(clearSingleCategory());
  }, [dispatch, category_id]);

  const onSubmit = useCallback(
    (...args) => dispatch(updateCategory(...args)),
    [dispatch]
  );

  useEffect(() => {
    if (submitting && !isUpdatePending) {
      setShowEditCategory(false);
      setSubmitting(false);
      setFileName("");
    }
  }, [isUpdatePending, submitting, setShowEditCategory]);

  // const onSubmit = (values) => {
  //   onEditCategory(values);
  // };

  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="relative flex flex-col w-[500px] rounded-lg gap-8 bg-white px-12 pt-12 pb-6 ">
        <span
          className="absolute top-2 right-4 cursor-pointer"
          onClick={() => setShowEditCategory(false)}
        >
          x
        </span>
        <span className="text-2xl text-center">Ažuriranje kategorje</span>
        {!category && <div>Učitavanje kategorije..</div>}
        {category && (
          <Formik
            initialValues={{
              parent_id: category.parent_id,
              name_sr_lat: category.name_sr_lat,
              name_sr_cir: category.name_sr_cir,
              name_en: category.name_en,
              image: category.image,
              // parent_id: "node.parent_id",
              // name_sr_lat: "node.name",
              // image: "node.image",
            }}
            enableReinitialize
            onSubmit={(values, { setErrors, resetForm }) => {
              const formData = new FormData();
              formData.append("parent_id", values.parent_id);
              formData.append("name_sr_lat", values.name_sr_lat);
              formData.append("name_sr_cir", values.name_sr_cir);
              formData.append("name_en", values.name_en);
              if (imageChanged) {
                formData.append("image", values.image);
              }

              //setFileName('');
              //console.log("EDIT CATEGORY FORM DATA:", values);
              setSubmitting(true);
              setFileName("");
              onSubmit(category.id, formData, setErrors);
            }}
            validate={(values) => {
              const errors = {};
              if (!values.parent_id) {
                errors.parent_id =
                  "Naziv kategorije roditelj je obavezno polje";
              }
              // if (!values.name) {
              //   errors.name = "Naziv nove kategorije je obavezno polje";
              // }
              return errors;
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => {
              const handleFileChange = (event) => {
                const file = event.currentTarget.files[0];
                if (file) {
                  setFieldValue("image", file);
                  setImageChanged(true);
                  setFileName(file.name);
                }
              };
              // console.log("IS SUBMITTING?", isSubmitting);
              // console.log("VALUES: ", values);
              return (
                <form onSubmit={handleSubmit} className="flex flex-col gap-8">
                  <div className="flex flex-col">
                    <label>Naziv kategorije roditelj</label>
                    <select
                      name="parent_id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.parent_id}
                      className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm text-opacity-30"
                    >
                      {categories.map((category) => (
                        <option
                          key={category.id}
                          value={category.id}
                          //selected={category.id === node.id}
                        >
                          {category.name}
                        </option>
                      ))}
                      {/* <option
                    value=""
                    disabled
                    selected
                    className="text-sm text-opacity-30"
                  >
                    Unesi naziv kategorije roditelj
                  </option>
                  <option className="text-sm">Kategorija 1</option>
                  <option className="text-sm">Kategorija 2</option>
                  <option className="text-sm"> Kategorija 3</option>
                  <option className="text-sm">Kategorija 4</option> */}
                    </select>
                    <p className="text-red-500 text-sm">
                      {errors.parent_id && touched.parent && errors.parent_id}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <label>Naziv kategorije latinica*</label>
                    <input
                      type="text"
                      name="name_sr_lat"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name_sr_lat}
                      placeholder="Unesi naziv kategorije"
                      className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                    />
                    <p className="text-red-500 text-sm">
                      {errors.name_sr_lat &&
                        touched.name_sr_lat &&
                        errors.name_sr_lat}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <label>Naziv kategorije ćirilica*</label>
                    <input
                      type="text"
                      name="name_sr_cir"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name_sr_cir}
                      placeholder="Unesi naziv kategorije"
                      className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                    />
                    <p className="text-red-500 text-sm">
                      {errors.name_sr_cir &&
                        touched.name_sr_cir &&
                        errors.name_sr_cir}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <label>Naziv kategorije engleski*</label>
                    <input
                      type="text"
                      name="name_en"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name_en}
                      placeholder="Unesi naziv kategorije"
                      className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                    />
                    <p className="text-red-500 text-sm">
                      {errors.name_en && touched.name_en && errors.name_en}
                    </p>
                  </div>
                  <div>
                    <label htmlFor="">Slika kategorije</label>
                    {category.image && (
                      <img
                        src={`${baseURL}/${category.image}`}
                        alt=""
                        className="h-auto w-40"
                      ></img>
                    )}

                    <input
                      id="fileInput"
                      type="file"
                      name="image"
                      className="hidden"
                      onChange={handleFileChange}
                    ></input>
                    <div
                      className="flex border border-dashed border-black border-opacity-20 w-fit p-8 rounded-lg cursor-pointer text-sm text-opacity-30"
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                    >
                      Promeni sliku
                    </div>
                    <p>Izabrana slika: </p>

                    <span>{fileName}</span>
                  </div>

                  <button
                    type="submit"
                    disabled={isUpdatePending}
                    className="btn-primary mx-auto "
                  >
                    {isUpdatePending ? "Ažuriranje..." : "Ažuriraj"}
                  </button>
                </form>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
}
