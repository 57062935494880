import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectClients = (state) => state.home_screen_clients || initialState;

const makeSelectClients = () =>
  createSelector(selectClients, (substate) => substate.clients);

const makeSelectClientsLoading = () =>
  createSelector(selectClients, (substate) => substate.clientsLoading);

const makeSelectError = () =>
  createSelector(selectClients, (substate) => substate.error);

const makeSelectActiveDevice = () =>
  createSelector(selectClients, (substate) => substate.activeDevice);

export {
  makeSelectClients,
  makeSelectError,
  makeSelectActiveDevice,
  makeSelectClientsLoading,
};
