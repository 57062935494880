import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "../../../utils/injectReducer";
import { useInjectSaga } from "../../../utils/injectSaga";
import reducer from "../../categories/redux/reducer";
import saga from "../../categories/redux/saga";
import { useEffect, useState } from "react";
import {
  makeSelectCategories,
  makeSelectClients,
} from "../../categories/redux/selectors";
import { fetchCategories } from "../../categories/redux/actions";

const key = "categories";

export default function CategoriesDropdown({
  value,
  setValue,
  setShowCategories,
}) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();

  const [delay, setDelay] = useState("");

  const categories = useSelector(makeSelectCategories()).data;

  console.log("categories", categories);

  useEffect(() => {
    dispatch(fetchCategories({ paginate: 10 }));
  }, [dispatch]);

  const handleChange = (e) => {
    let text = e.target.value;
    if (delay) clearTimeout(delay);
    let copyState = delay;
    copyState = setTimeout(() => {
      dispatch(fetchCategories({ paginate: 10, search: text }));
    }, 500);
    setDelay(copyState);
  };

  const handleClick = (item) => {
    let find = value.find((el) => el.id === item.id);
    if (!find) {
      setValue([...value, item]);
      //toggle();
    }
  };

  if (!categories) {
    return null;
  }

  return (
    <div className="absolute top-16 bg-white z-10 p-2 border border-black border-opacity-20 w-full rounded-md ">
      <div className="mb-2">
        <input
          type="text"
          className="w-full"
          placeholder="Pretraži"
          onChange={handleChange}
        ></input>
      </div>
      {categories &&
        categories.map((category) => (
          <div
            key={category.id}
            onClick={() => {
              handleClick(category);
              setShowCategories(false);
            }}
            className="cursor-pointer"
          >
            {category.name}
          </div>
        ))}
    </div>
  );
}
