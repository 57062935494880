import { takeLatest, call, put, select } from "redux-saga/effects";
import request from "../../../../utils/request";
import parseApiErrorsToFormik from "../../../../utils/parseApiErrorsToFormik";

import {
  getClients,
  addClientError,
  setClients,
  getClientsSuccess,
} from "./actions";
import types from "./constants";
import { makeSelectActiveDevice } from "./selectors";
import { enqueueNotification } from "../../../../notifications/actions";

export function* fetchClients() {
  try {
    const res = yield call(request, {
      url: `admin/home-page/web/home-clients`,
      method: "get",
    });
    yield put(setClients(res.data));
    yield put(getClientsSuccess());
  } catch (error) {}
}

export function* addClient({ client_id }) {
  try {
    const res = yield call(request, {
      url: `admin/home-page/web/home-clients`,
      method: "post",
      data: client_id,
    });
    yield put(getClients());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    //yield put(addClientError(null));
  } catch (error) {
    yield put(
      enqueueNotification({ message: error.data.message, type: "error" })
    );
  }
}

export function* changeOrder({ payload }) {
  try {
    yield call(request, {
      url: `admin/home-page/web/home-clients`,
      method: "patch",
      data: payload,
    });
    yield put(getClients());
  } catch (error) {}
}

export function* deleteClient({ client_id }) {
  try {
    const res = yield call(request, {
      url: `admin/home-page/web/home-clients/${client_id}`,
      method: "delete",
    });
    yield put(getClients());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {}
}

export default function* cmsHomeScreenClientsSaga() {
  yield takeLatest(types.GET_CLIENTS_REQUEST, fetchClients);
  yield takeLatest(types.ADD_CLIENT, addClient);
  yield takeLatest(types.CHANGE_ORDER, changeOrder);
  yield takeLatest(types.DELETE_CLIENT, deleteClient);
}
