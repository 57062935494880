import produce from "immer";
import types from "./constants";

export const initialState = {
  clients: null,
  error: null,
  activeDevice: "web",
  clientsLoading: null,
};

/* eslint-disable default-case */
const cmsHomeScreenClientsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_CLIENTS:
        draft.clients = action.payload;
        break;
      case types.ADD_CLIENT_ERROR:
        draft.error = action.error;
        break;
      case types.GET_CLIENTS_REQUEST:
        draft.clientsLoading = true;
        break;
      case types.GET_CLIENTS_SUCCESS:
        draft.clientsLoading = false;
        break;
    }
  });

export default cmsHomeScreenClientsReducer;
