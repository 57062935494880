import { all, call, put, takeLatest } from "redux-saga/effects";
import types from "./constants";
import request from "../../../utils/request";
import { enqueueNotification } from "../../../notifications/actions";
import { typographyClasses } from "@mui/material";

const BASE_URL = "admin/deliveries";

export function* fetchDeliveryPeople() {
  console.log("fetchDeliveryPeople");
  try{
    const response = yield call(request, {
      url: `${BASE_URL}`,
      method: "GET",
    });
    console.log(response);
    if(response){
      yield put({
        type: types.SET_DELIVERY_PEOPLE,
        payload: response.data,
      });
    }
  
  }
  catch(error){
    console.log(error);
  }
}

export function* addDeliverer({ payload}) {
  const formData = new FormData();
  formData.append("name", payload.name);
  formData.append("image", payload.image);
  try{
    const response = yield call( request, {
      url: `${BASE_URL}`,
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },

    });
    if(response){
      yield put(
        enqueueNotification({
          message: response.data.message,
          type: "success",
        })
      );
      yield call(fetchDeliveryPeople);
    }
  }
  catch(error){
    console.log(error);
  }
}

export function* updateDeliverer({ payload}) {
  try{
    const response = yield call( request, {
      url: `${BASE_URL}/${payload.id}?_method=patch`,
      method: "post",
      data: payload?.data,
      headers: { "Content-Type": "multipart/form-data" },

    });
    if(response){
      yield put(
        enqueueNotification({
          message: response.data.message,
          type: "success",
        })
      );
      yield call(fetchDeliveryPeople);
    }
  }
  catch(error){
    yield put(
      enqueueNotification({
        message: error.data.message,
        type: "error",
      })
    );
  }
}

export function* deleteDeliverer({payload}) {
  try{
    const response = yield call(request, {
      url: `${BASE_URL}/${payload}`,
      method: "DELETE",
    });
    if(response){
      yield put(
        enqueueNotification({
          message: response.data.message,
          type: "success",
        })
      );
      yield call(fetchDeliveryPeople);
    }
  
  }
  catch(error){
    console.log(error);
  }
}

export default function* deliveryPeopleSaga() {
  yield all([
    takeLatest(types.FETCH_DELIVERY_PEOPLE, fetchDeliveryPeople),
    takeLatest(types.ADD_DELIVERY_PERSON_REQUEST, addDeliverer),
    takeLatest(types.UPDATE_DELIVERY_PERSON_REQUEST, updateDeliverer),
    takeLatest(types.DELETE_DELIVERY_PERSON_REQUEST, deleteDeliverer),
  ]);
}
