import produce from "immer";
import { getItem } from "../../utils/localStorage";
import types from "./constants";

export const initialState = {
  token: getItem("token") || null,
  user: null,
  modules: [],
};

/* eslint-disable default-case */
const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_AUTHENTICATED_USER_SUCCESS:
        draft.user = action.user;
        break;
      case types.LOGOUT_SUCCESS:
        draft.user = null;
        draft.token = null;
        break;
      case types.SET_TOKEN:
        draft.token = action.token;
        break;
      case types.SET_MODULES:
        draft.modules = action.payload;
        break;
    }
  });

export default appReducer;
