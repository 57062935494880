import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "../../../../utils/injectReducer";
import { useInjectSaga } from "../../../../utils/injectSaga";
import reducer from "../../../clients/redux/reducer";
import saga from "../../../clients/redux/saga";
import { useEffect, useState } from "react";
import { makeSelectClients } from "../../../clients/redux/selectors";
import { fetchClients } from "../../../clients/redux/actions";

const key = "clients";

export default function ClientsDropdown({
  value,
  setSelectedClient,
  setShowClients,
}) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();

  const [delay, setDelay] = useState("");

  const clients = useSelector(makeSelectClients()).data;
  console.log("clients", clients);

  useEffect(() => {
    dispatch(fetchClients({ paginate: 10 }));
  }, [dispatch]);

  const handleChange = (e) => {
    let text = e.target.value;
    if (delay) clearTimeout(delay);
    let copyState = delay;
    copyState = setTimeout(() => {
      dispatch(fetchClients({ paginate: 10, search: text }));
    }, 500);
    setDelay(copyState);
  };

  const handleClick = (client) => {
    setSelectedClient(client);
    setShowClients(false);
  };

  if (!clients) {
    return null;
  }

  return (
    <div className="absolute top-16 bg-white z-10 p-2 border border-black border-opacity-20 w-full rounded-md ">
      <div className="mb-2">
        <input
          type="text"
          className="w-full"
          placeholder="Pretraži"
          onChange={handleChange}
        ></input>
      </div>
      {clients &&
        clients.map((client) => (
          <div
            key={client.id}
            onClick={() => {
              handleClick(client);
            }}
            className="cursor-pointer"
          >
            {client.object_name}
          </div>
        ))}
    </div>
  );
}
