const KEY = "[CMS_ATTRIBUTES]";

const types = {
  SET_NOTIFICATION: `${KEY} SET_NOTIFICATION`,

  FETCH_ATTRIBUTES: `${KEY} FETCH_ATTRIBUTES`,
  SET_ATTRIBUTES: `${KEY} SET_ATTRIBUTES`,
  ADD_ATTRIBUTE_REQUEST: `${KEY} ADD_ATTRIBUTE_REQUEST`,
  ADD_ATTRIBUTE_SUCCESS: `${KEY} ADD_ATTRIBUTE_SUCCESS`,
  ADD_ATTRIBUTE_ERROR: `${KEY} ADD_ATTRIBUTE_ERROR`,
  FETCH_SINGLE_ATTRIBUTE: `${KEY} FETCH_SINGLE_ATTRIBUTE`,
  SET_SINGLE_ATTRIBUTE: `${KEY} SET_SINGLE_ATTRIBUTE`,
  CLEAR_SINGLE_ATTRIBUTE: `${KEY} CLEAR_SINGLE_ATTRIBUTE`,
  FETCH_SINGLE_ATTRIBUTE_ERROR: `${KEY} FETCH_SINGLE_ATTRIBUTE_ERROR`,
  UPDATE_ATTRIBUTE_REQUEST: `${KEY} UPDATE_ATTRIBUTE_REQUEST`,
  UPDATE_ATTRIBUTE_SUCCESS: `${KEY} UPDATE_ATTRIBUTE_SUCCESS`,
  UPDATE_ATTRIBUTE_ERROR: `${KEY} UPDATE_ATTRIBUTE_ERROR`,
  DELETE_ATTRIBUTE_REQUEST: `${KEY} DELETE_ATTRIBUTE_REQUEST`,

  FETCH_FACILITY_TYPES: `${KEY} FETCH_FACILITY_TYPES`,
  SET_FACILITY_TYPES: `${KEY} SET_FACILITY_TYPES`,
  ADD_FACILITY_TYPE_REQUEST: `${KEY} ADD_FACILITY_TYPE_REQUEST`,
  ADD_FACILITY_TYPE_SUCCESS: `${KEY} ADD_FACILITY_TYPE_SUCCESS`,
  ADD_FACILITY_TYPE_ERROR: `${KEY} ADD_FACILITY_TYPE_ERROR`,
  FETCH_SINGLE_FACILITY_TYPE: `${KEY} FETCH_SINGLE_FACILITY_TYPE`,
  SET_SINGLE_FACILITY_TYPE: `${KEY} SET_SINGLE_FACILITY_TYPE`,
  CLEAR_SINGLE_FACILITY_TYPE: `${KEY} CLEAR_SINGLE_FACILITY_TYPE`,
  FETCH_SINGLE_FACILITY_TYPE_ERROR: `${KEY} FETCH_SINGLE_FACILITY_TYPE_ERROR`,
  UPDATE_FACILITY_TYPE_REQUEST: `${KEY} UPDATE_FACILITY_TYPE_REQUEST`,
  UPDATE_FACILITY_TYPE_SUCCESS: `${KEY} UPDATE_FACILITY_TYPE_SUCCESS`,
  UPDATE_FACILITY_TYPE_ERROR: `${KEY} UPDATE_FACILITY_TYPE_ERROR`,
  DELETE_FACILITY_TYPE_REQUEST: `${KEY} DELETE_FACILITY_TYPE_REQUEST`,

  FETCH_KITCHEN_TYPES: `${KEY} FETCH_KITCHEN_TYPES`,
  SET_KITCHEN_TYPES: `${KEY} SET_KITCHEN_TYPES`,
  ADD_KITCHEN_TYPE_REQUEST: `${KEY} ADD_KITCHEN_TYPE_REQUEST`,
  ADD_KITCHEN_TYPE_SUCCESS: `${KEY} ADD_KITCHEN_TYPE_SUCCESS`,
  ADD_KITCHEN_TYPE_ERROR: `${KEY} ADD_KITCHEN_TYPE_ERROR`,
  FETCH_SINGLE_KITCHEN_TYPE: `${KEY} FETCH_SINGLE_KITCHEN_TYPE`,
  SET_SINGLE_KITCHEN_TYPE: `${KEY} SET_SINGLE_KITCHEN_TYPE`,
  CLEAR_SINGLE_KITCHEN_TYPE: `${KEY} CLEAR_SINGLE_KITCHEN_TYPE`,
  FETCH_SINGLE_KITCHEN_TYPE_ERROR: `${KEY} FETCH_SINGLE_KITCHEN_TYPE_ERROR`,
  UPDATE_KITCHEN_TYPE_REQUEST: `${KEY} UPDATE_KITCHEN_TYPE_REQUEST`,
  UPDATE_KITCHEN_TYPE_SUCCESS: `${KEY} UPDATE_KITCHEN_TYPE_SUCCESS`,
  UPDATE_KITCHEN_TYPE_ERROR: `${KEY} UPDATE_KITCHEN_TYPE_ERROR`,
  DELETE_KITCHEN_TYPE_REQUEST: `${KEY} DELETE_KITCHEN_TYPE_REQUEST`,

  FETCH_PAYMENT_TYPES: `${KEY} FETCH_PAYMENT_TYPES`,
  SET_PAYMENT_TYPES: `${KEY} SET_PAYMENT_TYPES`,
  ADD_PAYMENT_TYPE_REQUEST: `${KEY} ADD_PAYMENT_TYPE_REQUEST`,
  ADD_PAYMENT_TYPE_SUCCESS: `${KEY} ADD_PAYMENT_TYPE_SUCCESS`,
  ADD_PAYMENT_TYPE_ERROR: `${KEY} ADD_PAYMENT_TYPE_ERROR`,
  FETCH_SINGLE_PAYMENT_TYPE: `${KEY} FETCH_SINGLE_PAYMENT_TYPE`,
  SET_SINGLE_PAYMENT_TYPE: `${KEY} SET_SINGLE_PAYMENT_TYPE`,
  CLEAR_SINGLE_PAYMENT_TYPE: `${KEY} CLEAR_SINGLE_PAYMENT_TYPE`,
  FETCH_SINGLE_PAYMENT_TYPE_ERROR: `${KEY} FETCH_SINGLE_PAYMENT_TYPE_ERROR`,
  UPDATE_PAYMENT_TYPE_REQUEST: `${KEY} UPDATE_PAYMENT_TYPE_REQUEST`,
  UPDATE_PAYMENT_TYPE_SUCCESS: `${KEY} UPDATE_PAYMENT_TYPE_SUCCESS`,
  UPDATE_PAYMENT_TYPE_ERROR: `${KEY} UPDATE_PAYMENT_TYPE_ERROR`,
  DELETE_PAYMENT_TYPE_REQUEST: `${KEY} DELETE_PAYMENT_TYPE_REQUEST`,
};

export default types;
