import types from "./constants";

export const fetchAttributes = (payload) => ({
  type: types.FETCH_ATTRIBUTES,
  payload,
});

export const setAttributes = (payload) => ({
  type: types.SET_ATTRIBUTES,
  payload,
});

export const addAttribute = (payload, close) => ({
  type: types.ADD_ATTRIBUTE_REQUEST,
  payload,
  close,
});

export const addAttributeSuccess = () => ({
  type: types.ADD_ATTRIBUTE_SUCCESS,
});

export const addAttributeError = () => ({
  type: types.ADD_ATTRIBUTE_ERROR,
});

export const fetchSingleAttribute = (payload) => ({
  type: types.FETCH_SINGLE_ATTRIBUTE,
  payload,
});

export const fetchSingleAttributeError = (error) => ({
  type: types.FETCH_SINGLE_ATTRIBUTE_ERROR,
  error,
});

export const setSingleAttribute = (payload) => ({
  type: types.SET_SINGLE_ATTRIBUTE,
  payload,
});

export const clearSingleAttribute = () => ({
  type: types.CLEAR_SINGLE_ATTRIBUTE,
});

export const updateAttribute = (id, payload, setErrors) => ({
  type: types.UPDATE_ATTRIBUTE_REQUEST,
  id,
  payload,
  meta: { setErrors },
});

export const updateAttributeSuccess = () => ({
  type: types.UPDATE_ATTRIBUTE_SUCCESS,
});

export const updateAttributeError = () => ({
  type: types.UPDATE_ATTRIBUTE_ERROR,
});

export const deleteAttribute = (payload) => ({
  type: types.DELETE_ATTRIBUTE_REQUEST,
  payload,
});

export const fetchKitchenTypes = (payload) => ({
  type: types.FETCH_KITCHEN_TYPES,
  payload,
});

export const setKitchenTypes = (payload) => ({
  type: types.SET_KITCHEN_TYPES,
  payload,
});

export const addKitchenType = (payload, close) => ({
  type: types.ADD_KITCHEN_TYPE_REQUEST,
  payload,
  close,
});

export const addKitchenTypeSuccess = () => ({
  type: types.ADD_KITCHEN_TYPE_SUCCESS,
});

export const addKitchenTypeError = () => ({
  type: types.ADD_KITCHEN_TYPE_ERROR,
});

export const fetchSingleKitchenType = (payload) => ({
  type: types.FETCH_SINGLE_KITCHEN_TYPE,
  payload,
});

export const fetchSingleKitchenTypeError = (error) => ({
  type: types.FETCH_SINGLE_KITCHEN_TYPE_ERROR,
  error,
});

export const setSingleKitchenType = (payload) => ({
  type: types.SET_SINGLE_KITCHEN_TYPE,
  payload,
});

export const clearSingleKitchenType = () => ({
  type: types.CLEAR_SINGLE_KITCHEN_TYPE,
});

export const updateKitchenType = (id, payload, setErrors) => ({
  type: types.UPDATE_KITCHEN_TYPE_REQUEST,
  id,
  payload,
  meta: { setErrors },
});

export const updateKitchenTypeSuccess = () => ({
  type: types.UPDATE_KITCHEN_TYPE_SUCCESS,
});

export const updateKitchenTypeError = () => ({
  type: types.UPDATE_KITCHEN_TYPE_ERROR,
});

export const deleteKitchenType = (payload) => ({
  type: types.DELETE_KITCHEN_TYPE_REQUEST,
  payload,
});

export const fetchFacilityTypes = (payload) => ({
  type: types.FETCH_FACILITY_TYPES,
  payload,
});

export const setFacilityTypes = (payload) => ({
  type: types.SET_FACILITY_TYPES,
  payload,
});

export const addFacilityType = (payload, close) => ({
  type: types.ADD_FACILITY_TYPE_REQUEST,
  payload,
  close,
});

export const addFacilityTypeSuccess = () => ({
  type: types.ADD_FACILITY_TYPE_SUCCESS,
});

export const addFacilityTypeError = () => ({
  type: types.ADD_FACILITY_TYPE_ERROR,
});

export const fetchSingleFacilityType = (payload) => ({
  type: types.FETCH_SINGLE_FACILITY_TYPE,
  payload,
});

export const fetchSingleFacilityTypeError = (error) => ({
  type: types.FETCH_SINGLE_FACILITY_TYPE_ERROR,
  error,
});

export const setSingleFacilityType = (payload) => ({
  type: types.SET_SINGLE_FACILITY_TYPE,
  payload,
});

export const clearSingleFacilityType = () => ({
  type: types.CLEAR_SINGLE_FACILITY_TYPE,
});

export const updateFacilityType = (id, payload, setErrors) => ({
  type: types.UPDATE_FACILITY_TYPE_REQUEST,
  id,
  payload,
  meta: { setErrors },
});

export const updateFacilityTypeSuccess = () => ({
  type: types.UPDATE_FACILITY_TYPE_SUCCESS,
});

export const updateFacilityTypeError = () => ({
  type: types.UPDATE_FACILITY_TYPE_ERROR,
});

export const deleteFacilityType = (payload) => ({
  type: types.DELETE_FACILITY_TYPE_REQUEST,
  payload,
});

export const fetchPaymentTypes = (payload) => ({
  type: types.FETCH_PAYMENT_TYPES,
  payload,
});

export const setPaymentTypes = (payload) => ({
  type: types.SET_PAYMENT_TYPES,
  payload,
});

export const addPaymentType = (payload, close) => ({
  type: types.ADD_PAYMENT_TYPE_REQUEST,
  payload,
  close,
});

export const addPaymentTypeSuccess = () => ({
  type: types.ADD_PAYMENT_TYPE_SUCCESS,
});

export const addPaymentTypeError = () => ({
  type: types.ADD_PAYMENT_TYPE_ERROR,
});

export const fetchSinglePaymentType = (payload) => ({
  type: types.FETCH_SINGLE_PAYMENT_TYPE,
  payload,
});

export const fetchSinglePaymentTypeError = (error) => ({
  type: types.FETCH_SINGLE_PAYMENT_TYPE_ERROR,
  error,
});

export const setSinglePaymentType = (payload) => ({
  type: types.SET_SINGLE_PAYMENT_TYPE,
  payload,
});

export const clearSinglePaymentType = () => ({
  type: types.CLEAR_SINGLE_PAYMENT_TYPE,
});

export const updatePaymentType = (id, payload, setErrors) => ({
  type: types.UPDATE_PAYMENT_TYPE_REQUEST,
  id,
  payload,
  meta: { setErrors },
});

export const updatePaymentTypeSuccess = () => ({
  type: types.UPDATE_PAYMENT_TYPE_SUCCESS,
});

export const updatePaymentTypeError = () => ({
  type: types.UPDATE_PAYMENT_TYPE_ERROR,
});

export const deletePaymentType = (payload) => ({
  type: types.DELETE_PAYMENT_TYPE_REQUEST,
  payload,
});
