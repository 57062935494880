import { Formik } from "formik";

import { useCallback, useEffect, useState } from "react";

import { useInjectReducer } from "../../utils/injectReducer";
import { useInjectSaga } from "../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { useDispatch, useSelector } from "react-redux";
import { clearSingleUser, fetchSingleUser, updateUser } from "./redux/actions";
import {
  makeSelectSingleUser,
  makeSelectUpdateUserIsPending,
} from "./redux/selectors";

const key = "users";

export default function EditUser({ setShowEditUser, onEditUser, userId }) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const user = useSelector(makeSelectSingleUser());
  const isUpdatePending = useSelector(makeSelectUpdateUserIsPending());

  useEffect(() => {
    if (submitting && !isUpdatePending) {
      setShowEditUser(false);
      setSubmitting(false);
    }
  }, [isUpdatePending, submitting, setShowEditUser]);

  useEffect(() => {
    dispatch(fetchSingleUser(userId));

    return () => dispatch(clearSingleUser());
  }, [dispatch, userId]);

  const onSubmit = useCallback(
    (...args) => dispatch(updateUser(...args)),
    [dispatch]
  );

  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="relative flex flex-col w-[500px] rounded-lg gap-8 bg-white px-12 pt-12 pb-6 ">
        <span
          className="absolute top-2 right-4 cursor-pointer"
          onClick={() => setShowEditUser(false)}
        >
          &times;
        </span>
        <span className="text-2xl text-center">Ažuriraj korisnika</span>
        {!user && <div>Učitavanje korisnika..</div>}
        {user && (
          <Formik
            initialValues={{
              name: user.name,
              email: user.email,
              role: user.roles[0],
            }}
            onSubmit={(values, { setErrors, resetForm }) => {
              onSubmit(user.id, values, setErrors, resetForm);
              setSubmitting(true);
            }}
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = "Naziv korisnika je obavezno polje";
              }
              if (!values.email) {
                errors.email = "Email korisnika je obavezno polje";
              }
              if (!values.role) {
                errors.role = "Uloga korisnika je obavezno polje";
              }
              return errors;
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => {
              // console.log("IS SUBMITTING?", isSubmitting);
              // console.log("VALUES: ", values);
              return (
                <form onSubmit={handleSubmit} className="flex flex-col gap-8">
                  <div className="flex flex-col">
                    <label>Ime korisnika*</label>
                    <input
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      placeholder="Unesi ime korisnika"
                      className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                    />
                    {/* <p className="text-red-500 text-sm">
                    {errors.name && touched.name && errors.name}
                  </p> */}
                  </div>
                  <div className="flex flex-col">
                    <label>E-pošta korisnika*</label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder="Unesi e-poštu korisnika"
                      className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                    />
                    {/* <p className="text-red-500 text-sm">
                    {errors.name && touched.name && errors.name}
                  </p> */}
                  </div>

                  <div className="flex flex-col">
                    <label>Uloga korisnika*</label>
                    <select
                      name="role"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.role}
                      className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                    >
                      <option
                        value=""
                        disabled
                        selected
                        className="text-black text-opacity-30"
                      >
                        Izaberi ulogu korisnika
                      </option>
                      <option className="text-sm " value="SuperAdmin">
                        SuperAdmin
                      </option>
                      <option className="text-sm" value="Administrator">
                        Administrator
                      </option>
                      <option className="text-sm" value="Agent">
                        Agent
                      </option>
                      <option className="text-sm" value="Korisnik">
                        Korisnik
                      </option>
                    </select>
                    {/* <p className="text-red-500 text-sm">
                    {errors.parent_id && touched.parent && errors.parent_id}
                  </p> */}
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn-primary mx-auto "
                  >
                    {/* {isCreatePending ? "Dodavanje..." : "Dodaj"} */}
                    Ažuriraj
                  </button>
                </form>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
}
