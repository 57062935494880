import { takeLatest, call, put } from "redux-saga/effects";
//import { push } from "connected-react-router";
import request from "../../../../utils/request";
//import parseApiErrorsToFormik from 'utils/parseApiErrorsToFormik';

import {
  getBanners,
  setBanners,
  setBanner,
  setBannersPositions,
  getBannerError,
  setCategory,
  getBannersSuccess,
} from "./actions";
import types from "./constants";
import { enqueueNotification } from "../../../../notifications/actions";

const BASE_URL = "admin/banners";

export function* fetchBanners({ params }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}`,
      method: "get",
      params,
    });
    yield put(setBanners(res));
    yield put(getBannersSuccess());
  } catch (error) {}
}

export function* fetchBanner({ banner_id }) {
  try {
    const { data } = yield call(request, {
      url: `banners/${banner_id}`,
      method: "get",
    });
    yield put(setBanner(data));
  } catch (error) {
    //if (error.status === 404) yield put(getBannerError(messages.banner404));
  }
}

export function* fetchCategory() {
  try {
    const { data } = yield call(request, {
      url: `admin/categories?paginate=1000&search=`,
      method: "get",
    });
    yield put(setCategory(data));
  } catch (error) {
    //if (error.status === 404) yield put(getBannerError(messages.banner404));
  }
}

export function* fetchBannersPositions() {
  try {
    const { data } = yield call(request, {
      url: `${BASE_URL}-positions`,
      method: "get",
    });
    yield put(setBannersPositions(data));
  } catch (error) {}
}

export function* createBanner({ payload, close }) {
  try {
    const {
      name,
      description,
      link,
      banner_position_id,
      desktop_image,
      categories,
      mobile_image,
      expires_at,
    } = payload;
    const formData = new FormData();
    if (desktop_image)
      formData.append("desktop_image", desktop_image, desktop_image.name);
    if (mobile_image)
      formData.append("mobile_image", mobile_image, mobile_image.name);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("banner_position_id", banner_position_id);
    if (link) formData.append("link", link);
    if (categories) formData.append("categories", categories);
    if (expires_at) formData.append("expires_at", expires_at);

    const res = yield call(request, {
      url: BASE_URL,
      method: "post",
      data: formData,
    });
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(getBanners());
    yield close();

    //yield put(push(routes.CMS_BANNERS));
  } catch (error) {
    if (error.status === 422) {
      //yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}

export function* updateBanner({ banner_id, payload, meta: { setErrors } }) {
  try {
    const {
      name,
      description,
      link,
      banner_position_id,
      desktop_image,
      categories,
      mobile_image,
      expires_at,
    } = payload;
    const formData = new FormData();
    if (desktop_image && desktop_image.name) {
      formData.append("desktop_image", desktop_image, desktop_image.name);
    } else {
      formData.append("desktop_image", desktop_image);
    }

    if (mobile_image && mobile_image.name) {
      formData.append("mobile_image", mobile_image, mobile_image.name);
    } else {
      formData.append("mobile_image", mobile_image);
    }

    formData.append("name", name);
    formData.append("description", description);
    formData.append("banner_position_id", banner_position_id);
    if (link) formData.append("link", link);
    if (categories) formData.append("categories", categories);
    if (expires_at) formData.append("expires_at", expires_at);
    const res = yield call(request, {
      url: `${BASE_URL}/${banner_id}?_method=patch`,
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    //yield put(push(routes.CMS_BANNERS));
  } catch (error) {
    const errorMessage = error.data?.message || "Došlo je do greške";
    yield put(enqueueNotification({ message: errorMessage, type: "error" }));
  }
}

export function* handleChangeOrder({ payload }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/orders`,
      method: "post",
      data: payload,
    });
    yield put(getBanners());
  } catch (error) {}
}

export function* updateBannerPhoto({ banner_id, photo }) {
  try {
    const formData = new FormData();
    formData.append(`image`, photo, photo.name);

    const res = yield call(request, {
      url: `${BASE_URL}/${banner_id}/desktop-image`,
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {}
}

export function* updateBannerMobilePhoto({ banner_id, photo }) {
  try {
    const formData = new FormData();
    formData.append(`image`, photo, photo.name);

    const res = yield call(request, {
      url: `${BASE_URL}/${banner_id}/mobile-image`,
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {}
}

export function* deleteBanner({ banner_id }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${banner_id}`,
      method: "delete",
    });
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(getBanners());
  } catch (error) {}
}

export default function* cmsBannersSaga() {
  yield takeLatest(types.GET_BANNERS_REQUEST, fetchBanners);
  yield takeLatest(types.GET_BANNER, fetchBanner);
  yield takeLatest(types.GET_CATEGORY, fetchCategory);
  yield takeLatest(types.GET_BANNERS_POSITIONS, fetchBannersPositions);
  yield takeLatest(types.CHANGE_ORDER_REQUEST, handleChangeOrder);

  yield takeLatest(types.CREATE_BANNER, createBanner);
  yield takeLatest(types.UPDATE_BANNER, updateBanner);
  yield takeLatest(types.UPDATE_BANNER_PHOTO, updateBannerPhoto);
  yield takeLatest(types.UPDATE_BANNER_MOBILE_PHOTO, updateBannerMobilePhoto);
  yield takeLatest(types.DELETE_BANNER, deleteBanner);
}
