import types from "./constants";

export const getCategories = () => ({
  type: types.GET_CATEGORIES_REQUEST,
});

export const getCategoriesSuccess = () => ({
  type: types.GET_CATEGORIES_SUCCESS,
});

export const setCategories = (payload) => ({
  type: types.SET_CATEGORIES,
  payload,
});

export const addCategory = (payload, closeModal) => ({
  type: types.ADD_CATEGORY,
  payload,
  closeModal,
});

export const addCategoryError = (error) => ({
  type: types.ADD_CATEGORY_ERROR,
  error,
});

export const changeImage = (category_id, image, close) => ({
  type: types.CHANGE_IMAGE_REQUEST,
  category_id,
  image,
  close,
});

export const changeImageSuccess = (error) => ({
  type: types.CHANGE_IMAGE_SUCCESS,
  error,
});

export const changeOrder = (payload) => ({
  type: types.CHANGE_ORDER,
  payload,
});

export const deleteCategory = (category_id) => ({
  type: types.DELETE_CATEGORY,
  category_id,
});

export const setActiveDevice = (device) => ({
  type: types.SET_ACTIVE_DEVICE,
  device,
});
