import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "../../../utils/injectReducer";
import { useInjectSaga } from "../../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { useEffect, useState } from "react";

import { makeSelectBannersPositions } from "./redux/selectors";
import { getBannersPositions } from "./redux/actions";

const key = "cms_banners";

export default function PositionsDropdown({
  setSelectedPosition,
  setShowPositions,
}) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();

  const positions = useSelector(makeSelectBannersPositions());

  useEffect(() => {
    dispatch(getBannersPositions());
  }, [dispatch]);

  const handleClick = (position) => {
    setSelectedPosition(position);
    setShowPositions(false);
    //toggle();
  };

  if (!positions) {
    return null;
  }

  return (
    <div className="absolute top-16 bg-white z-10 p-2 border border-black border-opacity-20 w-full rounded-md ">
      {positions &&
        positions.map((position) => (
          <div
            key={position.id}
            onClick={() => handleClick(position)}
            className="cursor-pointer"
          >
            {position.name}
          </div>
        ))}
    </div>
  );
}
