import React, { useEffect, useState } from "react";
import { IoCheckmark } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";
import CateringModal from "./cateringModal";
import DeliveryModal from "./deliveryModal";

const RadioInputDelivery = ({
  name,
  value,
  checkedValue,
  onChange,
  labelText,
  editClient,
}) => {
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    // Only proceed if it's not the first render and editClient is false
    if (!isFirstRender && !editClient) {
      if (value === "yes" && checkedValue === value) {
        setShowDeliveryModal(true);
      } else {
        setShowDeliveryModal(false);
      }
    }
    // Mark it as not first render for subsequent renders
    setIsFirstRender(false);
  }, [checkedValue, value, isFirstRender, editClient]);

  return (
    <label className="custom-option">
      {showDeliveryModal && (
        <DeliveryModal setShowDeliveryModal={setShowDeliveryModal} />
      )}

      <input
        className="custom_radio"
        type="radio"
        name={name}
        value={value}
        checked={checkedValue === value}
        onChange={() => onChange(name, value)}
      />
      <span
        className={`custom-radio flex items-center justify-center ${
          checkedValue === value ? "bg-red-500" : "bg-white"
        }`}
      >
        {checkedValue === value ? (
          value === "yes" ? (
            <IoCheckmark
              color="white"
              className="rounded-xl p-[2px]"
              size={20}
              onClick={() => setShowDeliveryModal(true)}
            />
          ) : (
            <IoIosClose color="white" className="rounded-xl" size={20} />
          )
        ) : (
          ""
        )}
      </span>
      {labelText}
    </label>
  );
};

export default RadioInputDelivery;
