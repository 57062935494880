import { useCallback, useEffect, useRef, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbSection } from "react-icons/tb";
import { useInjectReducer } from "../../../utils/injectReducer";
import { useInjectSaga } from "../../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { useDispatch } from "react-redux";
import {
  changeOrder,
  clearBanner,
  createBanner,
  deleteBanner,
  getBanners,
  updateBanner,
} from "./redux/actions";
import { useSelector } from "react-redux";
import {
  makeSelectBanners,
  makeSelectBannersLoading,
  makeSelectParams,
} from "./redux/selectors";
import CreateBannerForm from "./create-banner";
import EditBannerForm from "./edit-banner";
import Sortable from "sortablejs";

const key = "cms_banners";

export default function BannersPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const sortableContainerRef = useRef(null);
  const [data, setData] = useState([]);
  console.log("BANNERS DATA:", data);

  const [banner_id, setBanner_id] = useState(null);

  const banners = useSelector(makeSelectBanners()).data;
  const isLoading = useSelector(makeSelectBannersLoading());
  const params = useSelector(makeSelectParams());

  useEffect(() => {
    dispatch(getBanners(params));
    dispatch(clearBanner());
  }, [dispatch, params]);

  useEffect(() => {
    if (banners && !isLoading) {
      setData(banners);
    }
  }, [dispatch, isLoading]);

  const onSubmitUpdate = useCallback(
    (...args) => dispatch(updateBanner(...args)),
    [dispatch]
  );

  useEffect(() => {
    if (sortableContainerRef.current) {
      const sortable = new Sortable(sortableContainerRef.current, {
        animation: 150,
        onEnd: (/**Event*/ evt) => {
          const { newIndex, oldIndex } = evt;
          const newData = [...data];
          newData.splice(newIndex, 0, newData.splice(oldIndex, 1)[0]);
          setData(newData);

          handleChangeOrder(newData);
        },
      });

      return () => {
        sortable.destroy();
      };
    }
  }, [data]);

  const handleChangeOrder = (newOrder) => {
    const bannersPayload = newOrder.map((item, index) => ({
      id: item.id,
      order: index,
    }));

    dispatch(changeOrder({ banner_orders: bannersPayload }));
  };

  const handleDelete = useCallback(
    (...args) => dispatch(deleteBanner(...args)),
    [dispatch]
  );

  const closeCreateForm = () => {
    setShowCreateForm(false);
    //setCategory(null);
  };

  return (
    <div className="flex-col">
      {!showCreateForm && !showEditForm && (
        <>
          <div className="flex justify-between bg-white p-5 mb-4 rounded-md">
            <div className="left flex gap-1">
              <TbSection size={25} />
              <div className=" flex flex-col">
                <span className="text-2xl">Baneri</span>
                <span className="text-opacity-30 text-sm">banera ukupno</span>
              </div>
            </div>
            <div className="right">
              <button
                className="btn-primary"
                onClick={() => setShowCreateForm(true)}
              >
                Dodaj Baner
              </button>
            </div>
          </div>

          <div className="bg-white flex justify-between p-5  border-b border-black border-opacity-20">
            <div className="grid grid-cols-2 w-[1200px]">
              <span>Ime</span>
              <span>Redosled</span>
            </div>
            <span>Akcija </span>
          </div>
          <div className="banner-list bg-white" ref={sortableContainerRef}>
            {/* {_.sortBy(data, ["order"]).map((section, index) => { */}
            {data.map((banner, index) => {
              return (
                <div
                  key={banner.id}
                  data-id={banner.id}
                  className="sortable-item client_row px-5 flex justify-between items-center cursor-pointer"
                >
                  <div className="grid grid-cols-2 w-[1200px] py-4 text-sm">
                    <span>{banner.name}</span>
                    <span>{banner.order + 1}</span>
                  </div>
                  <div className="flex gap-3">
                    <FiEdit
                      size={20}
                      color="gray"
                      className="cursor-pointer"
                      onClick={() => {
                        setBanner_id(banner.id);
                        setShowEditForm(true);
                      }}
                    />
                    <RiDeleteBin6Line
                      size={20}
                      color="gray"
                      onClick={() => handleDelete(banner.id)}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {showCreateForm && (
        <CreateBannerForm
          //onSubmit={onSubmitCreate}
          setShowCreateForm={setShowCreateForm}
          close={closeCreateForm}
        />
      )}
      {showEditForm && (
        <EditBannerForm
          onSubmit={onSubmitUpdate}
          banner_id={banner_id}
          setBanner_id={setBanner_id}
          setShowEditForm={setShowEditForm}
        />
      )}
    </div>
  );
}
