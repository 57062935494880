import { Formik } from "formik";
import { useInjectReducer } from "../../utils/injectReducer";
import { useInjectSaga } from "../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { addCategory } from "./redux/actions";
import { makeSelectCreateCategoryIsPending } from "./redux/selectors";
import { useNavigate } from "react-router-dom";
//import 'react-select-search/style.css'
import SelectSearch from 'react-select-search';

const key = "categories";

export default function AddCategory({
  setShowAddCategory,
  categories,
  onAddCategory,
}) {
  const [submitting, setSubmitting] = useState(false);

  const isCreatePending = useSelector(makeSelectCreateCategoryIsPending());

  const [fileName, setFileName] = useState("");

  const onSubmit = (values) => {
    onAddCategory(values);
  };

  useEffect(() => {
    if (submitting && !isCreatePending) {
      setShowAddCategory(false);
      setSubmitting(false);
    }
  }, [isCreatePending, submitting, setShowAddCategory]);

  const [parentCategory, setParentCategory] = useState("");
  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="relative flex flex-col w-[500px] rounded-lg gap-8 bg-white px-12 pt-12 pb-6 ">
        <span
          className="absolute top-2 right-4 cursor-pointer"
          onClick={() => setShowAddCategory(false)}
        >
          x
        </span>
        <span className="text-2xl text-center">Dodaj novu kategorju</span>
        <Formik
          initialValues={{ name: "", image: "" }}
          onSubmit={(values, { setErrors, resetForm }) => {
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("parent_id", parentCategory);
            formData.append("image", values.image);

            //setFileName('');
            onSubmit(formData, setErrors, resetForm);
            setSubmitting(true);
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Naziv nove kategorije je obavezno polje";
            }
            return errors;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => {
            const handleFileChange = (event) => {
              const file = event.currentTarget.files[0];
              if (file) {
                setFieldValue("image", file);
                setFileName(file.name);
              }
            };
            // console.log("IS SUBMITTING?", isSubmitting);
            // console.log("VALUES: ", values);
            return (
              <form onSubmit={handleSubmit} className="flex flex-col gap-8">
                <div className="flex flex-col">
                  <label>Naziv kategorije roditelj</label>
                  <SelectSearch id="select" onChange={setParentCategory}   search options={categories?.map(i=> {return {name:i.name, value:i.id}})} value={parentCategory} name="parent_id" placeholder="Pretraži po nazivu" />
                  {/* <select
                    name="parent_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.parent_id}
                    className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm text-opacity-30"
                  >
                    <option><input className="h-10 w-full"  /></option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select> */}
                  <p className="text-red-500 text-sm">
                    {errors.parent_id && touched.parent && errors.parent_id}
                  </p>
                </div>
                <div className="flex flex-col">
                  <label>Naziv nove kategorije*</label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    placeholder="Unesi naziv nove kategorije"
                    className="px-4 py-2 h-14 border-2  border-gray-300 border-opacity-70 placeholder:text-base text-sm "
                  />
                  <p className="text-red-500 text-sm">
                    {errors.name && touched.name && errors.name}
                  </p>
                </div>
                <div>
                  <label htmlFor="">Slika kategorije</label>
                  <input
                    id="fileInput"
                    type="file"
                    name="image"
                    className="hidden"
                    onChange={handleFileChange}
                  ></input>
                  <div
                    className="flex border border-dashed border-black border-opacity-20 w-fit p-8 rounded-lg cursor-pointer text-sm text-opacity-30"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    Unesi sliku
                  </div>
                  <p>Izabrana slika: </p>
                  {fileName.length > 1 && <span>{fileName.slice(0, 15)}</span>}
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn-primary mx-auto "
                >
                  {isCreatePending ? "Dodavanje..." : "Dodaj"}
                </button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
