import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TreeNode from "./treeNode";
//import AddCategory from "./addCategory";
import { makeSelectUser } from "../../containers/App/selectors";
import { BiNetworkChart } from "react-icons/bi";
import {
  makeSelectCategories,
  makeSelectClientCategoriesIds,
  makeSelectClientConnect,
  makeSelectClients,
} from "./redux/selectors";
import {
  clearClientCategoriesIds,
  clearConnectClient,
  connectClientCategories,
  disconnectClientCategories,
  fetchCategories,
  fetchClients,
  setConnectClient,
} from "./redux/actions";
import { useInjectReducer } from "../../utils/injectReducer";
import { useInjectSaga } from "../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";

const key = "categories";

export default function ClientCategoriesPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const clientConnect = useSelector(makeSelectClientConnect());
  const [selectedClient, setSelectedClient] = useState("");
  const [filterText, setFilterText] = useState("");
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [showClients, setShowClients] = useState(false);
  const [showDisconnect, setShowDisconnect] = useState(false);

  const dispatch = useDispatch();
  const categories = useSelector(makeSelectCategories());
  const user = useSelector(makeSelectUser());
  const clients = useSelector(makeSelectClients()).data;
  const categoriesIds = useSelector(makeSelectClientCategoriesIds());

  //console.log("selectedClient:", selectedClient);
  //console.log("categoriesIds:", categoriesIds);

  let filteredClients;
  let client;

  if (clients) {
    if (filterText) {
      filteredClients = clients.filter((client) =>
        client.company_name.toLowerCase().includes(filterText.toLowerCase())
      );
    } else {
      filteredClients = clients;
    }
    //client = clients.find((c) => c.id === clientConnect);
  }
  // console.log("filteredClients:", filteredClients);
  // console.log("client:", client);

  // const filteredClients = clients.filter((client) =>
  //   client.name.toLowerCase().includes(filterText.toLowerCase())
  // );

  const handleSelectChange = (event) => {
    setSelectedClient(event.target.value);
  };

  const handleClientConnect = useCallback(
    (...args) => dispatch(setConnectClient(...args)),
    [dispatch]
  );

  const handleFilterInputChange = (event) => {
    setFilterText(event.target.value);
    //setSelectedClient(""); // Reset selected client when filtering
  };

  function findCompanyNameById(id) {
    const selectedClient = clients.find((client) => client.id === id);
    return selectedClient ? selectedClient.company_name : "";
  }

  const { data } = categories;

  let topLevelItems;

  if (data) {
    topLevelItems = data.filter((item) => item.parent_id === null);
  }

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchClients());
    dispatch(clearClientCategoriesIds());
    dispatch(clearConnectClient());
  }, [dispatch]);

  const clearClient = () => {
    dispatch(clearConnectClient());
    dispatch(clearClientCategoriesIds());
    setShowClients(false);
  };

  const handleConnect = useCallback(
    (...args) => dispatch(connectClientCategories(...args)),
    [dispatch]
  );

  const handleDisconnect = useCallback(
    (...args) => dispatch(disconnectClientCategories(...args)),
    [dispatch]
  );

  function connectClient() {
    if (clientConnect && categoriesIds.length > 0)
      //const payload = { client_id: selectedClient, categories: categoriesIds };
      //console.log("SUBMIT payload", payload);
      handleConnect(clientConnect, { categories: categoriesIds });
    dispatch(clearClientCategoriesIds());
  }

  function disconnectClient() {
    //const payload = { client_id: selectedClient, categories: categoriesIds };
    //console.log("SUBMIT payload", payload);
    handleDisconnect(clientConnect, { categories: categoriesIds });
    dispatch(clearClientCategoriesIds());
  }

  // Update showClients when filterText changes or input is focused
  useEffect(() => {
    if (filterText.length > 0 || inputIsFocused) {
      setShowClients(true);
    } else {
      setShowClients(false);
    }
  }, [filterText, inputIsFocused]);

  // Handle input focus events
  const handleInputFocus = () => {
    setInputIsFocused(!inputIsFocused);
  };

  // const handleInputBlur = () => {
  //   setInputIsFocused(false);
  // };

  if (!user) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="flex justify-between bg-white p-5 mb-4 rounded-md">
        <div className="left flex gap-1">
          <BiNetworkChart size={25} />
          <div className=" flex flex-col">
            <span className="text-2xl">Povezivanje</span>
          </div>
        </div>

        <div className="flex items-end gap-6 right">
          <div className="flex flex-col gap-2 w-[300px] relative">
            <label className="text-sm">
              Upiši naziv ugostiteljskog objekta
            </label>
            <input
              type="text"
              placeholder="Naziv ugostiteljskog objekta"
              value={
                clientConnect ? findCompanyNameById(clientConnect) : filterText
              }
              onChange={handleFilterInputChange}
              onClick={handleInputFocus}
              //onBlur={handleInputBlur}
            />
            {clientConnect && (
              <div
                className="absolute right-2 top-8 text-xl cursor-pointer"
                onClick={() => clearClient()}
              >
                &times;
              </div>
            )}

            {showClients && (
              <div className="absolute top-16 bg-white w-full p-2 border border-black border-opacity-30 z-10">
                {filteredClients &&
                  filteredClients.map((client) => (
                    <div
                      key={client.id}
                      onClick={() => {
                        handleClientConnect(client.id);
                        setFilterText("");
                        setInputIsFocused(false);
                      }}
                      className="cursor-pointer"
                    >
                      {client.company_name}
                    </div>
                  ))}
              </div>
            )}
            {/* <select value={selectedClient} onChange={handleSelectChange}>
              <option value="" disabled>
                Select a client
              </option>
              {filteredClients &&
                filteredClients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.company_name}
                  </option>
                ))}
            </select> */}
          </div>
          <div className="flex items-center gap-2 right">
            {!showDisconnect && (
              <button
                onClick={() => connectClient()}
                className="btn-primary"
                disabled={!clientConnect && categoriesIds.length > 0}
              >
                Poveži
              </button>
            )}

            <button
              onClick={() => disconnectClient()}
              className="btn-secondary"
            >
              {/* {showDisconnect ? "Povezivanje" : "Razvezivanje"} */}
              Odveži
            </button>
          </div>
        </div>
      </div>
      {/* {showAddCategory && (
        <div>
          <AddCategory
            setShowAddCategory={setShowAddCategory}
            categories={data}
            onAddCategory={handleAddCategory}
          />
        </div>
      )} */}
      {/* {showEditCategory && (
        <div>
          <EditCategory
            categories={data}
            setShowEditCategory={handleEditClick}
            node={editCategory}
          />
        </div>
      )} */}
      <div className="bg-white flex justify-between  p-5 border-b border-black border-opacity-20">
        <span>
          {showDisconnect ? "Povezane kategorije" : "Naziv kategorije"}{" "}
        </span>
        <span>Akcija</span>
      </div>

      {clientConnect &&
        !showDisconnect &&
        data &&
        topLevelItems.map((item) => (
          <TreeNode
            key={item.id}
            node={item}
            data={data}
            connect={true}

            //onEditClick={handleEditClick}
          />
        ))}
      {/* {clientConnect &&
        showDisconnect &&
        data &&
        topLevelItems.map((item) => (
          <TreeNode
            key={item.id}
            node={item}
            data={data}
            connect={true}
            dissconnect={true}

            //onEditClick={handleEditClick}
          />
        ))} */}
      {/* {showDisconnect && client && (
        <div className="bg-white p-5 flex flex-col gap-2 rounded-md">
          {client.categories.map((c) => (
            <div className="flex items-center justify-between border-b border-opacity-20">
              <div>{c.name}</div>
              <div>
                <button
                  className="bg-red-500 text-white p-2 rounded-md text-sm"
                  onClick={() =>
                    handleDisconnect(clientConnect, { category: c.id })
                  }
                >
                  Razveži
                </button>
              </div>
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
}
