import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectDeliveryPeople = (state) => state.deliveryPeople || initialState;

export const makeSelectDeliveryPeople = () =>
    createSelector(selectDeliveryPeople, (substate) => substate.deliverers);

export const makeSelectAddDelivererIsPending = () =>
    createSelector(selectDeliveryPeople, (substate) => substate.addDelivererIsPending);

export const makeSelectUpdateDelivererIsPending = () =>
    createSelector(selectDeliveryPeople, (substate) => substate.updateDelivererIsPending);

export const makeSelectDeleteDelivererIsPending = () =>
    createSelector(selectDeliveryPeople, (substate) => substate.deleteDelivererIsPending);
