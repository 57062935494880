import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectAttributes = (state) => state.attributes || initialState;

export const makeSelectAttributes = () =>
  createSelector(selectAttributes, (substate) => substate.attributes.data);

export const makeSelectSingleAttribute = () =>
  createSelector(selectAttributes, (substate) => substate.singleAttribute);

export const makeSelectSingleAttributeError = () =>
  createSelector(selectAttributes, (substate) => substate.singleAttributeError);

export const makeSelectKitchenTypes = () =>
  createSelector(selectAttributes, (substate) => substate.kitchenTypes.data);

export const makeSelectSingleKitchenType = () =>
  createSelector(selectAttributes, (substate) => substate.singleKitchenType);

export const makeSelectSingleKitchenTypeError = () =>
  createSelector(
    selectAttributes,
    (substate) => substate.singleKitchenTypeError
  );

export const makeSelectFacilityTypes = () =>
  createSelector(selectAttributes, (substate) => substate.facilityTypes.data);

export const makeSelectSingleFacilityType = () =>
  createSelector(selectAttributes, (substate) => substate.singleFacilityType);

export const makeSelectSingleFacilityTypeError = () =>
  createSelector(
    selectAttributes,
    (substate) => substate.singleFacilityTypeError
  );

export const makeSelectPaymentTypes = () =>
  createSelector(selectAttributes, (substate) => substate.paymentTypes.data);

export const makeSelectSinglePaymentType = () =>
  createSelector(selectAttributes, (substate) => substate.singlePaymentType);

export const makeSelectSinglePaymentTypeError = () =>
  createSelector(
    selectAttributes,
    (substate) => substate.singlePaymentTypeError
  );
