import { useCallback, useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import CategoriesDropdown from "./categories-dropdown";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useInjectReducer } from "../../../utils/injectReducer";
import { useInjectSaga } from "../../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { useDispatch } from "react-redux";
import { addCategory, changeImage } from "./redux/actions";

const key = "home_screen_categories";

export default function EditCategory({ setShowEditForm, category, close }) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();

  const [preview, setPreview] = useState(category.image);
  const [error, setError] = useState("");
  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [photo, setPhoto] = useState(null);

  const uploadPhoto = (e) => {
    let file = e.target.files[0];

    if (file) {
      if (file.size > 2000000) {
        setError("Maksimalni limit slike je 2MB");
      } else {
        setError("");
        setPhoto(file);
        setPreview(URL.createObjectURL(file));
      }
    }
  };

  const handleSubmit = () => {
    if (photo) {
      //const payload = { category.id, photo };
      handleEditCategory(category.id, photo, close);
    }
  };

  const handleEditCategory = useCallback(
    (...args) => dispatch(changeImage(...args)),
    [dispatch]
  );

  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="relative flex flex-col  items-center w-[400px] rounded-lg gap-8 bg-white px-12 pt-12 pb-6 ">
        <span
          className="absolute top-2 right-4 cursor-pointer"
          onClick={() => setShowEditForm(false)}
        >
          &times;
        </span>

        <div>
          <input
            id="fileInput"
            type="file"
            name="image"
            className="hidden"
            onChange={uploadPhoto}
          ></input>
          {!preview && (
            <div
              className="flex items-center justify-center border border-dashed border-black border-opacity-20 h-[250px] w-[250px] p-8 rounded-lg cursor-pointer text-sm text-black text-opacity-40"
              onClick={() => document.getElementById("fileInput").click()}
            >
              Unesi sliku
            </div>
          )}

          {preview && (
            <div
              className="h-[250px] w-[250px]"
              style={{
                backgroundImage: `url(${preview})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              onClick={() => document.getElementById("fileInput").click()}
            >
              <FaTimesCircle onClick={() => setPreview(null)} />
            </div>
          )}

          {/* {fileName.length > 1 && <span>{fileName.slice(0, 15)}</span>} */}
        </div>

        <button className="btn-primary" onClick={handleSubmit}>
          Izmeni Sliku
        </button>
      </div>
    </div>
  );
}
