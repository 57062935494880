import React, { useState } from "react";
import { Field } from "formik";
//import { useIntl } from 'react-intl';

const FormikField = ({
  type,
  name,
  label,
  errors,
  touched,
  focus = false,
  readOnly = false,
  ...rest
}) => {
  return (
    <div className="input_holder">
      <div className="form_field flex flex-col">
        <label htmlFor={name}>{label}</label>
        <Field
          type={type}
          name={name}
          autoFocus={focus}
          readOnly={readOnly}
          {...rest}
          className="border-gray-300 py-2"
        />
      </div>
      {errors && errors[name] && touched[name] && (
        <p className="error_msg">{errors[name]}</p>
      )}
    </div>
  );
};

export default FormikField;
