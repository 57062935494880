export const ENQUEUE_NOTIFICATION = "ENQUEUE_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

export const enqueueNotification = (notification) => ({
  type: ENQUEUE_NOTIFICATION,
  payload: notification,
});

export const removeNotification = (notificationId) => ({
  type: REMOVE_NOTIFICATION,
  payload: notificationId,
});
