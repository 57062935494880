import { BsTag } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useInjectReducer } from "../../utils/injectReducer";
import { useInjectSaga } from "../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectAttributes,
  makeSelectFacilityTypes,
  makeSelectKitchenTypes,
  makeSelectPaymentTypes,
} from "./redux/selectors";
import { useCallback, useEffect, useState } from "react";
import {
  addAttribute,
  addFacilityType,
  addKitchenType,
  addPaymentType,
  deleteAttribute,
  deleteFacilityType,
  deleteKitchenType,
  deletePaymentType,
  fetchAttributes,
  fetchFacilityTypes,
  fetchKitchenTypes,
  fetchPaymentTypes,
} from "./redux/actions";
import AddAttribute from "./addAttribute";
import EditAttribute from "./editAttribute";
import { makeSelectUser } from "../../containers/App/selectors";

const key = "attributes";

export default function AttributesPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  //console.log("showEditForm:", showEditForm);
  const [editAttributeId, setEditAttributeId] = useState("");
  const [attributeGroup, setAttributeGroup] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAttributes());
    dispatch(fetchKitchenTypes());
    dispatch(fetchFacilityTypes());
    dispatch(fetchPaymentTypes());
  }, [dispatch]);

  const attributes = useSelector(makeSelectAttributes());
  //console.log(attributes);
  const kitchenTypes = useSelector(makeSelectKitchenTypes());
  const facilityTypes = useSelector(makeSelectFacilityTypes());
  const paymentTypes = useSelector(makeSelectPaymentTypes());

  const handleAddAttribute = useCallback(
    (...args) => dispatch(addAttribute(...args)),
    [dispatch]
  );
  const handleDeleteAttribute = useCallback(
    (...args) => dispatch(deleteAttribute(...args)),
    [dispatch]
  );

  const handleAddFacilityType = useCallback(
    (...args) => dispatch(addFacilityType(...args)),
    [dispatch]
  );

  const handleDeleteFacilityType = useCallback(
    (...args) => dispatch(deleteFacilityType(...args)),
    [dispatch]
  );

  const handleAddKitchenType = useCallback(
    (...args) => dispatch(addKitchenType(...args)),
    [dispatch]
  );
  const handleDeleteKitchenType = useCallback(
    (...args) => dispatch(deleteKitchenType(...args)),
    [dispatch]
  );

  const handleAddPaymentType = useCallback(
    (...args) => dispatch(addPaymentType(...args)),
    [dispatch]
  );
  const handleDeletePaymentType = useCallback(
    (...args) => dispatch(deletePaymentType(...args)),
    [dispatch]
  );

  const user = useSelector(makeSelectUser());

  const closeModal = () => {
    setShowCreateForm(false);
  };

  if (!user) {
    return null;
  }

  return (
    <div className="flex-col">
      <div className="flex justify-between bg-white p-5 mb-4 rounded-md">
        <div className="left flex gap-1">
          <BsTag size={25} />

          <div className=" flex flex-col">
            <h1 className="text-2xl">Atributi</h1>
            <span className="text-opacity-30 text-sm">11 atributa ukupno</span>
          </div>
        </div>

        <div className="right">
          <button
            onClick={() => setShowCreateForm(true)}
            className="btn-primary"
          >
            Dodaj novi atribut
          </button>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-6">
        {kitchenTypes && (
          <div className="bg-white">
            <div className="flex items-center gap-2 px-5 py-4 border-b border-black border-opacity-20">
              <h2>Vrsta kuhinje</h2>
              <span className="text-black text-opacity-30 text-sm ">
                {kitchenTypes.length} atributa ukupno
              </span>
            </div>
            <div>
              {kitchenTypes &&
                kitchenTypes.map((kitchen) => (
                  <div
                    key={kitchen.id}
                    className="flex items-center justify-between px-5 py-4"
                  >
                    <span className="text-sm">{kitchen.name}</span>
                    <div className="flex gap-3">
                      <FiEdit
                        size={20}
                        color="gray"
                        className="cursor-pointer"
                        onClick={() => {
                          setEditAttributeId(kitchen.id);
                          setAttributeGroup("kitchenType");
                          setShowEditForm(!showEditForm);
                        }}
                      />
                      {user.roles[0] !== "Agent" &&
                        user.roles[0] !== "Korisnik" && (
                          <RiDeleteBin6Line
                            size={20}
                            color="gray"
                            className="cursor-pointer"
                            onClick={() => handleDeleteKitchenType(kitchen.id)}
                          />
                        )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        {facilityTypes && (
          <div className="bg-white">
            <div className="flex items-center gap-2 px-5 py-4 border-b border-black border-opacity-20">
              <h2>Tip objekta</h2>
              <span className="text-black text-opacity-30 text-sm ">
                {facilityTypes.length} atributa ukupno
              </span>
            </div>
            <div>
              {facilityTypes &&
                facilityTypes.map((facility) => (
                  <div
                    key={facility.id}
                    className="flex items-center justify-between px-5 py-4"
                  >
                    <span className="text-sm">{facility.name}</span>
                    <div className="flex gap-3">
                      <FiEdit
                        size={20}
                        color="gray"
                        className="cursor-pointer"
                        onClick={() => {
                          setEditAttributeId(facility.id);
                          setAttributeGroup("facilityType");
                          setShowEditForm(!showEditForm);
                        }}
                      />
                      {user.roles[0] !== "Agent" &&
                        user.roles[0] !== "Korisnik" && (
                          <RiDeleteBin6Line
                            size={20}
                            color="gray"
                            className="cursor-pointer"
                            onClick={() =>
                              handleDeleteFacilityType(facility.id)
                            }
                          />
                        )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        {paymentTypes && (
          <div className="bg-white">
            <div className="flex items-center gap-2 px-5 py-4 border-b border-black border-opacity-20">
              <h2>Način plaćanja</h2>
              <span className="text-black text-opacity-30 text-sm ">
                {paymentTypes.length} atributa ukupno
              </span>
            </div>
            <div>
              {paymentTypes &&
                paymentTypes.map((payment) => (
                  <div
                    key={payment.id}
                    className="flex items-center justify-between px-5 py-4"
                  >
                    <span className="text-sm">{payment.name}</span>
                    <div className="flex gap-3">
                      <FiEdit
                        size={20}
                        color="gray"
                        className="cursor-pointer"
                        onClick={() => {
                          setEditAttributeId(payment.id);
                          setAttributeGroup("paymentType");
                          setShowEditForm(!showEditForm);
                        }}
                      />
                      {user.roles[0] !== "Agent" &&
                        user.roles[0] !== "Korisnik" && (
                          <RiDeleteBin6Line
                            size={20}
                            color="gray"
                            className="cursor-pointer"
                            onClick={() => handleDeletePaymentType(payment.id)}
                          />
                        )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        {attributes && (
          <div className="bg-white">
            <div className="flex items-center gap-2 px-5 py-4 border-b border-black border-opacity-20">
              <h2>Ostali atributi</h2>
              <span className="text-black text-opacity-30 text-sm ">
                {attributes.length} atributa ukupno
              </span>
            </div>
            <div>
              {attributes &&
                attributes.map((attribute) => (
                  <div
                    key={attribute.id}
                    className="flex items-center justify-between px-5 py-4"
                  >
                    <span className="text-sm">{attribute.name}</span>
                    <div className="flex gap-3">
                      <FiEdit
                        size={20}
                        color="gray"
                        className="cursor-pointer"
                        onClick={() => {
                          setEditAttributeId(attribute.id);
                          setAttributeGroup("attribute");
                          setShowEditForm(!showEditForm);
                        }}
                      />
                      {user.roles[0] !== "Agent" &&
                        user.roles[0] !== "Korisnik" && (
                          <RiDeleteBin6Line
                            size={20}
                            color="gray"
                            className="cursor-pointer"
                            onClick={() => handleDeleteAttribute(attribute.id)}
                          />
                        )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
      {showCreateForm && (
        <AddAttribute
          showCreateForm={setShowCreateForm}
          onAddAttribute={handleAddAttribute}
          onAddFacilityType={handleAddFacilityType}
          onAddKitchenType={handleAddKitchenType}
          onAddPaymentType={handleAddPaymentType}
          close={closeModal}
        />
      )}
      {showEditForm && (
        <EditAttribute
          showEditForm={setShowEditForm}
          editAttributeId={editAttributeId}
          attribute_group={attributeGroup}
        />
      )}
    </div>
  );
}
