import React, { useCallback, useState } from "react";
import UploadMainPhoto from "../clients/UploadMainPhoto";
import { useInjectReducer } from "../../utils/injectReducer";
import { useInjectSaga } from "../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { useDispatch } from "react-redux";
import { addDeliveryPersonRequest, deleteDeliveryPersonRequest } from "./redux/actions";
import { Button } from "@mui/material";
import { BiX } from "react-icons/bi";
import { FaTimesCircle } from "react-icons/fa";

const key = "deliveryPeople";

export default function AddDeliverer({ setOpen }) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const [photo, setPhoto] = useState(null);
  const [name, setName] = useState("");
  const [preview, setPreview] = useState(null);

  const dispatch = useDispatch();

  const handleAddClient = useCallback(
    (...args) => dispatch(addDeliveryPersonRequest(...args)),
    [dispatch]
  );

  console.log(photo);
  console.log(name);

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setPhoto(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-gray-600 bg-opacity-20 flex justify-center items-center"
    >
      <div
        className="bg-white p-10 px-20 relative rounded-md shadow-md"
      >
        <div onClick={() => setOpen(false)} className="absolute top-0 right-0">
          <BiX width={30} height={30} className="w-7 cursor-pointer h-7" />
        </div>
        <p className="text-3xl text-center mb-10">Novi dostavljač</p>
        <input
          type="text"
          value={name}
          name="name"
          placeholder="Ime dostavljača"
          onChange={(e) => setName(e.target.value)}
        />

        <input
          id="fileInput"
          type="file"
          name="image"
          className="hidden"
          onChange={handleFileChange}
        ></input>
        <div
          className="flex border border-dashed mx-auto my-10 border-black border-opacity-20 w-fit p-8 rounded-lg cursor-pointer text-sm text-opacity-30"
          onClick={() => document.getElementById("fileInput").click()}
        >
          Unesi sliku
        </div>
        {preview && (
          <div
            className="h-[150px] mx-auto w-[150px]"
            style={{
              backgroundImage: `url(${preview})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <FaTimesCircle onClick={() => setPreview(null)} />
          </div>
        )}
        <div className="mx-auto w-full flex justify-center items-center">
          <button
            className="btn-primary flex justify-center items-center"
            onClick={() => {
              handleAddClient({ name, image:photo })
              setOpen(false)
            }}
          >
            Dodaj novog dostavljača
          </button>
        </div>
      </div>
    </div>
  );
}
