import { produce } from "immer";
import types from "./constants";

export const initialState = {
  attributes: {},
  kitchenTypes: {},
  facilityTypes: {},
  paymentTypes: {},
  createAttributeIsPending: false,
  createFacilityTypeIsPending: false,
  createKitchenTypeIsPending: false,
  createPaymentTypeIsPending: false,
  notification: {},
};

/* eslint-disable default-case */
const attributesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_NOTIFICATION:
        draft.notification = action.payload;
        break;
      case types.SET_ATTRIBUTES:
        draft.attributes = action.payload;
        break;
      case types.ADD_ATTRIBUTE_REQUEST:
        draft.createAttributeIsPending = true;
        break;
      case types.ADD_ATTRIBUTE_SUCCESS:
      case types.ADD_ATTRIBUTE_ERROR:
        draft.createAttributeIsPending = false;
        break;
      case types.SET_SINGLE_ATTRIBUTE:
        draft.singleAttribute = action.payload;
        break;
      case types.FETCH_SINGLE_ATTRIBUTE_ERROR:
        draft.singleAttributeError = action.error;
        break;
      case types.CLEAR_SINGLE_ATTRIBUTE:
        draft.singleAttribute = null;
        draft.singleAttributeError = null;
        break;
      case types.SET_KITCHEN_TYPES:
        draft.kitchenTypes = action.payload;
        break;
      case types.ADD_KITCHEN_TYPE_REQUEST:
        draft.createKitchenTypeIsPending = true;
        break;
      case types.ADD_KITCHEN_TYPE_SUCCESS:
      case types.ADD_KITCHEN_TYPE_ERROR:
        draft.createKitchenTypeIsPending = false;
        break;
      case types.SET_SINGLE_KITCHEN_TYPE:
        draft.singleKitchenType = action.payload;
        break;
      case types.FETCH_SINGLE_KITCHEN_TYPE_ERROR:
        draft.singleKitchenTypeError = action.error;
        break;
      case types.CLEAR_SINGLE_KITCHEN_TYPE:
        draft.singleKitchenType = null;
        draft.singleKitchenTypeError = null;
        break;
      case types.SET_FACILITY_TYPES:
        draft.facilityTypes = action.payload;
        break;
      case types.ADD_FACILITY_TYPE_REQUEST:
        draft.createFacilityTypeIsPending = true;
        break;
      case types.ADD_FACILITY_TYPE_SUCCESS:
      case types.ADD_FACILITY_TYPE_ERROR:
        draft.createFacilityTypeIsPending = false;
        break;
      case types.SET_SINGLE_FACILITY_TYPE:
        draft.singleFacilityType = action.payload;
        break;
      case types.FETCH_SINGLE_FACILITY_TYPE_ERROR:
        draft.singleFacilityTypeError = action.error;
        break;
      case types.CLEAR_SINGLE_FACILITY_TYPE:
        draft.singleFacilityType = null;
        draft.singleFacilityTypeError = null;
        break;
      case types.SET_PAYMENT_TYPES:
        draft.paymentTypes = action.payload;
        break;
      case types.ADD_PAYMENT_TYPE_REQUEST:
        draft.createPaymentTypeIsPending = true;
        break;
      case types.ADD_PAYMENT_TYPE_SUCCESS:
      case types.ADD_PAYMENT_TYPE_ERROR:
        draft.createPaymentTypeIsPending = false;
        break;
      case types.SET_SINGLE_PAYMENT_TYPE:
        draft.singlePaymentType = action.payload;
        break;
      case types.FETCH_SINGLE_PAYMENT_TYPE_ERROR:
        draft.singlePaymentTypeError = action.error;
        break;
      case types.CLEAR_SINGLE_PAYMENT_TYPE:
        draft.singlePaymentType = null;
        draft.singlePaymentTypeError = null;
        break;
    }
  });

export default attributesReducer;
