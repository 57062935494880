import { takeLatest, call, put } from "redux-saga/effects";
//import { push } from "connected-react-router";
import request from "../../utils/request";
import parseApiErrorsToFormik from "../../utils/parseApiErrorsToFormik";
import { setItem } from "../../utils/localStorage";

import { loginSuccess, loginError } from "./actions";
import types from "./constants";
//import routes from "routes";
import { fetchAuthenticatedUser, setToken } from "../../containers/App/actions";
import { getContext } from "redux-saga/effects";

export function* login({ payload, meta: { setErrors } }) {
  try {
    const response = yield call(request, {
      url: "login",
      method: "post",
      data: payload,
    });

    const token = response.data.token;
    yield put(loginSuccess());
    yield call(setItem, "token", token);
    yield put(setToken(token));
    yield put(fetchAuthenticatedUser());
    const history = yield getContext("history");
    history.push("/categories");
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(loginError());
  }
}

export default function* cmsAuthSaga() {
  yield takeLatest(types.LOGIN_REQUEST, login);
}
