import { produce } from "immer";
import types from "./constants";

export const initialState = {
  users: {},
  singleUser: null,
  updateUserIsPending: false,
};

/* eslint-disable default-case */
const clientsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_USERS:
        draft.users = action.payload;
        break;
      case types.ADD_USER_REQUEST:
        draft.createUserIsPending = true;
        break;
      case types.ADD_USER_SUCCESS:
      case types.ADD_USER_ERROR:
        draft.createUserIsPending = false;
        break;
      case types.SET_SINGLE_USER:
        draft.singleUser = action.payload;
        break;
      case types.FETCH_SINGLE_USER_ERROR:
        draft.singleUserError = action.error;
        break;
      case types.CLEAR_SINGLE_USER:
        draft.singleUser = null;
        draft.singleUserError = null;
        break;
      case types.UPDATE_USER_REQUEST:
        draft.updateUserIsPending = true;
        break;
      case types.UPDATE_USER_SUCCESS:
      case types.UPDATE_USER_ERROR:
        draft.updateUserIsPending = false;
        break;
    }
  });

export default clientsReducer;
