import { EditorState, convertToRaw } from "draft-js";
import { Form, Formik } from "formik";
import { momentFormatDate } from "../../../utils/momentFormatDate";
import { useCallback, useState } from "react";
import FormikField from "../../../utils/FormikField";
import CategoriesDropdown from "./categories-dropdown";
import BodyEditor from "./BodyEditor";
import { FaTimes, FaTimesCircle } from "react-icons/fa";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import PositionsDropdown from "./positions-dropdown";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useInjectReducer } from "../../../utils/injectReducer";
import { useInjectSaga } from "../../../utils/injectSaga";
import { useDispatch } from "react-redux";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { createBanner } from "./redux/actions";

const key = "cms_banners";

export default function CreateBannerForm({ setShowCreateForm, close }) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const [desktopPhoto, setDesktopPhoto] = useState(null);
  const [previewDesktop, setPreviewDesktop] = useState(null);

  const [mobilePhoto, setMobilePhoto] = useState(null);
  const [previewMobile, setPreviewMobile] = useState(null);

  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  console.log("selectedCategories CREATE", selectedCategories);

  const [showPositions, setShowPositions] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState([null]);

  const [showCalendar, setShowCalendar] = useState(false);

  const uploadPhotoDesktop = (e) => {
    let file = e.target.files[0];

    if (file) {
      if (file.size > 2000000) {
        //setError("Maksimalni limit slike je 2MB");
      } else {
        setDesktopPhoto(file);
        setPreviewDesktop(URL.createObjectURL(file));
      }
    }
  };

  const uploadPhotoMobile = (e) => {
    let file = e.target.files[0];

    if (file) {
      if (file.size > 2000000) {
        //setError("Maksimalni limit slike je 2MB");
      } else {
        setMobilePhoto(file);
        setPreviewMobile(URL.createObjectURL(file));
      }
    }
  };

  const handleSubmit = (values, { setErrors }) => {
    const { name, description, link, expires_at } = values;

    let payload = {
      name,
      description: JSON.stringify(
        convertToRaw(description.getCurrentContent())
      ),
      //category_id: selectedCategory,
      banner_position_id: selectedPosition.id,
      desktop_image: desktopPhoto,
      mobile_image: mobilePhoto,
      link: link,
      expires_at: expires_at ? momentFormatDate(expires_at) : null,
    };

    if (selectedCategories) {
      payload.categories = selectedCategories.map((item) => item.id).join(",");
    }

    //console.log(payload, close);

    onSubmit(payload, close);
  };

  const onSubmit = useCallback(
    (...args) => dispatch(createBanner(...args)),
    [dispatch]
  );

  return (
    <div className="h-full w-full bg-white rounded-lg">
      <Formik
        initialValues={{
          name: "",
          description: EditorState.createEmpty(),
          link: "",
          banner_position_id: "",
          category_id: null,
          desktop_image: null,
          mobile_image: null,
          expires_at: null,
        }}
        onSubmit={(values, { setErrors }) => {
          handleSubmit(values, { setErrors });
        }}
      >
        {({ errors, touched, values, setFieldValue }) => {
          console.log("VALUES BANNER CREATE", values);
          return (
            <Form className="form_holder p-4 flex flex-col gap-4">
              <div className="grid grid-cols-2 gap-7  w-full">
                <div className="">
                  <input
                    id="desktopImage"
                    type="file"
                    name="image"
                    className="hidden"
                    onChange={uploadPhotoDesktop}
                  ></input>
                  {!previewDesktop && (
                    <div
                      className="flex items-center justify-center border border-dashed border-black border-opacity-20 w-full h-[250px]  rounded-lg cursor-pointer text-sm text-black text-opacity-40"
                      onClick={() =>
                        document.getElementById("desktopImage").click()
                      }
                    >
                      Unesi sliku banera
                    </div>
                  )}

                  {previewDesktop && (
                    <div
                      className="w-full h-[250px]"
                      style={{
                        backgroundImage: `url(${previewDesktop})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }}
                    >
                      <FaTimesCircle onClick={() => setPreviewDesktop(null)} />
                    </div>
                  )}
                </div>
                <div className="">
                  <input
                    id="mobileImage"
                    type="file"
                    name="image"
                    className="hidden"
                    onChange={uploadPhotoMobile}
                  ></input>
                  {!previewMobile && (
                    <div
                      className="flex items-center justify-center border border-dashed border-black border-opacity-20 w-full h-[250px]  rounded-lg cursor-pointer text-sm text-black text-opacity-40"
                      onClick={() =>
                        document.getElementById("mobileImage").click()
                      }
                    >
                      Unesi sliku banera za mobilni
                    </div>
                  )}

                  {previewMobile && (
                    <div
                      className="w-full h-[250px]"
                      style={{
                        backgroundImage: `url(${previewMobile})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }}
                    >
                      <FaTimesCircle onClick={() => setPreviewMobile(null)} />
                    </div>
                  )}
                </div>

                {/* <PhotoDropzone
                label="Izaberi sliku banera"
                errors={errors}
                setPhoto={(data) => setFieldValue("desktop_image", data)}
                name="desktop_image"
              />
              <PhotoDropzone
                label="Izaberi sliku banera za mobilni"
                errors={errors}
                setPhoto={(data) => setFieldValue("mobile_image", data)}
              /> */}

                <FormikField
                  type="text"
                  name="name"
                  label="Ime"
                  errors={errors}
                  touched={touched}
                  focus={true}
                  className="w-full"
                />
                <div className="relative">
                  <label>Izaberi kategoriju</label>
                  <div
                    className="flex w-full  items-center px-4 py-2 border border-black border-opacity-20 rounded-md text-sm text-black text-opacity-40 cursor-pointer"
                    onClick={() => setShowCategories(!showCategories)}
                  >
                    {selectedCategories.length > 0 ? (
                      <div className="flex items-center gap-2 flex-wrap">
                        {selectedCategories.map((category) => (
                          <div className="flex items-center  border p-1 rounded-md">
                            {category.name}
                            <FaTimes
                              className="z-50"
                              onClick={() =>
                                setSelectedCategories(
                                  selectedCategories.filter(
                                    (item) => item.id !== category.id
                                  )
                                )
                              }
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      "Izaberi kategoriju"
                    )}

                    {showCategories ? (
                      <MdKeyboardArrowUp className="ml-auto" />
                    ) : (
                      <MdKeyboardArrowDown className="ml-auto" />
                    )}
                  </div>

                  {showCategories && (
                    <CategoriesDropdown
                      selectedCategories={selectedCategories}
                      setSelectedCategories={(data) =>
                        setSelectedCategories(data)
                      }
                      setShowCategories={setShowCategories}
                    />
                  )}
                </div>
                <div className="relative">
                  <label>Izaberi poziciju</label>
                  <div
                    className="flex w-full  items-center px-4 py-2 border border-black border-opacity-20 rounded-md text-sm text-black text-opacity-40 cursor-pointer"
                    onClick={() => setShowPositions(!showPositions)}
                  >
                    {selectedPosition
                      ? selectedPosition.name
                      : "Izaberi poziciju"}

                    {showPositions ? (
                      <MdKeyboardArrowUp className="ml-auto" />
                    ) : (
                      <MdKeyboardArrowDown className="ml-auto" />
                    )}
                  </div>

                  {showPositions && (
                    <PositionsDropdown
                      setSelectedPosition={setSelectedPosition}
                      setShowPositions={setShowPositions}
                    />
                  )}
                </div>

                {/* <CategoriesDropdown /> */}
              </div>
              <BodyEditor
                value={values.description}
                setValue={(data) => setFieldValue("description", data)}
              />

              <div className="product_brand_field custom_m">
                <FormikField
                  type="text"
                  name="link"
                  label="Link"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="relative">
                <div onClick={() => setShowCalendar(!showCalendar)}>
                  <label htmlFor="">Ističe</label>
                  <div className="border rounded-md w-full min-h-[40px] flex items-center pl-2">
                    {values.expires_at}
                  </div>
                </div>
                {showCalendar && (
                  <div className="absolute">
                    <Calendar
                      onChange={(date) => {
                        const offset = date.getTimezoneOffset() * 60000;
                        const adjustedDate = new Date(date.getTime() - offset);
                        const formattedDate = adjustedDate
                          .toISOString()
                          .slice(0, 10);
                        setFieldValue("expires_at", formattedDate);
                        setShowCalendar(false);
                      }}
                      value={values.expires_at}
                    />
                  </div>
                )}
              </div>

              <div className="flex items-center gap-2 ml-auto">
                <div
                  className="btn-secondary cursor-pointer"
                  onClick={() => setShowCreateForm(false)}
                >
                  Nazad
                </div>
                <button className="btn-primary">Dodaj Baner</button>
              </div>

              {/* <Buttons label={messages.add_banner} /> */}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
