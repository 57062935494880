import React from "react";
import Sidebar from "./sidebar";
import Header from "./header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useInjectSaga } from "../utils/injectSaga";

import notificationSaga from "../notifications/saga";
import { useSelector } from "react-redux";

const notificationKey = "notification";

const Layout = ({ children }) => {
  useInjectSaga({ key: notificationKey, saga: notificationSaga });

  const currentNotification = useSelector(
    (state) => state.notifications.currentNotification
  );

  if (currentNotification) {
    toast[currentNotification.type](currentNotification.message, {
      toastId: currentNotification.key,
    });
  }
  return (
    <div className="layout">
      <ToastContainer />
      <Header />
      <Sidebar />

      <div className="content">{children}</div>
    </div>
  );
};

export default Layout;
