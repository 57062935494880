const KEY = "[CMS_HOME_PAGE_CATEGORIES]";

const types = {
  GET_CATEGORIES_REQUEST: `${KEY} GET_CATEGORIES_REQUEST`,
  GET_CATEGORIES_SUCCESS: `${KEY} GET_CATEGORIES_SUCCESS`,
  SET_CATEGORIES: `${KEY} SET_CATEGORIES`,
  ADD_CATEGORY: `${KEY} ADD_CATEGORY`,
  ADD_CATEGORY_ERROR: `${KEY} ADD_CATEGORY_ERROR`,
  CHANGE_IMAGE_REQUEST: `${KEY} CHANGE_IMAGE_REQUEST`,
  CHANGE_IMAGE_SUCCESS: `${KEY} CHANGE_IMAGE_SUCCESS`,
  CHANGE_ORDER: `${KEY} CHANGE_ORDER`,
  DELETE_CATEGORY: `${KEY} DELETE_CATEGORY`,
  SET_ACTIVE_DEVICE: `${KEY} SET_ACTIVE_DEVICE`,
};

export default types;
