import produce from "immer";
import types from "./constants";

export const initialState = {
  categories: null,
  error: null,
  activeDevice: "web",
};

/* eslint-disable default-case */
const cmsHomeScreenCategoriesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_CATEGORIES:
        draft.categories = action.payload;
        break;
      case types.ADD_CATEGORY_ERROR:
        draft.error = action.error;
        break;
      // case types.CHANGE_IMAGE_REQUEST:
      //   draft.changeImagePending = true;
      //   break;
      // case types.CHANGE_IMAGE_SUCCESS:
      //   draft.changeImagePending = false;
      //   break;
      case types.SET_ACTIVE_DEVICE:
        draft.activeDevice = action.device;
        break;
      case types.GET_CATEGORIES_REQUEST:
        draft.categoriesLoading = true;
        break;
      case types.GET_CATEGORIES_SUCCESS:
        draft.categoriesLoading = false;
        break;
    }
  });

export default cmsHomeScreenCategoriesReducer;
