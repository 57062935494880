import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectSections = (state) => state.home_screen_sections || initialState;

const makeSelectSections = () =>
  createSelector(selectSections, (substate) => substate.sections);

const makeSelectSectionsLoading = () =>
  createSelector(selectSections, (substate) => substate.sectionsLoading);

const makeSelectSection = () =>
  createSelector(selectSections, (substate) => substate.section);

const makeSelectSectionLoading = () =>
  createSelector(selectSections, (substate) => substate.sectionLoading);

const makeSelectSectionError = () =>
  createSelector(selectSections, (substate) => substate.sectionError);

const makeSelectCreateClientError = () =>
  createSelector(selectSections, (substate) => substate.createClientError);

const makeSelectCreateCategoryError = () =>
  createSelector(selectSections, (substate) => substate.createCategoryError);

const makeSelectActiveDevice = () =>
  createSelector(selectSections, (substate) => substate.activeDevice);

export {
  makeSelectSections,
  makeSelectSectionsLoading,
  makeSelectSection,
  makeSelectSectionLoading,
  makeSelectSectionError,
  makeSelectCreateClientError,
  makeSelectCreateCategoryError,
  makeSelectActiveDevice,
};
