import { Form, Formik } from "formik";
import { LuNetwork } from "react-icons/lu";
import RadioInput from "./radioInput";
import { useCallback, useEffect, useState } from "react";
import RadioInputCatering from "./radioInputCatering";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectAddClientIsSuccess,
  makeSelectCateringInfo,
  makeSelectCreateClientIsPending,
  makeSelectDeliveryInfo,
  makeSelectWorkingTimes,
} from "./redux/selectors";
import RadioInputDelivery from "./radioInputDelivery";
import { clientSchema } from "./validations";
import WorkingTimesModal from "./workingTimesModal";
import UploadMainPhoto from "./UploadMainPhoto";
import UploadPhotos from "./UploadPhotos";
import {
  addClient,
  clearAddClientSuccess,
  clearCateringInfo,
  clearDeliveryInfo,
  clearSingleClient,
  clearWorkingTimes,
  setDeliveryInfo,
} from "./redux/actions";
import { useInjectReducer } from "../../utils/injectReducer";
import { useInjectSaga } from "../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { IoIosCheckmark } from "react-icons/io";
import UploadLogo from "./UploadLogo";
import UploadQRCode from "./UploadQRCode";
import { googleMAPSkey } from "../../env";
import axios from "axios";

const key = "clients";

export default function AddClient({
  showCreateForm,
  attributes,
  kitchenTypes,
  facilityTypes,
  paymentTypes,
  onAddClient,
}) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);

  const isCreatePending = useSelector(makeSelectCreateClientIsPending());
  const isCreateSuccess = useSelector(makeSelectAddClientIsSuccess());

  const [selectedKitchenTypes, setSelectedKitchenTypes] = useState([]);
  const [showKitchenTypes, setShowKitchenTypes] = useState(false);

  const [selectedFacilityTypes, setSelectedFacilityTypes] = useState([]);
  const [showFacilityTypes, setShowFacilityTypes] = useState(false);

  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState([]);
  const [showPaymentTypes, setShowPaymentTypes] = useState(false);

  const [showWorkingTimesModal, setShowWorkingTimesModal] = useState(false);

  const [selectedAttributes, setSelectedAttributes] = useState({});

  const [main_photo, setMain_photo] = useState(null);
  const [logo, setLogo] = useState(null);
  const [QRCode, setQRCode] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [pricelist, setPricelist] = useState(null);

  console.log("pricelist:", pricelist);
  console.log("main_photo:", main_photo);

  const cateringInfo = useSelector(makeSelectCateringInfo());
  const deliveryInfo = useSelector(makeSelectDeliveryInfo());
  const workingTimes = useSelector(makeSelectWorkingTimes());

  const [couriers, setCouriers] = useState([]);

  const handleAddDeliveryData = useCallback(
    (...args) => dispatch(setDeliveryInfo(...args)),
    [dispatch]
  );

  useEffect(() => {
    if (deliveryInfo?.couriers?.length) {
      setCouriers(deliveryInfo.couriers);
    }
  }, [deliveryInfo]);

  console.log(couriers);

  useEffect(() => {
    dispatch(clearAddClientSuccess());
  }, []);

  useEffect(() => {
    dispatch(clearWorkingTimes());
    dispatch(clearCateringInfo());
    dispatch(clearDeliveryInfo());
    dispatch(clearSingleClient());
  }, [dispatch]);

  useEffect(() => {
    if (isCreateSuccess) {
      showCreateForm(false);
      setSubmitting(false);
    }
  }, [showCreateForm, isCreateSuccess]);

  // useEffect(() => {
  //   if (submitting && !isCreatePending && isCreateSuccess) {
  //     showCreateForm(false);
  //     setSubmitting(false);
  //   }
  // }, [isCreatePending, submitting, showCreateForm, isCreateSuccess]);

  const toggleKitchenTypeSelection = (id) => {
    setSelectedKitchenTypes((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id); // Remove the id if it's already selected
      } else {
        return [...prevSelected, id]; // Add the id if it's not selected
      }
    });
  };

  const toggleFacilityTypeSelection = (id) => {
    setSelectedFacilityTypes((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id); // Remove the id if it's already selected
      } else {
        return [...prevSelected, id]; // Add the id if it's not selected
      }
    });
  };

  const togglePaymentTypeSelection = (id) => {
    setSelectedPaymentTypes((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id); // Remove the id if it's already selected
      } else {
        return [...prevSelected, id]; // Add the id if it's not selected
      }
    });
  };

  const attributesArray = Object.entries(selectedAttributes)
    .filter(([_, { value }]) => value !== "")
    .map(([attributeId, { value, note }]) => ({
      attribute_id: attributeId,
      value: value,
      note: note,
    }));

  const handleAddClient = useCallback(
    (...args) => dispatch(addClient(...args)),
    [dispatch]
  );

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      //setFieldValue('image', file);
      setPricelist(file);
    }
  };

  // const onSubmit = (values) => {
  //   onAddClient(values);
  // };

  const getCoordinates = async (address) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${googleMAPSkey}`
      );
      const { results } = response.data;
      if (results && results.length > 0) {
        const { lat, lng } = results[0].geometry.location;
        return { lat, lng };
      }
    } catch (error) {
      console.error("Error fetching geocoding data:", error);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          object_name: "",
          company_name: "",
          object_address: "",
          company_address: "",
          city: "",
          municipality: "",
          contact_person: "",
          phone: "",
          email: "",
          contact_email: "",
          object_description: "",
          photos: [],
          main_photo: null,
          catering: null,
          delivery: null,
          couriers: [],
          working_times: null,
        }}
        onSubmit={async (values, { setErrors, resetForm }) => {
          let crd = null;

          await getCoordinates(values.object_address).then((coordinates) => {
            console.log("COORDINATES", coordinates);
            crd = coordinates;
          });

          const payload = {
            ...values,
            attributes: attributesArray,
            kitchenTypeIds: selectedKitchenTypes,
            facilityTypeIds: selectedFacilityTypes,
            paymentTypeIds: selectedPaymentTypes,
            working_times: workingTimes,
            coordinates: crd,
            // logo: logo ? logo : null,
            // qr_code: QRCode ? QRCode : null,
          };

          if (values.catering === "yes") {
            payload.catering = 1;
            payload.catering_phone = cateringInfo.catering_phone;
            payload.catering_info = cateringInfo.catering_note;
            payload.catering_days = cateringInfo.catering_days;
          } else {
            payload.catering = 0;
          }
          if (values.delivery === "yes") {
            payload.delivery = 1;
            payload.delivery_phone = deliveryInfo.delivery_phone;
            payload.delivery_info = deliveryInfo.delivery_note;
            payload.delivery_days = deliveryInfo.delivery_days;
            payload.couriers = couriers;
          } else {
            payload.delivery = 0;
          }

          console.log("ADD CLIENT PAYLOAD:", payload);

          if (workingTimes.length) {
            handleAddClient(
              payload,
              main_photo,
              photos,
              logo,
              QRCode,
              pricelist,
              setErrors
            );
            setSubmitting(true);
          }
        }}
        validationSchema={clientSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          const setAttributeValue = (attributeId, value, note) => {
            setSelectedAttributes((prev) => ({
              ...prev,
              [attributeId]: { value, note },
            }));
          };

          // const handleFileChange = (event) => {
          //   const file = event.currentTarget.files[0];
          //   if (file) {
          //     setFieldValue("image", file);
          //     setFileName(file.name);
          //   }
          // };

          //console.log("CLIENT VALUES", values);
          return (
            <Form onSubmit={handleSubmit}>
              <div className="flex justify-between bg-white p-5 mb-4 rounded-md">
                <div className="left flex gap-1">
                  <LuNetwork className="rotate-90" size={25} />
                  <div className=" flex flex-col">
                    <span className="text-2xl">Dodavanje novog klijenta</span>
                    <span className="text-sm ">
                      {new Date().toLocaleString("sr-Latn-RS", {
                        month: "long",
                        year: "numeric",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false, // Use 24-hour format. Remove this line if you want AM/PM format.
                      })}
                    </span>
                  </div>
                </div>

                <div className="right flex gap-1">
                  <button className="hidden" type="submit"></button>
                  <button
                    className="btn-cancel"
                    onClick={() => showCreateForm(false)}
                  >
                    Poništi
                  </button>
                  <button type="submit" className="btn-primary">
                    {isCreatePending ? "Dodavanje..." : "Dodaj"}
                  </button>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-6">
                <div className="left pb-5 bg-white rounded-md overflow-hidden">
                  <p className="bg-white p-4 border-b border-black border-opacity-20">
                    Osnovne informacije
                  </p>

                  <div className="client_input">
                    <label className="text-sm">
                      1. <span className="text-red-500 font-bold">*</span> Naziv
                      objekta:{" "}
                    </label>
                    <input
                      type="text"
                      name="object_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.object_name}
                      placeholder="Unesi naziv objekta"
                    />
                    <p className="error_message">
                      {errors.object_name &&
                        touched.object_name &&
                        errors.object_name}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">2. Naziv firme/radnje:</label>
                    <input
                      type="text"
                      name="company_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company_name}
                      placeholder="Unesi naziv firme/radnje"
                    />
                    <p className="error_message">
                      {errors.company_name &&
                        touched.company_name &&
                        errors.company_name}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">
                      3. <span className="text-red-500 font-bold">*</span>{" "}
                      Adresa objekta:
                    </label>
                    <input
                      type="text"
                      name="object_address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.object_address}
                      placeholder="Unesi adresu objekta"
                    />
                    <p className="error_message">
                      {errors.object_address &&
                        touched.object_address &&
                        errors.object_address}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">4. Adresa firme/radnje:</label>
                    <input
                      type="text"
                      name="company_address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company_address}
                      placeholder="Unesi adresu firme/radnje"
                    />
                    <p className="error_message">
                      {errors.company_address &&
                        touched.company_address &&
                        errors.company_address}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">
                      5. <span className="text-red-500 font-bold">*</span> Grad:
                    </label>
                    <input
                      type="text"
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      placeholder="Unesi grad"
                    />
                    <p className="error_message">
                      {errors.city && touched.city && errors.city}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">6. Opština:</label>
                    <input
                      type="text"
                      name="municipality"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.municipality}
                      placeholder="Unesi opštinu"
                    />
                    <p className="error_message">
                      {errors.municipality &&
                        touched.municipality &&
                        errors.municipality}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">7. PIB:</label>
                    <input
                      type="text"
                      name="company_pib"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company_pib}
                      placeholder="Unesi PIB"
                    />
                    <p className="error_message">
                      {errors.company_pib &&
                        touched.company_pib &&
                        errors.company_pib}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">
                      8. <span className="text-red-500 font-bold">*</span>{" "}
                      Kontakt osoba:
                    </label>
                    <input
                      type="text"
                      name="contact_person"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contact_person}
                      placeholder="Unesi kontakt osobu"
                    />
                    <p className="error_message">
                      {errors.contact_person &&
                        touched.contact_person &&
                        errors.contact_person}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">9. Vlasnik:</label>
                    <input
                      type="text"
                      name="owner"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.owner}
                      placeholder="Unesi vlasnika"
                    />
                    <p className="error_message">
                      {errors.owner && touched.owner && errors.owner}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">10. Fiksni telefon:</label>
                    <input
                      type="text"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      placeholder="Unesi fiksni telefon"
                    />
                    <p className="error_message">
                      {errors.phone && touched.phone && errors.phone}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">11. Mobilni telefon 1:</label>
                    <input
                      type="text"
                      name="mobile_phone_1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobile_phone_1}
                      placeholder="Unesi mobilni telefon 1"
                    />
                    <p className="error_message">
                      {errors.mobile_phone_1 &&
                        touched.mobile_phone_1 &&
                        errors.mobile_phone_1}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">12. Mobilni telefon 2:</label>
                    <input
                      type="text"
                      name="mobile_phone_2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobile_phone_2}
                      placeholder="Unesi mobilni telefon 2"
                    />
                    <p className="error_message">
                      {errors.mobile_phone_2 &&
                        touched.mobile_phone_2 &&
                        errors.mobile_phone_2}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">
                      13. <span className="text-red-500 font-bold">*</span>{" "}
                      E-pošta:
                    </label>
                    <input
                      type="text"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder="Unesi e-poštu"
                    />
                    <p className="error_message">
                      {errors.email && touched.email && errors.email}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">
                      14. <span className="text-red-500 font-bold">*</span>{" "}
                      Kontakt e-pošta:
                    </label>
                    <input
                      type="text"
                      name="contact_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contact_email}
                      placeholder="Unesi kontakt e-poštu"
                    />
                    <p className="error_message">
                      {errors.contact_email &&
                        touched.contact_email &&
                        errors.contact_email}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">15. WWW:</label>
                    <input
                      type="text"
                      name="website"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.website}
                      placeholder="Unesi WWW"
                    />
                    <p className="error_message">
                      {errors.website && touched.website && errors.website}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">16. Facebook:</label>
                    <input
                      type="text"
                      name="facebook"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.facebook}
                      placeholder="Unesi Facebook"
                    />
                    <p className="error_message">
                      {errors.facebook && touched.facebook && errors.facebook}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">17. Instagram:</label>
                    <input
                      type="text"
                      name="instagram"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.instagram}
                      placeholder="Unesi Instagram"
                    />
                    <p className="error_message">
                      {errors.instagram &&
                        touched.instagram &&
                        errors.instagram}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">18. Youtube:</label>
                    <input
                      type="text"
                      name="youtube"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.youtube}
                      placeholder="Unesi Youtube"
                    />
                    <p className="error_message">
                      {errors.youtube && touched.youtube && errors.youtube}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">19. Tik-tok:</label>
                    <input
                      type="text"
                      name="tik_tok"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tik_tok}
                      placeholder="Unesi Tik-tok"
                    />
                    <p className="error_message">
                      {errors.tik_tok && touched.tik_tok && errors.tik_tok}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm">
                      20. Ostale društvene mreže:
                    </label>
                    <input
                      type="text"
                      name="other_social_network"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.other_social_network}
                      placeholder="Unesi ostale društvene mreže"
                    />
                    <p className="error_message">
                      {errors.other_social_network &&
                        touched.other_social_network &&
                        errors.other_social_network}
                    </p>
                  </div>
                  <div className="client_input relative ">
                    <label className="text-sm">
                      21. Tip ugostiteljskog objekta:
                    </label>
                    <div
                      className="relative text-sm text-black text-opacity-40 input"
                      onClick={() => setShowFacilityTypes(true)}
                    >
                      {selectedFacilityTypes.length
                        ? `Izabrano ${selectedFacilityTypes.length}`
                        : "Izaberi/dodaj tip ugostiteljskog objekta"}
                    </div>
                    {showFacilityTypes && facilityTypes && (
                      <div className="bg-white absolute top-12 left-1/2 flex flex-col gap-1 text-sm w-1/2  z-10 p-5 border border-black rounded-md">
                        {facilityTypes.map((facilityType) => (
                          <div
                            className={`cursor-pointer pl-2  ${
                              selectedFacilityTypes.includes(facilityType.id)
                                ? "bg-blue-400 rounded-md text-white"
                                : "text-gray-400"
                            }`}
                            key={facilityType.id}
                            onClick={() =>
                              toggleFacilityTypeSelection(facilityType.id)
                            }
                          >
                            {facilityType.name}
                          </div>
                        ))}
                        <div
                          className="absolute top-2 right-2 cursor-pointer text-lg"
                          onClick={() => setShowFacilityTypes(false)}
                        >
                          <IoIosCheckmark size={35} />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="client_input relative ">
                    <label className="text-sm">22. Vrsta kuhinje:</label>
                    <div
                      className="relative text-sm text-black text-opacity-40 input"
                      onClick={() => setShowKitchenTypes(true)}
                    >
                      {selectedKitchenTypes.length
                        ? `Izabrano ${selectedKitchenTypes.length}`
                        : "Izaberi/dodaj vrstu kuhinje"}
                    </div>
                    {showKitchenTypes && kitchenTypes && (
                      <div className="bg-white absolute top-12 left-1/2 flex flex-col gap-1 text-sm w-1/2  z-10 p-5 border border-black rounded-md">
                        {kitchenTypes.map((kitchenType) => (
                          <div
                            className={`cursor-pointer pl-2  ${
                              selectedKitchenTypes.includes(kitchenType.id)
                                ? "bg-blue-400 rounded-md text-white"
                                : "text-gray-400"
                            }`}
                            key={kitchenType.id}
                            onClick={() =>
                              toggleKitchenTypeSelection(kitchenType.id)
                            }
                          >
                            {kitchenType.name}
                          </div>
                        ))}
                        <div
                          className="absolute top-2 right-2 cursor-pointer text-lg"
                          onClick={() => setShowKitchenTypes(false)}
                        >
                          <IoIosCheckmark size={35} />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="client_input">
                    <label className="text-sm">
                      23. <span className="text-red-500 font-bold">*</span>{" "}
                      Ketering:
                    </label>
                    <div className="flex gap-2">
                      <RadioInputCatering
                        name="catering"
                        value="yes"
                        checkedValue={values.catering}
                        onChange={setFieldValue}
                        labelText="Da"
                        editClient={false}
                      />

                      <RadioInputCatering
                        name="catering"
                        value="no"
                        checkedValue={values.catering}
                        onChange={setFieldValue}
                        labelText="Ne"
                        editClient={false}
                      />
                    </div>
                    <p className="error_message">
                      {errors.catering && touched.catering && errors.catering}
                    </p>
                  </div>
                  <div className="client_input">
                    <label className="text-sm mb-2">
                      24. <span className="text-red-500 font-bold">*</span>{" "}
                      Dostava:
                    </label>
                    <div className="flex gap-2 mb-2">
                      <RadioInputDelivery
                        name="delivery"
                        value="yes"
                        checkedValue={values.delivery}
                        onChange={setFieldValue}
                        labelText="Da"
                      />

                      <RadioInputDelivery
                        name="delivery"
                        value="no"
                        checkedValue={values.delivery}
                        onChange={setFieldValue}
                        labelText="Ne"
                      />
                    </div>

                    <div className="flex flex-col">
                      {deliveryInfo?.couriers?.length && (
                        <>
                          {deliveryInfo.couriers.map((courier, index) => {
                            return (
                              <div className="flex ">
                                <p>
                                  {index + 1}. {courier.name}
                                </p>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                    <div className="flex flex-col w-full">
                      {deliveryInfo?.couriers?.length && (
                        <>
                          {deliveryInfo.couriers.map((courier, index) => {
                            return (
                              <div className="flex w-full">
                                <input
                                  type="text"
                                  className="w-full"
                                  placeholder="Link"
                                  value={courier.link}
                                  onChange={(e) => {
                                    const updatedCouriers = [
                                      ...deliveryInfo.couriers,
                                    ];
                                    updatedCouriers[index] = {
                                      ...updatedCouriers[index],
                                      link: e.target.value,
                                    };
                                    handleAddDeliveryData({
                                      ...deliveryInfo,
                                      couriers: updatedCouriers,
                                    });
                                  }}
                                />
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                    <p className="error_message">
                      {errors.delivery && touched.delivery && errors.delivery}
                    </p>
                  </div>
                  <div className="client_input relative ">
                    <label className="text-sm">
                      25. <span className="text-red-500 font-bold">*</span>{" "}
                      Radno vreme:
                    </label>
                    <div
                      className="relative text-sm text-black text-opacity-40 input"
                      onClick={() => setShowWorkingTimesModal(true)}
                    >
                      {workingTimes.length
                        ? "Radno vreme uneseno"
                        : "Unesi radno vreme"}
                    </div>
                    {showWorkingTimesModal && (
                      <WorkingTimesModal
                        setShowWorkingTimesModal={setShowWorkingTimesModal}
                      />
                    )}
                    <p className="error_message">
                      {workingTimes.length ? "" : "Polje je obavezno"}
                    </p>
                  </div>
                  <div className="client_input relative ">
                    <label className="text-sm">26. Način plaćanja:</label>
                    <div
                      className="relative text-sm text-black text-opacity-40 input"
                      onClick={() => setShowPaymentTypes(true)}
                    >
                      {selectedPaymentTypes.length
                        ? `Izabrano ${selectedPaymentTypes.length}`
                        : "Izaberi način plaćanja"}
                    </div>
                    {showPaymentTypes && paymentTypes && (
                      <div className="bg-white absolute top-12 left-1/2 flex flex-col gap-1 text-sm w-1/2  z-10 p-5 border border-black rounded-md">
                        {paymentTypes.map((paymentType) => (
                          <div
                            className={`cursor-pointer pl-2  ${
                              selectedPaymentTypes.includes(paymentType.id)
                                ? "bg-blue-400 rounded-md text-white"
                                : "text-gray-400"
                            }`}
                            key={paymentType.id}
                            onClick={() =>
                              togglePaymentTypeSelection(paymentType.id)
                            }
                          >
                            {paymentType.name}
                          </div>
                        ))}
                        <div
                          className="absolute top-2 right-2 cursor-pointer"
                          onClick={() => setShowPaymentTypes(false)}
                        >
                          <IoIosCheckmark size={35} />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="p-4 upload_photos_row flex flex-col gap-2">
                    <UploadMainPhoto setPhoto={(arg) => setMain_photo(arg)} />
                    <UploadPhotos
                      photos={values.photos}
                      setPhotos={(arg) => setPhotos(arg)}
                    />
                    <UploadLogo setPhoto={(arg) => setLogo(arg)} />
                    <UploadQRCode setPhoto={(arg) => setQRCode(arg)} />
                    <div className="w-full flex items-center gap-2">
                      <input
                        id="pricelist"
                        type="file"
                        name="pricelist"
                        className=""
                        onChange={handleFileChange}
                      ></input>
                      <label htmlFor="" className="">
                        Cenovnik
                      </label>
                    </div>
                  </div>
                </div>
                <div className="right pb-12 bg-white rounded-md overflow-hidden">
                  <p className="bg-white p-4 border-b border-black border-opacity-20">
                    Ostale informacije
                  </p>
                  {attributes.data &&
                    attributes.data
                      .filter((attr) => attr.values.length > 0)
                      .filter(
                        (attribute) =>
                          // attribute.values.length === 0 ||
                          // (attribute.values.length > 0 &&
                          attribute.values[0].value !== "YES" &&
                          attribute.values[0].value !== "DA" &&
                          attribute.values[0].value !== "ДА"
                      )
                      .map((attribute) => (
                        <div className="client_input">
                          <label className="text-sm">
                            {attribute.serial_number}. {attribute.name}:
                          </label>
                          <input
                            type="text"
                            name={attribute.id}
                            onChange={(e) =>
                              setAttributeValue(
                                attribute.id,
                                e.target.value,
                                ""
                              )
                            }
                            onBlur={handleBlur}
                            value={selectedAttributes[attribute.id]?.value}
                            placeholder={attribute.name}
                          />
                          {selectedAttributes[attribute.id]?.value.length >
                            0 && (
                            <div className="w-full">
                              <label className="text-sm">Note:</label>
                              <input
                                type="text"
                                className="h-1/2"
                                onChange={(e) =>
                                  setAttributeValue(
                                    attribute.id,
                                    selectedAttributes[attribute.id]?.value,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      ))}

                  {attributes.data &&
                    attributes.data
                      .filter((attr) => attr.values.length > 0)
                      .filter(
                        (attribute) =>
                          // attribute.values.length > 1 ||
                          // (attribute.values.length > 1 &&
                          attribute.values[0].value === "YES" ||
                          attribute.values[0].value === "DA" ||
                          attribute.values[0].value === "ДА"
                      )
                      .map((attribute) => (
                        <div className="client_input">
                          <label className="text-sm">
                            {attribute.serial_number}. {attribute.name}:
                          </label>
                          <div className="flex gap-2">
                            <RadioInput
                              attribute={attribute}
                              name={attribute.id}
                              value={attribute.values[0].value}
                              checkedValue={
                                selectedAttributes[attribute.id]?.value
                              }
                              onChange={setAttributeValue}
                              labelText={attribute.values[0].value}
                            />

                            <RadioInput
                              attribute={attribute}
                              name={attribute.id}
                              value={attribute.values[1].value}
                              checkedValue={
                                selectedAttributes[attribute.id]?.value
                              }
                              onChange={setAttributeValue}
                              labelText={attribute.values[1].value}
                            />
                          </div>
                          {selectedAttributes[attribute.id]?.value ===
                          attribute.values[0].value ? (
                            <div className="w-full">
                              <label className="text-sm">Note:</label>
                              <input
                                type="text"
                                onChange={(e) =>
                                  setAttributeValue(
                                    attribute.id,
                                    selectedAttributes[attribute.id]?.value,
                                    e.target.value
                                  )
                                }
                                className="h-1/2"
                              />
                            </div>
                          ) : null}
                        </div>
                      ))}
                  <div className=" flex w-full bg-neutral-100 my-2 py-4 p-2 px-5">
                    <label className="text-sm flex-grow">
                      49. Street View Link:
                    </label>
                    <input
                      type="text"
                      name="street_view"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.street_view}
                      placeholder="Unesi Street View link"
                      className="w-2/3 p-2 border rounded-md text-sm"
                    />
                    <p className="error_message">
                      {errors.street_view &&
                        touched.street_view &&
                        errors.street_view}
                    </p>
                  </div>
                  <div className="w-full px-4 mt-4 border-t pt-4 flex flex-col gap-2">
                    <label className="text-sm">50. Tekst o objektu</label>
                    <textarea
                      value={values.object_description}
                      onChange={handleChange}
                      name="object_description"
                      className="min-h-[100px] border rounded-md p-2 text-sm"
                    ></textarea>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
