import { produce } from "immer";
import types from "./constants";

export const initialState = {
  categories: {},
  // params: { main: 1 },
  clients: [],
  singleCategory: null,
  clientCategoriesIds: [],
  clientConnect: null,
  // singleCategoryError: null,
  // createCategoryIsPending: false,
  // updateCategoryIsPending: false,
  // attributeValues: [],
};

/* eslint-disable default-case */
const categoriesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_CATEGORIES:
        draft.categories = action.payload;
        break;
      case types.SET_CLIENTS:
        draft.clients = action.payload;
        break;
      case types.SET_SINGLE_CATEGORY:
        draft.singleCategory = action.payload;
        break;
      case types.FETCH_SINGLE_CATEGORY_ERROR:
        draft.singleCategoryError = action.error;
        break;
      case types.CLEAR_SINGLE_CATEGORY:
        draft.singleCategory = null;
        draft.singleCategoryError = null;
        break;
      case types.SET_CLIENT_CATEGORIES_IDS:
        // Iterate through the new IDs in action.payload
        for (const id of action.payload) {
          // Check if the ID is already in the array
          if (draft.clientCategoriesIds.includes(id)) {
            // If the ID is found, remove it from the array
            draft.clientCategoriesIds = draft.clientCategoriesIds.filter(
              (existingId) => existingId !== id
            );
          } else {
            // If the ID is not found, add it to the array
            draft.clientCategoriesIds.push(id);
          }
        }
        break;
      // case types.SET_CLIENT_CATEGORIES_IDS:
      //   // Check if the ID is already in the array
      //   if (draft.clientCategoriesIds.includes(action.payload)) {
      //     // If the ID is found, filter it out of the array
      //     draft.clientCategoriesIds = draft.clientCategoriesIds.filter(
      //       (id) => id !== action.payload
      //     );
      //   } else {
      //     // If the ID is not found, add it to the array
      //     draft.clientCategoriesIds = [
      //       ...draft.clientCategoriesIds,
      //       action.payload,
      //     ];
      //   }
      //   break;

      case types.CLEAR_CLIENT_CATEGORIES_IDS:
        draft.clientCategoriesIds = [];
        break;
      case types.SET_CONNECT_CLIENT:
        draft.clientConnect = action.payload;
        break;
      case types.CLEAR_CONNECT_CLIENT:
        draft.clientConnect = null;
        break;
      case types.CONNECT_CLIENT_CATEGORIES_REQUEST:
        draft.connectClientCategoriesIsPending = true;
        break;
      case types.CONNECT_CLIENT_CATEGORIES_SUCCESS:
        draft.connectClientCategoriesIsPending = false;
        break;
      case types.CONNECT_CLIENT_CATEGORIES_ERROR:
        draft.connectClientCategoriesIsPending = false;
        break;
      case types.DISCONNECT_CLIENT_CATEGORIES_REQUEST:
        draft.disconnectClientCategoriesIsPending = true;
        break;
      case types.DISCONNECT_CLIENT_CATEGORIES_SUCCESS:
        draft.disconnectClientCategoriesIsPending = false;
        break;
      case types.ADD_CATEGORY_REQUEST:
        draft.createCategoryIsPending = true;
        break;
      case types.ADD_CATEGORY_SUCCESS:
      case types.ADD_CATEGORY_ERROR:
        draft.createCategoryIsPending = false;
        break;
      case types.UPDATE_CATEGORY_REQUEST:
        draft.updateCategoryIsPending = true;
        break;
      case types.UPDATE_CATEGORY_SUCCESS:
      case types.UPDATE_CATEGORY_ERROR:
        draft.updateCategoryIsPending = false;
        break;
    }
  });

export default categoriesReducer;
