import { useCallback, useEffect, useRef, useState } from "react";
import { TbSection } from "react-icons/tb";
import AddSection from "./addSection";
import { useInjectReducer } from "../../../utils/injectReducer";
import { useInjectSaga } from "../../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSectionOrder,
  createSection,
  deleteSection,
  getSections,
  setSections,
  updateSection,
} from "./redux/actions";
import {
  makeSelectSections,
  makeSelectSectionsLoading,
} from "./redux/selectors";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";
import Sortable from "sortablejs";
import EditSection from "./editSection/editSection";

const key = "home_screen_sections";

export default function SectionsPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const [showAddSection, setShowAddSection] = useState(false);
  const [showEditSection, setShowEditSection] = useState(false);
  const [sectionId, setSectionId] = useState(null);

  const sections = useSelector(makeSelectSections());
  const isLoading = useSelector(makeSelectSectionsLoading());

  const sortableContainerRef = useRef(null);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (sortableContainerRef.current) {
      const sortable = new Sortable(sortableContainerRef.current, {
        animation: 150,
        onEnd: (/**Event*/ evt) => {
          const { newIndex, oldIndex } = evt;
          const newData = [...data];
          newData.splice(newIndex, 0, newData.splice(oldIndex, 1)[0]);
          setData(newData);

          handleChangeSectionOrder(newData);
        },
      });

      return () => {
        sortable.destroy();
      };
    }
  }, [data]);

  useEffect(() => {
    dispatch(getSections());
  }, [dispatch]);

  useEffect(() => {
    if (sections && !isLoading) {
      setData(sections);
    }
  }, [dispatch, isLoading]);

  const onSubmit = useCallback(
    (...args) => dispatch(createSection(...args)),
    [dispatch]
  );

  const onSubmitUpdate = useCallback(
    (...args) => dispatch(updateSection(...args)),
    [dispatch]
  );

  const handleDelete = useCallback(
    (...args) => dispatch(deleteSection(...args)),
    [dispatch]
  );

  const handleChangeSectionOrder = (newOrder) => {
    const sectionsPayload = newOrder.map((item, index) => ({
      id: item.id,
      order: index,
    }));

    dispatch(changeSectionOrder({ sections: sectionsPayload }));
  };

  if (isLoading) {
    return <div>Učitavanje sekcija</div>;
  }

  return (
    <div className="flex-col">
      <div className="flex justify-between bg-white p-5 mb-4 rounded-md">
        <div className="left flex gap-1">
          <TbSection size={25} />
          <div className=" flex flex-col">
            <span className="text-2xl">Sekcije</span>
            <span className="text-opacity-30 text-sm">
              {sections && sections.length} sekcija ukupno
            </span>
          </div>
        </div>
        <div className="right">
          <button
            className="btn-primary"
            onClick={() => setShowAddSection(true)}
          >
            Dodaj novu sekciju
          </button>
        </div>
      </div>
      <div className="bg-white">
        <div className="bg-white flex justify-between p-5  border-b border-black border-opacity-20">
          <div className="grid grid-cols-2 w-[1200px]">
            <span>Ime</span>
            <span>Redosled</span>
          </div>
          <span>Akcija </span>
        </div>

        <div className="sections-list" ref={sortableContainerRef}>
          {/* {_.sortBy(data, ["order"]).map((section, index) => { */}
          {data.map((section, index) => {
            return (
              <div
                key={section.id}
                data-id={section.id}
                className="sortable-item client_row px-5 flex justify-between items-center cursor-pointer"
              >
                <div className="grid grid-cols-2 w-[1200px] py-4 text-sm">
                  <span>{section.name}</span>
                  <span>{section.order + 1}</span>
                </div>
                <div className="flex gap-3">
                  <FiEdit
                    size={20}
                    color="gray"
                    className="cursor-pointer"
                    onClick={() => {
                      setSectionId(section.id);
                      setShowEditSection(true);
                    }}
                  />
                  <RiDeleteBin6Line
                    size={20}
                    color="gray"
                    onClick={() => handleDelete(section.id)}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {showAddSection && (
        <AddSection setShowAddSection={setShowAddSection} onSubmit={onSubmit} />
      )}
      {showEditSection && (
        <EditSection
          sectionId={sectionId}
          setShowEditSection={setShowEditSection}
          onSubmit={onSubmitUpdate}
        />
      )}
    </div>
  );
}
