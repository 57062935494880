const KEY = "[CMS_DELIVERY_PEOPLE]";

const types = {
  FETCH_DELIVERY_PEOPLE: `${KEY} FETCH_DELIVERY_PEOPLE`,
  SET_DELIVERY_PEOPLE: `${KEY} SET_DELIVERY_PEOPLE`,

  ADD_DELIVERY_PERSON_REQUEST: `${KEY} ADD_DELIVERY_PERSON_REQUEST`,
  ADD_DELIVERY_PERSON_SUCCESS: `${KEY} ADD_DELIVERY_PERSON_SUCCESS`,
  ADD_DELIVERY_PERSON_ERROR: `${KEY} ADD_DELIVERY_PERSON_ERROR`,

  UPDATE_DELIVERY_PERSON_REQUEST: `${KEY} UPDATE_DELIVERY_PERSON_REQUEST`,
  UPDATE_DELIVERY_PERSON_SUCCESS: `${KEY} UPDATE_DELIVERY_PERSON_SUCCESS`,
  UPDATE_DELIVERY_PERSON_ERROR: `${KEY} UPDATE_DELIVERY_PERSON_ERROR`,

  DELETE_DELIVERY_PERSON_REQUEST: `${KEY} DELETE_DELIVERY_PERSON_REQUEST`,
  DELETE_DELIVERY_PERSON_SUCCESS: `${KEY} DELETE_DELIVERY_PERSON_SUCCESS`,
  DELETE_DELIVERY_PERSON_ERROR: `${KEY} DELETE_DELIVERY_PERSON_ERROR`,
};

export default types;
