import { useCallback, useEffect, useRef, useState } from "react";
import { TbSection } from "react-icons/tb";

import { useInjectReducer } from "../../../utils/injectReducer";
import { useInjectSaga } from "../../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Sortable from "sortablejs";
import {
  addClient,
  changeOrder,
  deleteClient,
  getClients,
} from "./redux/actions";
import { makeSelectClients, makeSelectClientsLoading } from "./redux/selectors";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import ClientsDropdown from "./clients-dropdown";

const key = "home_screen_clients";

export default function HomeClientsPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const [showClients, setShowClients] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  const sortableContainerRef = useRef(null);

  useEffect(() => {
    dispatch(getClients());
  }, [dispatch]);

  const clients = useSelector(makeSelectClients());
  const isLoading = useSelector(makeSelectClientsLoading());

  const [data, setData] = useState([]);

  useEffect(() => {
    if (clients && !isLoading) {
      setData(clients);
    }
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (sortableContainerRef.current) {
      const sortable = new Sortable(sortableContainerRef.current, {
        animation: 150,
        onEnd: (/**Event*/ evt) => {
          const { newIndex, oldIndex } = evt;
          const newData = [...data];
          newData.splice(newIndex, 0, newData.splice(oldIndex, 1)[0]);
          setData(newData);

          handleChangeClientsOrder(newData);
        },
      });

      return () => {
        sortable.destroy();
      };
    }
  }, [data]);

  const handleChangeClientsOrder = (newOrder) => {
    const clientsPayload = newOrder.map((item, index) => ({
      id: item.id,
      order: index,
    }));

    dispatch(changeOrder({ clients: clientsPayload }));
  };

  const handleAddClient = useCallback(
    (...args) => dispatch(addClient(...args)),
    [dispatch]
  );

  const handleDeleteClient = useCallback(
    (...args) => dispatch(deleteClient(...args)),
    [dispatch]
  );

  if (isLoading) {
    return <div>Učitavanje klijenata</div>;
  }

  return (
    <div className="flex-col">
      <div className="flex justify-between bg-white p-5 mb-4 rounded-md">
        <div className="left flex gap-1">
          <TbSection size={25} />
          <div className=" flex flex-col">
            <span className="text-2xl">Klijenti</span>
            <span className="text-opacity-30 text-sm">
              {/* {sections && sections.length} sekcija ukupno */}
              klijenata ukupno
            </span>
          </div>
        </div>
        <div className="right flex items-center gap-1 ">
          <div className="flex flex-col h-full relative w-[300px] ">
            <div
              className="flex items-center h-full px-4 py-2 border border-black border-opacity-20 rounded-md text-sm text-black text-opacity-40 cursor-pointer"
              onClick={() => setShowClients(!showClients)}
            >
              {selectedClient
                ? selectedClient.company_name
                : "Izaberi klijenta"}
              {showClients ? (
                <MdKeyboardArrowUp className="ml-auto" />
              ) : (
                <MdKeyboardArrowDown className="ml-auto" />
              )}
            </div>
            {showClients && (
              <ClientsDropdown
                setSelectedClient={setSelectedClient}
                setShowClients={setShowClients}
              />
            )}
          </div>
          <button
            className="btn-primary"
            onClick={() => handleAddClient({ client_id: selectedClient.id })}
          >
            Dodaj klijenta
          </button>
        </div>
      </div>
      <div className="bg-white">
        <div className="bg-white flex justify-between p-5  border-b border-black border-opacity-20">
          <div className="grid grid-cols-2 w-[1200px]">
            <span>Ime</span>
            <span>Redosled</span>
          </div>
          <span>Akcija </span>
        </div>

        <div className="clients-list" ref={sortableContainerRef}>
          {data.map((client, index) => {
            return (
              <div
                key={client.id}
                data-id={client.id}
                className="sortable-item client_row px-5 flex justify-between items-center cursor-pointer"
              >
                <div className="grid grid-cols-2 w-[1200px] py-4 text-sm">
                  <span>{client.company_name}</span>
                  <span>{client.order + 1}</span>
                </div>
                <div className="flex gap-3">
                  <RiDeleteBin6Line
                    size={20}
                    color="gray"
                    onClick={() => handleDeleteClient(client.id)}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* {showAddSection && (
        <AddSection setShowAddSection={setShowAddSection} onSubmit={onSubmit} />
      )} */}
      {/* {showEditSection && (
        <EditSection
          sectionId={sectionId}
          setShowEditSection={setShowEditSection}
          onSubmit={onSubmitUpdate}
        />
      )} */}
    </div>
  );
}
