import React, { useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { inlineBG } from "../../utils/inlineBackground";

const UploadLogo = ({ setPhoto }) => {
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState("");

  const uploadPhoto = (e) => {
    let file = e.target.files[0];

    if (file) {
      setError("");
      setPhoto(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  return (
    <div className="w-full">
      <div className="flex items-center gap-2 text-sm">
        <input type="file" title="" onChange={uploadPhoto} />
        <span>Izaberi logo</span>
      </div>
      {preview && (
        <div
          className="h-[150px] w-[150px]"
          style={{
            backgroundImage: `url(${preview})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <FaTimesCircle onClick={() => setPreview(null)} />
        </div>
      )}
      {error && <p className="error_msg">{error}</p>}
    </div>
  );
};

export default UploadLogo;
