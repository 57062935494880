const KEY = "[CMS_HOME_PAGE_CLIENTS]";

const types = {
  GET_CLIENTS_REQUEST: `${KEY} GET_CLIENTS_REQUEST`,
  GET_CLIENTS_SUCCESS: `${KEY} GET_CLIENTS_SUCCESS`,
  SET_CLIENTS: `${KEY} SET_CLIENTS`,
  ADD_CLIENT: `${KEY} ADD_CLIENT`,
  ADD_CLIENT_ERROR: `${KEY} ADD_CLIENT_ERROR`,
  CHANGE_ORDER: `${KEY} CHANGE_ORDER`,
  DELETE_CLIENT: `${KEY} DELETE_CLIENT`,
};

export default types;
