import produce from "immer";
import types from "./constants";

export const initialState = {
  deliverers: {},
  addDelivererIsPending: false,
  updateDelivererIsPending: false,
  deleteDelivererIsPending: false,
};

/* eslint-disable default-case */
const deliverersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_DELIVERY_PEOPLE:
        draft.deliverers = action.payload;
        break;
      case types.ADD_DELIVERY_PERSON_REQUEST:
        draft.addDelivererIsPending = true;
        break;
      case types.ADD_DELIVERY_PERSON_SUCCESS:
      case types.ADD_DELIVERY_PERSON_ERROR:
        draft.addDelivererIsPending = false;
        break;
      case types.UPDATE_DELIVERY_PERSON_REQUEST:
        draft.updateDelivererIsPending = true;
        break;
      case types.UPDATE_DELIVERY_PERSON_SUCCESS:
      case types.UPDATE_DELIVERY_PERSON_ERROR:
        draft.updateDelivererIsPending = false;
        break;
      case types.DELETE_DELIVERY_PERSON_REQUEST:
        draft.deleteDelivererIsPending = true;
        break;
      case types.DELETE_DELIVERY_PERSON_SUCCESS:
      case types.DELETE_DELIVERY_PERSON_ERROR:
        draft.deleteDelivererIsPending = false;
        break;
    }
  });

export default deliverersReducer;
