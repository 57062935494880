import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import {
  makeSelectCreateAttributeSuccess,
  makeSelectCreatePaymentTypeSucess,
  makeSelectSingleAttributeSuccess,
} from "./redux/selectors";

const key = "categories";

export default function AddAttribute({
  showCreateForm,
  // categories,
  onAddAttribute,
  onAddFacilityType,
  onAddKitchenType,
  onAddPaymentType,
  close,
}) {
  const [submitting, setSubmitting] = useState(false);

  //const [fileName, setFileName] = useState("");

  const onSubmitAttribute = (values) => {
    onAddAttribute(values, close);
  };

  const onSubmitFacilityType = (values) => {
    onAddFacilityType(values, close);
  };

  const onSubmitKitchenType = (values) => {
    onAddKitchenType(values, close);
  };

  const onSubmitPaymentType = (values) => {
    onAddPaymentType(values, close);
  };

  // useEffect(() => {
  //   if (submitting && !isCreatePending) {
  //     setShowAddCategory(false);
  //     setSubmitting(false);
  //   }
  // }, [isCreatePending, submitting, setShowAddCategory]);

  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="relative flex flex-col w-[500px] rounded-lg gap-8 bg-white px-12 pt-12 pb-6 ">
        <span
          className="absolute top-2 right-4 cursor-pointer"
          onClick={() => showCreateForm(false)}
        >
          &times;
        </span>
        <span className="text-2xl text-center">Dodaj novi atribut</span>
        <Formik
          initialValues={{ attribute_group: "", name: "", isBinary: false }}
          onSubmit={(values, { setErrors, resetForm }) => {
            const payload = { name: values.name };
            if (values.attribute_group === "attributes") {
              payload.is_binary = values.isBinary;
              onSubmitAttribute(payload, setErrors, resetForm);
            }
            if (values.attribute_group === "facilityTypes") {
              onSubmitFacilityType(payload, setErrors, resetForm);
            }
            if (values.attribute_group === "kitchenTypes") {
              onSubmitKitchenType(payload, setErrors, resetForm);
            }
            if (values.attribute_group === "paymentTypes") {
              onSubmitPaymentType(payload, setErrors, resetForm);
            }

            setSubmitting(true);
          }}
          // validate={(values) => {
          //   const errors = {};
          //   if (!values.parent_id) {
          //     errors.parent_id = "Naziv kategorije roditelj je obavezno polje";
          //   }
          //   if (!values.name) {
          //     errors.name = "Naziv nove kategorije je obavezno polje";
          //   }
          //   return errors;
          // }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => {
            // console.log("IS SUBMITTING?", isSubmitting);
            // console.log("VALUES: ", values);
            return (
              <form onSubmit={handleSubmit} className="flex flex-col gap-8">
                <div className="flex flex-col">
                  <label>Grupa atributa</label>
                  <select
                    name="attribute_group"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.attribute_group}
                    className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm text-opacity-30"
                  >
                    <option value="" disabled selected>
                      Izaberi grupu atributa
                    </option>
                    <option value="facilityTypes">Tip objekta</option>
                    <option value="kitchenTypes">Vrsta kuhinje</option>
                    <option value="paymentTypes">Način plaćanja</option>
                    <option value="attributes">Ostali atributi</option>
                    {/* {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))} */}
                  </select>
                  {/* <p className="text-red-500 text-sm">
                    {errors.attribute_group && touched.attribute_group && errors.attribute_group}
                  </p> */}
                </div>
                <div className="flex flex-col">
                  <label>Naziv novog atributa*</label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    placeholder="Unesi naziv novog atributa"
                    className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                  />
                  <p className="text-red-500 text-sm">
                    {errors.name && touched.name && errors.name}
                  </p>
                </div>
                {values.attribute_group === "attributes" && (
                  <div className="flex items-center gap-4">
                    <label>Vrednost atributa DA/NE*</label>
                    <input
                      type="checkbox"
                      name="isBinary"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={true}
                      placeholder="Unesi vrednost atributa"
                      className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                    />
                    {/* <p className="text-red-500 text-sm">
                      {errors.name && touched.name && errors.name}
                    </p> */}
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn-primary mx-auto "
                >
                  {/* {isCreatePending ? "Dodavanje..." : "Dodaj"} */}
                  Dodaj
                </button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
