import types from "./constants";

export const fetchDeliveryPeople = (payload) => ({
  type: types.FETCH_DELIVERY_PEOPLE,
  payload,
});

export const setDeliveryPeople = (payload) => ({
  type: types.SET_DELIVERY_PEOPLE,
  payload,
});

export const addDeliveryPersonRequest = (payload) => ({
  type: types.ADD_DELIVERY_PERSON_REQUEST,
  payload,
});

export const addDeliveryPersonSuccess = () => ({
  type: types.ADD_DELIVERY_PERSON_SUCCESS,
});

export const addDeliveryPersonError = () => ({
  type: types.ADD_DELIVERY_PERSON_ERROR,
});

export const updateDeliveryPersonRequest = (payload) => ({
  type: types.UPDATE_DELIVERY_PERSON_REQUEST,
  payload,
});

export const updateDeliveryPersonSuccess = () => ({
  type: types.UPDATE_DELIVERY_PERSON_SUCCESS,
});

export const updateDeliveryPersonError = () => ({
  type: types.UPDATE_DELIVERY_PERSON_ERROR,
});

export const deleteDeliveryPersonRequest = (payload) => ({
  type: types.DELETE_DELIVERY_PERSON_REQUEST,
  payload,
});

export const deleteDeliveryPersonSuccess = () => ({
  type: types.DELETE_DELIVERY_PERSON_SUCCESS,
});

export const deleteDeliveryPersonError = () => ({
  type: types.DELETE_DELIVERY_PERSON_ERROR,
});
