import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectClients = (state) => state.clients || initialState;

export const makeSelectClients = () =>
  createSelector(selectClients, (substate) => substate.clients);

export const makeSelectDeliveryPeople = () =>
  createSelector(selectClients, (substate) => substate.deliverers);

export const makeSelectCreateClientIsPending = () =>
  createSelector(selectClients, (substate) => substate.createClientIsPending);

export const makeSelectAddClientIsSuccess = () =>
  createSelector(selectClients, (substate) => substate.addClientSuccess);

export const makeSelectUsers = () =>
  createSelector(selectClients, (substate) => substate.users);

export const makeSelectSingleClient = () =>
  createSelector(selectClients, (substate) => substate.singleClient);

export const makeSelectAttributes = () =>
  createSelector(selectClients, (substate) => substate.attributes);

export const makeSelectKitchenTypes = () =>
  createSelector(selectClients, (substate) => substate.kitchenTypes);

export const makeSelectFacilityTypes = () =>
  createSelector(selectClients, (substate) => substate.facilityTypes);

export const makeSelectPaymentTypes = () =>
  createSelector(selectClients, (substate) => substate.paymentTypes);

export const makeSelectCateringInfo = () =>
  createSelector(selectClients, (substate) => substate.cateringInfo);

export const makeSelectDeliveryInfo = () =>
  createSelector(selectClients, (substate) => substate.deliveryInfo);

export const makeSelectWorkingTimes = () =>
  createSelector(selectClients, (substate) => substate.workingTimes);
