import React, { useCallback, useState } from "react";
import LogoImage from "../images/logo.png";
import { PiUsersThreeBold } from "react-icons/pi";
import { LuNetwork } from "react-icons/lu";
import { BsTag } from "react-icons/bs";
import { BiNetworkChart, BiSolidTruck } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectUser } from "../containers/App/selectors";
import PersonImage from "../images/person.png";
import { logout } from "../containers/App/actions";
import { TbSection } from "react-icons/tb";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { IoHomeOutline } from "react-icons/io5";
import { GoDot } from "react-icons/go";

const key = "app";

const Sidebar = () => {
  const user = useSelector(makeSelectUser());
  const dispatch = useDispatch();
  const [showHomePageLinks, setShowHomePageLinks] = useState(false);

  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  if (!user) {
    return null;
  }

  return (
    <div className="flex flex-col items-center gap-10  w-[260px] fixed top-0 left-0 h-screen bg-white px-5 py-6 border-r border-black border-opacity-20">
      <img className="w-[86px] h-[64.22px]" src={LogoImage} alt="logo"></img>
      <div className="w-full flex flex-col gap-3">
        {user.roles[0] !== "Agent" && user.roles[0] !== "Korisnik" && (
          <Link
            to="/users"
            className="flex items-center px-3 py-2 rounded-md gap-1 bg-zinc-100 cursor-pointer"
          >
            <PiUsersThreeBold />
            <div className="text-sm ">Korisnici</div>
          </Link>
        )}

        <Link
          to="/clients"
          className="flex items-center px-3 py-2 rounded-md gap-1 bg-zinc-100 cursor-pointer "
        >
          <LuNetwork className="rotate-90" />

          <div className="text-sm ">Klijenti</div>
        </Link>
        {user.roles[0] !== "Korisnik" && (
          <Link
            to="/categories"
            className="flex items-center px-3 py-2 rounded-md gap-1 bg-zinc-100 cursor-pointer"
          >
            <LuNetwork className="rotate-90" />
            <div className="text-sm ">Kategorije</div>
          </Link>
        )}
        {user.roles[0] !== "Korisnik" && (
          <Link
            to="/attributes"
            className="flex items-center px-3 py-2 rounded-md gap-1 bg-zinc-100 cursor-pointer"
          >
            {" "}
            <BsTag />
            <div className="text-sm ">Atributi</div>
          </Link>
        )}
        <Link
          to="/client-categories"
          className="flex items-center px-3 py-2 rounded-md gap-1 bg-zinc-100 cursor-pointer "
        >
          <BiNetworkChart />

          <div className="text-sm ">Povezivanje</div>
        </Link>

        <Link
          to="/delivery-people"
          className="flex items-center px-3 py-2 rounded-md gap-1 bg-zinc-100 cursor-pointer "
        >
          <BiSolidTruck />

          <div className="text-sm ">Dostavljači</div>
        </Link>

        <div
          className="flex items-center px-3 py-2 rounded-md gap-1 bg-zinc-100 cursor-pointer "
          onClick={() => setShowHomePageLinks(!showHomePageLinks)}
        >
          <IoHomeOutline />

          <div className="text-sm ">Početna strana</div>
          {showHomePageLinks ? (
            <MdKeyboardArrowUp className="ml-auto" />
          ) : (
            <MdKeyboardArrowDown className="ml-auto" />
          )}
        </div>
        {showHomePageLinks && (
          <div className="pl-2 flex flex-col gap-1">
            <Link
              to="/home-clients"
              className="flex items-center px-3 py-2 rounded-md gap-1 bg-zinc-100 cursor-pointer "
            >
              <GoDot />

              <div className="text-sm ">Popularna mesta u Nišu</div>
            </Link>
            <Link
              to="/home-categories"
              className="flex items-center px-3 py-2 rounded-md gap-1 bg-zinc-100 cursor-pointer "
            >
              <GoDot />

              <div className="text-sm ">Popularne kategorije</div>
            </Link>
            <Link
              to="/home-sections"
              className="flex items-center px-3 py-2 rounded-md gap-1 bg-zinc-100 cursor-pointer "
            >
              <GoDot />

              <div className="text-sm ">Sekcije</div>
            </Link>
            <Link
              to="/home-banners"
              className="flex items-center px-3 py-2 rounded-md gap-1 bg-zinc-100 cursor-pointer "
            >
              <TbSection />

              <div className="text-sm ">Baneri</div>
            </Link>
          </div>
        )}
      </div>

      {user && (
        <div className="flex flex-col mt-auto gap-2">
          <div className="flex gap-2 mr-auto">
            <img
              src={PersonImage}
              alt="Person"
              className="h-11 w-11 rounded-full"
            ></img>
            <div className="flex flex-col ">
              <span>{user.name}</span>
              <span className="text-xs">{user.roles[0]}</span>
            </div>
          </div>
          <button
            className="w-full p-1 bg-red-500 text-white rounded-lg"
            onClick={handleLogout}
          >
            Izloguj se
          </button>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
