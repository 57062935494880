import { takeLatest, call, put, select } from "redux-saga/effects";
//import { push } from "connected-react-router";
import request from "../../../utils/request";
import parseApiErrorsToFormik from "../../../utils/parseApiErrorsToFormik";
import {
  fetchCategories,
  setCategories,
  setSingleCategory,
  fetchSingleCategoryError,
  addCategorySuccess,
  addCategoryError,
  updateCategorySuccess,
  updateCategoryError,
  changeOrder,
  fetchSingleCategory,
  setAttributeValues,
  setClients,
  connectClientCategoriesSuccess,
  connectClientCategoriesError,
  fetchClients,
  disconnectClientCategoriesSuccess,
  disconnectClientCategoriesError,
} from "./actions";
import types from "./constants";
import { enqueueNotification } from "../../../notifications/actions";
//import messages from "../messages";

//import { convertToRaw } from "draft-js";

const BASE_URL = "admin/categories";

export function* getCategories({ payload }) {
  try {
    const res = yield call(request, {
      url: BASE_URL,
      method: "get",
      params: payload,
    });

    yield put(setCategories(res));
  } catch (error) {}
}

export function* getClients({ payload }) {
  try {
    const res = yield call(request, {
      url: "admin/clients",
      method: "get",
    });

    yield put(setClients(res));
  } catch (error) {}
}

export function* getSingleCategory({ payload }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${payload}`,
      method: "get",
    });

    yield put(setSingleCategory(res.data));
  } catch (error) {
    if (error.status === 404)
      yield put(
        fetchSingleCategoryError("Kategorija ne postoji ili je izbrisana.")
      );
  }
}

export function* addCategory({ payload, meta: { setErrors } }) {
  try {
    const res = yield call(request, {
      url: BASE_URL,
      method: "post",
      data: payload,
      headers: { "Content-Type": "multipart/form-data" },
    });

    yield put(addCategorySuccess());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchCategories());
  } catch (error) {
    yield put(addCategoryError());
    const errorMessage = error.data?.message || "Došlo je do greške";
    yield put(enqueueNotification({ message: errorMessage, type: "error" }));
  }
}

export function* updateCategory({ id, payload, meta: { setErrors } }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${id}?_method=patch`,
      method: "post",
      data: payload,
      headers: { "Content-Type": "multipart/form-data" },
    });
    yield put(updateCategorySuccess());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchSingleCategory(id));
    yield put(fetchCategories());
    //yield put(push(routes.CMS_CATEGORIES));
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(updateCategoryError());
  }
}

export function* deleteCategory({ payload }) {
  //console.log("INSIDE SAGA DELETE");
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${payload}`,
      method: "delete",
    });

    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchCategories());
  } catch (error) {
    if (error.status === 422) {
      if (error.data.errors.message) {
        yield put();
        //enqueueSnackbar({ message: error.data.errors.message[0] }, "danger")
      }
    }
  }
}

export function* connectClientCategories({
  client_id,
  payload,
  //meta: { setErrors },
}) {
  try {
    const res = yield call(request, {
      url: `admin/client-categories/${client_id}/connect`,
      method: "post",
      data: payload,
    });
    yield put(connectClientCategoriesSuccess());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchClients());
  } catch (error) {
    if (error.status === 422) {
      //yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(connectClientCategoriesError());
  }
}

export function* disconnectClientCategories({
  client_id,
  payload,
  //meta: { setErrors },
}) {
  try {
    const res = yield call(request, {
      url: `admin/client-categories/${client_id}/disconnect`,
      method: "delete",
      data: payload,
    });
    yield put(disconnectClientCategoriesSuccess());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchClients());
  } catch (error) {
    if (error.status === 422) {
      //yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(disconnectClientCategoriesError());
  }
}

export default function* categoriesSaga() {
  yield takeLatest(types.FETCH_CATEGORIES, getCategories);
  yield takeLatest(types.FETCH_CLIENTS, getClients);
  yield takeLatest(types.FETCH_SINGLE_CATEGORY, getSingleCategory);
  yield takeLatest(types.ADD_CATEGORY_REQUEST, addCategory);
  yield takeLatest(types.UPDATE_CATEGORY_REQUEST, updateCategory);
  yield takeLatest(types.DELETE_CATEGORY_REQUEST, deleteCategory);
  yield takeLatest(
    types.CONNECT_CLIENT_CATEGORIES_REQUEST,
    connectClientCategories
  );
  yield takeLatest(
    types.DISCONNECT_CLIENT_CATEGORIES_REQUEST,
    disconnectClientCategories
  );
}
