import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../../utils/request";
import {
  addAttributeSuccess,
  addFacilityTypeSuccess,
  addKitchenTypeSuccess,
  addPaymentTypeSuccess,
  fetchAttributes,
  fetchFacilityTypes,
  fetchKitchenTypes,
  fetchPaymentTypes,
  fetchSingleAttribute,
  fetchSingleAttributeError,
  fetchSingleFacilityType,
  fetchSingleFacilityTypeError,
  fetchSingleKitchenType,
  fetchSingleKitchenTypeError,
  fetchSinglePaymentType,
  fetchSinglePaymentTypeError,
  setAttributes,
  setFacilityTypes,
  setKitchenTypes,
  setPaymentTypes,
  setSingleAttribute,
  setSingleFacilityType,
  setSingleKitchenType,
  setSinglePaymentType,
  updateAttributeError,
  updateAttributeSuccess,
  updateFacilityTypeError,
  updateFacilityTypeSuccess,
  updateKitchenTypeError,
  updateKitchenTypeSuccess,
  updatePaymentTypeSuccess,
} from "./actions";
import types from "./constants";
import { type } from "@testing-library/user-event/dist/type";
import parseApiErrorsToFormik from "../../../utils/parseApiErrorsToFormik";
import { toast } from "react-toastify";
import { enqueueNotification } from "../../../notifications/actions";

//const BASE_URL = "admin/attributes";

export function* getAttributes() {
  try {
    const res = yield call(request, {
      url: "admin/attributes",
      method: "get",
    });

    yield put(setAttributes(res));
  } catch (error) {}
}

export function* getSingleAttribute({ payload }) {
  try {
    const res = yield call(request, {
      url: `admin/attributes/${payload}`,
      method: "get",
    });

    yield put(setSingleAttribute(res.data));
  } catch (error) {
    if (error.status === 404)
      yield put(
        fetchSingleAttributeError("Atribut ne postoji ili je izbrisan.")
      );
  }
}

export function* addAttribute({ payload, close }) {
  try {
    const res = yield call(request, {
      url: "admin/attributes",
      method: "post",
      data: payload,
    });
    yield put(addAttributeSuccess());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchAttributes());
    yield close();
  } catch (error) {}
}

export function* updateAttribute({ id, payload, meta: { setErrors } }) {
  try {
    const res = yield call(request, {
      url: `admin/attributes/${id}`,
      method: "patch",
      data: payload,
    });
    yield put(updateAttributeSuccess());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchSingleAttribute(id));
    yield put(fetchAttributes());
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(updateAttributeError());
  }
}

export function* deleteAttribute({ payload }) {
  try {
    const res = yield call(request, {
      url: `admin/attributes/${payload}`,
      method: "delete",
    });
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchAttributes());
  } catch (error) {
    if (error.status === 422) {
      if (error.data.errors.message) {
        yield put();
        //enqueueSnackbar({ message: error.data.errors.message[0] }, "danger")
      }
    }
  }
}

export function* getKitchenTypes() {
  try {
    const res = yield call(request, {
      url: "admin/kitchen-types",
      method: "get",
    });

    yield put(setKitchenTypes(res));
  } catch (error) {}
}

export function* getSingleKitchenType({ payload }) {
  try {
    const res = yield call(request, {
      url: `admin/kitchen-types/${payload}`,
      method: "get",
    });

    yield put(setSingleKitchenType(res.data));
  } catch (error) {
    if (error.status === 404)
      yield put(
        fetchSingleKitchenTypeError("Tip kuhinje ne postoji ili je izbrisan.")
      );
  }
}

export function* addKitchenType({ payload, close }) {
  try {
    const res = yield call(request, {
      url: "admin/kitchen-types",
      method: "post",
      data: payload,
    });
    yield put(addKitchenTypeSuccess());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchKitchenTypes());
    yield close();
  } catch (error) {}
}

export function* updateKitchenType({ id, payload, meta: { setErrors } }) {
  try {
    const res = yield call(request, {
      url: `admin/kitchen-types/${id}`,
      method: "patch",
      data: payload,
    });
    yield put(updateKitchenTypeSuccess());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchSingleKitchenType(id));
    yield put(fetchKitchenTypes());
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(updateKitchenTypeError());
  }
}

export function* deleteKitchenType({ payload }) {
  try {
    const res = yield call(request, {
      url: `admin/kitchen-types/${payload}`,
      method: "delete",
    });
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchKitchenTypes());
  } catch (error) {
    if (error.status === 422) {
      if (error.data.errors.message) {
        yield put();
        //enqueueSnackbar({ message: error.data.errors.message[0] }, "danger")
      }
    }
  }
}

export function* getFacilityTypes() {
  try {
    const res = yield call(request, {
      url: "admin/facility-types",
      method: "get",
    });

    yield put(setFacilityTypes(res));
  } catch (error) {}
}

export function* getSingleFacilityType({ payload }) {
  try {
    const res = yield call(request, {
      url: `admin/facility-types/${payload}`,
      method: "get",
    });

    yield put(setSingleFacilityType(res.data));
  } catch (error) {
    if (error.status === 404)
      yield put(
        fetchSingleFacilityTypeError("Tip objekta ne postoji ili je izbrisan.")
      );
  }
}

export function* addFacilityType({ payload, close }) {
  try {
    const res = yield call(request, {
      url: "admin/facility-types",
      method: "post",
      data: payload,
    });
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    //yield put(addFacilityTypeSuccess());
    yield put(fetchFacilityTypes());
    yield close();
  } catch (error) {}
}

export function* updateFacilityType({ id, payload, meta: { setErrors } }) {
  //console.log("SAGA FACILITY:", payload);
  try {
    const res = yield call(request, {
      url: `admin/facility-types/${id}`,
      method: "patch",
      data: payload,
    });
    yield put(updateFacilityTypeSuccess());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchSingleFacilityType(id));
    yield put(fetchFacilityTypes());
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(updateFacilityTypeError());
  }
}

export function* deleteFacilityType({ payload }) {
  try {
    const res = yield call(request, {
      url: `admin/facility-types/${payload}`,
      method: "delete",
    });
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchFacilityTypes());
  } catch (error) {
    if (error.status === 422) {
      if (error.data.errors.message) {
        yield put();
        //enqueueSnackbar({ message: error.data.errors.message[0] }, "danger")
      }
    }
  }
}

export function* getPaymentTypes() {
  try {
    const res = yield call(request, {
      url: "admin/payment-types",
      method: "get",
    });

    yield put(setPaymentTypes(res));
  } catch (error) {}
}

export function* getSinglePaymentType({ payload }) {
  try {
    const res = yield call(request, {
      url: `admin/payment-types/${payload}`,
      method: "get",
    });

    yield put(setSinglePaymentType(res.data));
  } catch (error) {
    if (error.status === 404)
      yield put(
        fetchSinglePaymentTypeError(
          "Način plaćanja ne postoji ili je izbrisan."
        )
      );
  }
}

export function* addPaymentType({ payload, close }) {
  try {
    const res = yield call(request, {
      url: "admin/payment-types",
      method: "post",
      data: payload,
    });
    yield put(addPaymentTypeSuccess());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchPaymentTypes());
    yield close();
  } catch (error) {}
}

export function* updatePaymentType({ id, payload, meta: { setErrors } }) {
  try {
    const res = yield call(request, {
      url: `admin/payment-types/${id}`,
      method: "patch",
      data: payload,
    });
    yield put(updatePaymentTypeSuccess());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchSinglePaymentType(id));
    yield put(fetchPaymentTypes());
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(updateFacilityTypeError());
  }
}

export function* deletePaymentType({ payload }) {
  try {
    const res = yield call(request, {
      url: `admin/payment-types/${payload}`,
      method: "delete",
    });
    yield put(
      enqueueNotification({
        message: res.data.message,
        type: "success",
      })
    );
    yield put(fetchPaymentTypes());
  } catch (error) {
    if (error.status === 422) {
      if (error.data.errors.message) {
        //yield put();
        //enqueueSnackbar({ message: error.data.errors.message[0] }, "danger")
      }
    }
  }
}

export default function* attributesSaga() {
  yield takeLatest(types.FETCH_ATTRIBUTES, getAttributes);
  yield takeLatest(types.ADD_ATTRIBUTE_REQUEST, addAttribute);
  yield takeLatest(types.FETCH_SINGLE_ATTRIBUTE, getSingleAttribute);
  yield takeLatest(types.UPDATE_ATTRIBUTE_REQUEST, updateAttribute);
  yield takeLatest(types.DELETE_ATTRIBUTE_REQUEST, deleteAttribute);

  yield takeLatest(types.FETCH_KITCHEN_TYPES, getKitchenTypes);
  yield takeLatest(types.ADD_KITCHEN_TYPE_REQUEST, addKitchenType);
  yield takeLatest(types.FETCH_SINGLE_KITCHEN_TYPE, getSingleKitchenType);
  yield takeLatest(types.UPDATE_KITCHEN_TYPE_REQUEST, updateKitchenType);
  yield takeLatest(types.DELETE_KITCHEN_TYPE_REQUEST, deleteKitchenType);

  yield takeLatest(types.FETCH_FACILITY_TYPES, getFacilityTypes);
  yield takeLatest(types.ADD_FACILITY_TYPE_REQUEST, addFacilityType);
  yield takeLatest(types.FETCH_SINGLE_FACILITY_TYPE, getSingleFacilityType);
  yield takeLatest(types.UPDATE_FACILITY_TYPE_REQUEST, updateFacilityType);
  yield takeLatest(types.DELETE_FACILITY_TYPE_REQUEST, deleteFacilityType);

  yield takeLatest(types.FETCH_PAYMENT_TYPES, getPaymentTypes);
  yield takeLatest(types.ADD_PAYMENT_TYPE_REQUEST, addPaymentType);
  yield takeLatest(types.FETCH_SINGLE_PAYMENT_TYPE, getSinglePaymentType);
  yield takeLatest(types.UPDATE_PAYMENT_TYPE_REQUEST, updatePaymentType);
  yield takeLatest(types.DELETE_PAYMENT_TYPE_REQUEST, deletePaymentType);
}
