import CategoriesPage from "./pages/categories";
import LoginPage from "./pages/login";
import Layout from "./shared/layout";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { useInjectSaga } from "./utils/injectSaga";
import saga from "./containers/App/saga";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectToken, makeSelectUser } from "./containers/App/selectors";
import { useEffect, useState } from "react";
import { fetchAuthenticatedUser, getModules } from "./containers/App/actions";
import { getItem } from "./utils/localStorage";
import history from "./utils/history";
import { redirect } from "react-router-dom";
import ClientsPage from "./pages/clients";
import { SnackbarProvider } from "notistack";
import UsersPage from "./pages/users";
import AttributesPage from "./pages/attributes";
import ClientCategoriesPage from "./pages/categories/clientCategoriesPage";
import { ProtectedRoute } from "./protectedRoute";

import notificationSaga from "../src/notifications/saga";
import SectionsPage from "./pages/home-page/sections";
import HomeClientsPage from "./pages/home-page/clients";
import HomeCategoriesPage from "./pages/home-page/categories";
import BannersPage from "./pages/home-page/banners";
import DeliveryPeoplePage from "./pages/delivery-people";

const key = "app";

function App() {
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const token = useSelector(makeSelectToken());
  const user = useSelector(makeSelectUser());

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (token) dispatch(fetchAuthenticatedUser());
  }, [token, dispatch]);

  // useEffect(() => {
  //   if (!getItem("modules")) dispatch(getModules());
  // }, [dispatch]);

  return (
    <Router history={history}>
      <Layout>
        <Routes>
          <Route
            path="/clients"
            element={
              <ProtectedRoute>
                <ClientsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/categories"
            element={
              <ProtectedRoute>
                <CategoriesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/attributes"
            element={
              <ProtectedRoute>
                <AttributesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client-categories"
            element={
              <ProtectedRoute>
                <ClientCategoriesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/delivery-people"
            element={
              <ProtectedRoute>
                <DeliveryPeoplePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <UsersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/home-clients"
            element={
              <ProtectedRoute>
                <HomeClientsPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/home-categories"
            element={
              <ProtectedRoute>
                <HomeCategoriesPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/home-sections"
            element={
              <ProtectedRoute>
                <SectionsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/home-banners"
            element={
              <ProtectedRoute>
                <BannersPage />
              </ProtectedRoute>
            }
          />

          <Route path="/login" element={<LoginPage />} />

          {/* Add more routes as needed */}
        </Routes>
        <main></main>

        {/* <Notifier /> */}
      </Layout>
    </Router>
  );
}

export default App;
