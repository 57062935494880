import { call, put, select } from "redux-saga/effects";
import axios from "axios";
import camelCase from "lodash/camelCase";
import mapKeys from "lodash/mapKeys";
import { jwtDecode } from "jwt-decode";

import { logout } from "../containers/App/actions";
import { makeSelectToken } from "../containers/App/selectors";

import { getItem } from "./localStorage";
import { baseApiUrl } from "../env";

const api = axios.create({
  baseURL: baseApiUrl,
});

api.interceptors.response.use(
  (response) => mapKeys(response.data, (_, key) => camelCase(key)),
  (error) => Promise.reject(error.response)
);

export default function* request({ url, method, data, headers = {}, params }) {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      if (Date.now() / 1000 >= jwtDecode(token).exp) {
        yield put(logout());
      }

      headers.Authorization = `Bearer ${token}`;
    }

    headers["Content-Language"] = getItem("language") || "sr";

    return yield call(api, { method, url, headers, data, params });
  } catch (error) {
    if (error.status === 403) {
      //yield put(enqueueSnackbar({ message: "Unauthorized" }, "danger"));
    }
    if (error.status === 500) {
      // yield put(
      //   enqueueSnackbar(
      //     { message: "Došlo je do greške na serveru pokušajte ponovo." },
      //     "danger"
      //   )
      // );
    }
    throw error;
  }
}
