import { Formik } from "formik";

import { useCallback, useEffect, useState } from "react";

import { BiHide, BiShow } from "react-icons/bi";
import { useSelector } from "react-redux";
import { makeSelectCreateUserIsPending } from "./redux/selectors";

const key = "users";

export default function AddUser({ setShowAddUser, onAddUser }) {
  const [submitting, setSubmitting] = useState(false);
  const isCreatePending = useSelector(makeSelectCreateUserIsPending());

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (values) => {
    onAddUser(values);
  };

  useEffect(() => {
    if (submitting && !isCreatePending) {
      setShowAddUser(false);
      setSubmitting(false);
    }
  }, [isCreatePending, submitting, setShowAddUser]);

  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="relative flex flex-col w-[500px] rounded-lg gap-8 bg-white px-12 pt-12 pb-6 ">
        <span
          className="absolute top-2 right-4 cursor-pointer"
          onClick={() => setShowAddUser(false)}
        >
          &times;
        </span>
        <span className="text-2xl text-center">Dodaj novog korisnika</span>
        <Formik
          initialValues={{ name: "", email: "", password: "", role: null }}
          onSubmit={(values, { setErrors, resetForm }) => {
            onSubmit(values, setErrors, resetForm);
            setSubmitting(true);
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Naziv korisnika je obavezno polje";
            }
            if (!values.email) {
              errors.email = "Email korisnika je obavezno polje";
            }
            if (!values.password) {
              errors.password = "Lozinka korisnika je obavezno polje";
            }
            // if (values.password.length < 7) {
            //   errors.password = "Lozinka mora imati više od 7 karaktera";
            // }
            if (!values.role) {
              errors.role = "Uloga korisnika je obavezno polje";
            }
            return errors;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => {
            // console.log("IS SUBMITTING?", isSubmitting);
            // console.log("VALUES: ", values);
            return (
              <form onSubmit={handleSubmit} className="flex flex-col gap-8">
                <div className="flex flex-col">
                  <label>Ime korisnika*</label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    placeholder="Unesi ime korisnika"
                    className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                  />
                  <p className="text-red-500 text-sm">
                    {errors.name && touched.name && errors.name}
                  </p>
                </div>
                <div className="flex flex-col">
                  <label>E-pošta korisnika*</label>
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="Unesi e-poštu korisnika"
                    className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                  />
                  <p className="text-red-500 text-sm">
                    {errors.email && touched.email && errors.email}
                  </p>
                </div>
                <div className="flex flex-col relative">
                  <label>Lozinka korisnika*</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    placeholder="Unesi lozinku korisnika"
                    className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="ml-2 p-2 absolute right-0 top-7 text-sm"
                  >
                    {showPassword ? <BiHide /> : <BiShow />}
                  </button>
                  <p className="text-red-500 text-sm">
                    {errors.password && touched.password && errors.password}
                  </p>
                </div>
                <div className="flex flex-col">
                  <label>Uloga korisnika*</label>
                  <select
                    name="role"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.role}
                    className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                  >
                    <option
                      value=""
                      disabled
                      selected
                      className="text-black text-opacity-30"
                    >
                      Izaberi ulogu korisnika
                    </option>
                    <option className="text-sm " value="SuperAdmin">
                      SuperAdmin
                    </option>
                    <option className="text-sm" value="Administrator">
                      Administrator
                    </option>
                    <option className="text-sm" value="Agent">
                      Agent
                    </option>
                    <option className="text-sm" value="Korisnik">
                      Korisnik
                    </option>
                  </select>
                  <p className="text-red-500 text-sm">
                    {errors.role && touched.role && errors.role}
                  </p>
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn-primary mx-auto "
                >
                  {isCreatePending ? "Dodavanje..." : "Dodaj"}
                </button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
