import types from './constants';

export const fetchAuthenticatedUser = () => ({
  type: types.FETCH_AUTHENTICATED_USER_REQUEST
});

export const fetchAuthenticatedUserSuccess = user => ({
  type: types.FETCH_AUTHENTICATED_USER_SUCCESS,
  user
});

export const logout = () => ({
  type: types.LOGOUT_REQUEST
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS
});

export const setToken = token => ({
  type: types.SET_TOKEN,
  token
});

export const getModules = () => ({
  type: types.GET_MODULES
});

export const setModules = payload => ({
  type: types.SET_MODULES,
  payload
});
