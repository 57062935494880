import Yup from "../../utils/validations";

export const clientSchema = Yup.object().shape({
  // email: Yup.string()
  //   .email("Email mora biti validan")
  //   .max(255)
  //   .required("Polje je obavezno"),
  object_name: Yup.string().required("Polje je obavezno"),
  object_address: Yup.string().required("Polje je obavezno"),
  city: Yup.string().required("Polje je obavezno"),
  contact_person: Yup.string().required("Polje je obavezno"),
  email: Yup.string().required("Polje je obavezno"),
  contact_email: Yup.string().required("Polje je obavezno"),
  catering: Yup.string().required("Polje je obavezno"),
  delivery: Yup.string().required("Polje je obavezno"),
  couriers: Yup.array(
    Yup.object({
      id: Yup.number().required("Polje je obavezno"),
      name: Yup.string().required("Polje je obavezno"),
      image: Yup.string().required("Polje je obavezno"),
      link: Yup.string().required("Polje je obavezno"),
    })
  ).required("Polje je obavezno"),
});
