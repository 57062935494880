import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectCateringData,
  makeSelectCateringInfo,
  makeSelectSingleClient,
} from "./redux/selectors";
import { useInjectReducer } from "../../utils/injectReducer";
import reducer from "./redux/reducer";
import { clearCateringInfo, setCateringInfo } from "./redux/actions";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { parseTime } from "../../utils/parseTime";

const daysOfWeek = [
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
  { id: 7, name: "Sunday" },
];

const key = "clients";

export default function CateringModal({ setShowCateringModal }) {
  const cateringInfo = useSelector(makeSelectCateringInfo());
  const client = useSelector(makeSelectSingleClient());

  useEffect(() => {
    // if (client && client.catering === 1) {
    //   dispatch(setCateringInfo(client.cateringInfo));
    // }
    // if (client.catering === 0) {
    //   dispatch(clearCateringInfo());
    // }
  }, [client]);

  const [cateringDays, setCateringDays] = useState(
    daysOfWeek.map((day, index) => ({
      day: day.name,
      start_time:
        cateringInfo !== 0 ? cateringInfo.catering_days[index].start_time : "",
      end_time:
        cateringInfo !== 0 ? cateringInfo.catering_days[index].end_time : "",
    }))
  );

  //console.log("cateringDays", cateringDays);

  useInjectReducer({ key, reducer });

  const dispatch = useDispatch();

  const [catering_note, setNote] = useState(cateringInfo.catering_note);

  const [catering_phone, setContact] = useState(cateringInfo.catering_phone);

  const handleDayTimeChange = (dayName, startOrEnd, value) => {
    // const formattedValue = value ? dayjs(value).format("HH:mm") : null;
    const formattedValue = dayjs(value).format("HH:mm");
    //console.log("FORMATTED VALUE", formattedValue);

    setCateringDays((current) =>
      current.map((day) =>
        day.day === dayName ? { ...day, [startOrEnd]: formattedValue } : day
      )
    );
    const data = {
      catering: 1,
      catering_days: cateringDays,
      catering_note,
      catering_phone,
    };
    //console.log("handleDayTimeChange", data);
    handleAddData(data);
  };

  const handleAddData = useCallback(
    (...args) => dispatch(setCateringInfo(...args)),
    [dispatch]
  );

  const handleSubmit = () => {
    const data = {
      catering: 1,
      catering_days: cateringDays,
      catering_note,
      catering_phone,
    };
    //console.log(data);
    handleAddData(data);
    setShowCateringModal(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="fixed z-20 inset-0 bg-black bg-opacity-50 flex justify-center items-center cursor-default">
        <div className="relative flex flex-col w-[800px] rounded-lg gap-8 bg-white p-12">
          <span
            className="absolute top-2 right-4 cursor-pointer text-xl"
            onClick={() => setShowCateringModal(false)}
          >
            &times;
          </span>
          <h2 className="text-center text-2xl">Ketering</h2>
          <div className="flex gap-6">
            <div className="w-1/2">
              <h3>Radno vreme</h3>
              {daysOfWeek.map((day, index) => (
                <div
                  key={day.id}
                  className="grid grid-cols-3 items-center gap-2 my-2 w-full"
                >
                  <label>{day.name}</label>
                  <TimePicker
                    ampm={false}
                    value={
                      //cateringInfo.catering === 1
                      //? cateringInfo.catering_days[index].start_time
                      //:
                      parseTime(cateringDays[index].start_time)

                      // cateringInfo.catering === 1
                      //   ? parseTime(
                      //       cateringInfo.catering_days[index].start_time
                      //     )
                      //   : parseTime(cateringDays[index].start_time)
                    }
                    onChange={(newValue) => {
                      //console.log("NEW VALIUE", newValue);
                      handleDayTimeChange(day.name, "start_time", newValue);
                    }}
                    //onKeyDown={(e) => e.preventDefault()}
                  />
                  <TimePicker
                    ampm={false}
                    value={
                      parseTime(cateringDays[index].end_time)
                      // cateringInfo.catering === 1
                      //   ? cateringInfo.catering_days[index].end_time
                      //   : cateringDays[index].end_time
                    }
                    // value={
                    //   cateringInfo.catering === 1
                    //     ? parseTime(cateringInfo.catering_days[index].end_time)
                    //     : parseTime(cateringDays[index].end_time)
                    // }
                    onChange={(newValue) =>
                      handleDayTimeChange(day.name, "end_time", newValue)
                    }
                  />
                </div>
              ))}
            </div>
            <div className="w-1/2">
              <h3>Napomena</h3>
              <textarea
                className="border p-1 w-full h-1/2"
                value={catering_note}
                onChange={(e) => setNote(e.target.value)}
              ></textarea>
              <h3>Kontakt telefon za ketering uslugu</h3>
              <input
                type="text"
                className="border p-1 w-full"
                value={catering_phone}
                onChange={(e) => setContact(e.target.value)}
              />
            </div>
          </div>
          <button className="btn-primary mx-auto" onClick={handleSubmit}>
            Dodaj
          </button>
        </div>
      </div>
    </LocalizationProvider>
  );
}
