import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectCategories = (state) => state.categories || initialState;

const makeSelectCategories = () =>
  createSelector(selectCategories, (substate) => substate.categories);

const makeSelectClients = () =>
  createSelector(selectCategories, (substate) => substate.clients);

const makeSelectSingleCategory = () =>
  createSelector(selectCategories, (substate) => substate.singleCategory);

const makeSelectSingleCategoryError = () =>
  createSelector(selectCategories, (substate) => substate.singleCategoryError);

const makeSelectClientCategoriesIds = () =>
  createSelector(selectCategories, (substate) => substate.clientCategoriesIds);

const makeSelectClientConnect = () =>
  createSelector(selectCategories, (substate) => substate.clientConnect);

const makeSelectCreateCategoryIsPending = () =>
  createSelector(
    selectCategories,
    (substate) => substate.createCategoryIsPending
  );

const makeSelectUpdateCategoryIsPending = () =>
  createSelector(
    selectCategories,
    (substate) => substate.updateCategoryIsPending
  );

export {
  makeSelectCategories,
  makeSelectClients,
  makeSelectSingleCategory,
  makeSelectClientCategoriesIds,
  makeSelectClientConnect,
  makeSelectCreateCategoryIsPending,
  makeSelectUpdateCategoryIsPending,
  makeSelectSingleCategoryError,
};
