import types from "./constants";

export const setParams = (payload) => ({
  type: types.SET_PARAMS,
  payload,
});

export const clearParams = () => ({
  type: types.CLEAR_PARAMS,
});

export const changeOrder = (payload) => ({
  type: types.CHANGE_ORDER_REQUEST,
  payload,
});

export const fetchCategories = (payload) => ({
  type: types.FETCH_CATEGORIES,
  payload,
  //include
});

export const setClients = (payload) => ({
  type: types.SET_CLIENTS,
  payload,
});

export const fetchClients = (payload) => ({
  type: types.FETCH_CLIENTS,
  payload,
  //include
});

export const setCategories = (payload) => ({
  type: types.SET_CATEGORIES,
  payload,
});

export const fetchSingleCategory = (payload) => ({
  type: types.FETCH_SINGLE_CATEGORY,
  payload,
});

export const setSingleCategory = (payload) => ({
  type: types.SET_SINGLE_CATEGORY,
  payload,
});

export const clearSingleCategory = () => ({
  type: types.CLEAR_SINGLE_CATEGORY,
});

export const fetchSingleCategoryError = (error) => ({
  type: types.FETCH_SINGLE_CATEGORY_ERROR,
  error,
});

export const addCategory = (payload, setErrors) => ({
  type: types.ADD_CATEGORY_REQUEST,
  payload,
  meta: { setErrors },
});

export const addCategorySuccess = () => ({
  type: types.ADD_CATEGORY_SUCCESS,
});

export const addCategoryError = () => ({
  type: types.ADD_CATEGORY_ERROR,
});

export const updateCategory = (id, payload, setErrors) => ({
  type: types.UPDATE_CATEGORY_REQUEST,
  id,
  payload,
  meta: { setErrors },
});

export const updateCategorySuccess = () => ({
  type: types.UPDATE_CATEGORY_SUCCESS,
});

export const updateCategoryError = () => ({
  type: types.UPDATE_CATEGORY_ERROR,
});

export const deleteCategory = (payload) => ({
  type: types.DELETE_CATEGORY_REQUEST,
  payload,
  //items,
  //setItems
});

export const addAttribute = (id, payload, setErrors, resetForm) => ({
  type: types.ADD_ATTRIBUTE_REQUEST,
  id,
  payload,
  meta: { setErrors, resetForm },
});

export const updateAttribute = (category_id, payload, setErrors, close) => ({
  type: types.UPDATE_ATTRIBUTE_REQUEST,
  category_id,
  payload,
  meta: { setErrors },
  close,
});

export const deleteAttribute = (category_id, payload) => ({
  type: types.DELETE_ATTRIBUTE_REQUEST,
  category_id,
  payload,
});

export const setClientCategoriesIds = (payload) => ({
  type: types.SET_CLIENT_CATEGORIES_IDS,
  payload,
});

export const clearClientCategoriesIds = (payload) => ({
  type: types.CLEAR_CLIENT_CATEGORIES_IDS,
  payload,
});

export const setConnectClient = (payload) => ({
  type: types.SET_CONNECT_CLIENT,
  payload,
});

export const clearConnectClient = (payload) => ({
  type: types.CLEAR_CONNECT_CLIENT,
  payload,
});

export const connectClientCategories = (client_id, payload) => ({
  type: types.CONNECT_CLIENT_CATEGORIES_REQUEST,
  client_id,
  payload,
});

export const connectClientCategoriesSuccess = () => ({
  type: types.CONNECT_CLIENT_CATEGORIES_SUCCESS,
});

export const connectClientCategoriesError = () => ({
  type: types.CONNECT_CLIENT_CATEGORIES_ERROR,
});

export const disconnectClientCategories = (client_id, payload) => ({
  type: types.DISCONNECT_CLIENT_CATEGORIES_REQUEST,
  client_id,
  payload,
});

export const disconnectClientCategoriesSuccess = () => ({
  type: types.DISCONNECT_CLIENT_CATEGORIES_SUCCESS,
});

export const disconnectClientCategoriesError = () => ({
  type: types.DISCONNECT_CLIENT_CATEGORIES_ERROR,
});
