import Yup from "../../utils/validations";

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email mora biti validan")
    .max(255)
    .required("Polje je obavezno"),
  password: Yup.string()
    .min(8, "Mora imati najmanje 8 karaktera")
    .required("Polje je obavezno"),
});
