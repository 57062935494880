const KEY = "[CMS_CATEGORIES]";

const types = {
  SET_PARAMS: `${KEY} SET_PARAMS`,
  CLEAR_PARAMS: `${KEY} CLEAR_PARAMS`,

  CHANGE_ORDER_REQUEST: `${KEY} CHANGE_ORDER_REQUEST`,

  FETCH_CATEGORIES: `${KEY} FETCH_CATEGORIES`,
  SET_CATEGORIES: `${KEY} SET_CATEGORIES`,
  FETCH_CLIENTS: `${KEY} FETCH_CLIENTS`,
  SET_CLIENTS: `${KEY} SET_CLIENTS`,

  FETCH_SINGLE_CATEGORY: `${KEY} FETCH_SINGLE_CATEGORY`,
  SET_SINGLE_CATEGORY: `${KEY} SET_SINGLE_CATEGORY`,
  CLEAR_SINGLE_CATEGORY: `${KEY} CLEAR_SINGLE_CATEGORY`,
  FETCH_SINGLE_CATEGORY_ERROR: `${KEY} FETCH_SINGLE_CATEGORY_ERROR`,

  ADD_CATEGORY_REQUEST: `${KEY} ADD_CATEGORY_REQUEST`,
  ADD_CATEGORY_SUCCESS: `${KEY} ADD_CATEGORY_SUCCESS`,
  ADD_CATEGORY_ERROR: `${KEY} ADD_CATEGORY_ERROR`,

  UPDATE_CATEGORY_REQUEST: `${KEY} UPDATE_CATEGORY_REQUEST`,
  UPDATE_CATEGORY_SUCCESS: `${KEY} UPDATE_CATEGORY_SUCCESS`,
  UPDATE_CATEGORY_ERROR: `${KEY} UPDATE_CATEGORY_ERROR`,

  SET_CLIENT_CATEGORIES_IDS: `${KEY} SET_CLIENT_CATEGORIES_IDS `,
  CLEAR_CLIENT_CATEGORIES_IDS: `${KEY} CLEAR_CLIENT_CATEGORIES_IDS `,
  SET_CONNECT_CLIENT: `${KEY} SET_CONNECT_CLIENT`,
  CLEAR_CONNECT_CLIENT: `${KEY} CLEAR_CONNECT_CLIENT`,
  CONNECT_CLIENT_CATEGORIES_REQUEST: `${KEY} CONNECT_CLIENT_CATEGORIES_REQUEST`,
  CONNECT_CLIENT_CATEGORIES_SUCCESS: `${KEY} CONNECT_CLIENT_CATEGORIES_SUCCESS`,
  CONNECT_CLIENT_CATEGORIES_ERROR: `${KEY} CONNECT_CLIENT_CATEGORIES_ERROR`,
  DISCONNECT_CLIENT_CATEGORIES_REQUEST: `${KEY} DISCONNECT_CLIENT_CATEGORIES_REQUEST`,
  DISCONNECT_CLIENT_CATEGORIES_SUCCESS: `${KEY} DISCONNECT_CLIENT_CATEGORIES_SUCCESS`,
  DISCONNECT_CLIENT_CATEGORIES_ERROR: `${KEY} DISCONNECT_CLIENT_CATEGORIES_ERROR`,

  DELETE_CATEGORY_REQUEST: `${KEY} DELETE_CATEGORY_REQUEST`,
};

export default types;
