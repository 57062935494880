const APP = `[App]`;

const types = {
  FETCH_AUTHENTICATED_USER_REQUEST: `${APP} FETCH_AUTHENTICATED_USER_REQUEST`,
  FETCH_AUTHENTICATED_USER_SUCCESS: `${APP} FETCH_AUTHENTICATED_USER_SUCCESS`,

  LOGOUT_REQUEST: `${APP} LOGOUT_REQUEST`,
  LOGOUT_SUCCESS: `${APP} LOGOUT_SUCCESS`,

  SET_TOKEN: `${APP} SET_TOKEN`,

  GET_MODULES: `${APP} GET_MODULES`,
  SET_MODULES: `${APP} SET_MODULES`
};

export default types;
