import { useCallback, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { PiUsersThreeBold } from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useInjectReducer } from "../../utils/injectReducer";
import { useInjectSaga } from "../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { useDispatch, useSelector } from "react-redux";
import { addUser, deleteUser, fetchUsers, updateUser } from "./redux/actions";
import { makeSelectUsers } from "./redux/selectors";
import AddUser from "./addUser";
import EditUser from "./editUser";
import { makeSelectUser } from "../../containers/App/selectors";
import { redirect } from "react-router-dom";

const key = "users";

export default function UsersPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const users = useSelector(makeSelectUsers()).data;
  //console.log(users);

  const [showAddUser, setShowAddUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleAddUser = useCallback(
    (...args) => dispatch(addUser(...args)),
    [dispatch]
  );

  const handleEditUser = useCallback(
    (...args) => dispatch(updateUser(...args)),
    [dispatch]
  );

  const handleDelete = useCallback(
    (...args) => dispatch(deleteUser(...args)),
    [dispatch]
  );

  const user = useSelector(makeSelectUser());

  function convertDate(apiDate) {
    const date = new Date(apiDate);
    const readableDate = date.toLocaleDateString("sr-Latn", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    return readableDate;
  }

  if (!user && user.roles[0] !== "Agent" && user.roles[0] !== "Korisnik") {
    return null;
  }

  return (
    <div className="flex-col">
      <div className="flex justify-between bg-white p-5 mb-4 rounded-md">
        <div className="left flex gap-1">
          <PiUsersThreeBold size={25} />
          <div className=" flex flex-col">
            <span className="text-2xl">Korisnici</span>
            <span className="text-opacity-30 text-sm">
              {users && users.length} korisnika ukupno
            </span>
          </div>
        </div>

        <div className="right">
          <button onClick={() => setShowAddUser(true)} className="btn-primary">
            Dodaj novog korisnika
          </button>
        </div>
      </div>
      <div className="bg-white">
        <div className="bg-white flex justify-between p-5  border-b border-black border-opacity-20">
          <div className="grid grid-cols-5 w-[1200px]">
            <span>Ime korisnika</span>
            <span>Uloga korisnika</span>
            <span>Email korisnika </span>
            <span>Datum kreiranja</span>
            <span>Korisnika kreirao</span>
          </div>

          <span>Akcija </span>
        </div>
        {users &&
          users.map((user) => (
            <div className="client_row px-5 flex justify-between items-center ">
              <div className="grid grid-cols-5 w-[1200px] py-4 text-sm">
                <span>{user.name}</span>
                <span>{user.roles[0]}</span>
                <span>{user.email}</span>
                <span>{convertDate(user.created_at)}</span>
                <span>{user.create_user ? user.create_user.name : ""}</span>
              </div>
              <div className="flex gap-3">
                <FiEdit
                  size={20}
                  color="gray"
                  className="cursor-pointer"
                  onClick={() => {
                    setUserId(user.id);
                    setShowEditUser(true);
                  }}
                />
                <RiDeleteBin6Line
                  size={20}
                  color="gray"
                  onClick={() => handleDelete(user.id)}
                  className="cursor-pointer"
                />
              </div>
            </div>
          ))}
      </div>

      {showAddUser && (
        <AddUser setShowAddUser={setShowAddUser} onAddUser={handleAddUser} />
      )}
      {showEditUser && (
        <EditUser
          setShowEditUser={setShowEditUser}
          onEditUser={handleEditUser}
          userId={userId}
        />
      )}
    </div>
  );
}
