import produce from "immer";
import types from "./constants";

export const initialState = {
  sections: null,
  section: null,
  sectionError: null,
  createClientError: null,
  createCategoryError: null,
  activeDevice: "web",
};

/* eslint-disable default-case */
const cmsHomeScreenSectionsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_SECTIONS_REQUEST:
        draft.sectionsLoading = true;
        break;
      case types.GET_SECTIONS_SUCCESS:
        draft.sectionsLoading = false;
        break;
      case types.GET_SECTION_REQUEST:
        draft.sectionLoading = true;
        break;
      case types.GET_SECTION_SUCCESS:
        draft.sectionLoading = false;
        break;
      case types.SET_SECTIONS:
        draft.sections = action.payload;
        break;
      case types.SET_SECTION:
        draft.section = action.payload;
        break;
      case types.GET_SECTION_ERROR:
        draft.sectionError = action.error;
        break;
      case types.CLEAR_SECTION:
        draft.section = null;

        break;
      case types.CREATE_CLIENT_ERROR:
        draft.createClientError = action.error;
        break;
      case types.CREATE_CATEGORY_ERROR:
        draft.createCategoryError = action.error;
        break;
      case types.SET_ACTIVE_DEVICE:
        draft.activeDevice = action.device;
        break;
    }
  });

export default cmsHomeScreenSectionsReducer;
