import * as Yup from "yup";

Yup.setLocale({
  mixed: {
    required: "Polje je obavezno",
    //oneOf: messages.oneOf
  },
  string: {
    email: "Email mora biti validan",
  },
});

export default Yup;
