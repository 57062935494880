import { Formik } from "formik";
import { useInjectReducer } from "../../utils/injectReducer";
import { useInjectSaga } from "../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  clearSingleAttribute,
  clearSingleFacilityType,
  clearSingleKitchenType,
  clearSinglePaymentType,
  fetchSingleAttribute,
  fetchSingleFacilityType,
  fetchSingleKitchenType,
  fetchSinglePaymentType,
  updateAttribute,
  updateFacilityType,
  updateKitchenType,
  updatePaymentType,
} from "./redux/actions";
import {
  makeSelectSingleAttribute,
  makeSelectSingleFacilityType,
  makeSelectSingleKitchenType,
  makeSelectSinglePaymentType,
} from "./redux/selectors";
import { useNavigate } from "react-router-dom";

const key = "categories";

export default function EditAttribute({
  showEditForm,
  editAttributeId,
  attribute_group,
}) {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  //console.log("attribute_group", attribute_group);

  //const isUpdateFacilityTypePending = useSelector(makeSelectUpda);

  //const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (attribute_group === "kitchenType") {
      dispatch(fetchSingleKitchenType(editAttributeId));

      return () => dispatch(clearSingleKitchenType());
    }
    if (attribute_group === "facilityType") {
      dispatch(fetchSingleFacilityType(editAttributeId));

      return () => dispatch(clearSingleFacilityType());
    }

    if (attribute_group === "paymentType") {
      dispatch(fetchSinglePaymentType(editAttributeId));

      return () => dispatch(clearSinglePaymentType());
    }
    if (attribute_group === "attribute") {
      dispatch(fetchSingleAttribute(editAttributeId));

      return () => dispatch(clearSingleAttribute());
    }
  }, [dispatch, editAttributeId, attribute_group]);

  const onSubmitFacilityType = useCallback(
    (...args) => dispatch(updateFacilityType(...args)),
    [dispatch]
  );

  const onSubmitKitchenType = useCallback(
    (...args) => dispatch(updateKitchenType(...args)),
    [dispatch]
  );

  const onSubmitPaymentType = useCallback(
    (...args) => dispatch(updatePaymentType(...args)),
    [dispatch]
  );

  const onSubmitAttribute = useCallback(
    (...args) => dispatch(updateAttribute(...args)),
    [dispatch]
  );

  const facilityType = useSelector(makeSelectSingleFacilityType());
  const kitchenType = useSelector(makeSelectSingleKitchenType());
  const paymentType = useSelector(makeSelectSinglePaymentType());
  const attribute = useSelector(makeSelectSingleAttribute());

  let initialValues;

  if (kitchenType && attribute_group === "kitchenType") {
    initialValues = {
      attribute_group: attribute_group,
      name_en: kitchenType.name_en,
      name_sr_lat: kitchenType.name_sr_lat,
      name_sr_cir: kitchenType.name_sr_cir,
    };
  }
  if (facilityType && attribute_group === "facilityType") {
    initialValues = {
      attribute_group: attribute_group,
      name_en: facilityType.name_en,
      name_sr_lat: facilityType.name_sr_lat,
      name_sr_cir: facilityType.name_sr_cir,
    };
  }
  if (paymentType && attribute_group === "paymentType") {
    initialValues = {
      attribute_group: attribute_group,
      name_en: paymentType.name_en,
      name_sr_lat: paymentType.name_sr_lat,
      name_sr_cir: paymentType.name_sr_cir,
    };
  }
  if (attribute && attribute_group === "attribute") {
    initialValues = {
      attribute_group: attribute_group,
      name_en: attribute.name_en,
      name_sr_lat: attribute.name_sr_lat,
      name_sr_cir: attribute.name_sr_cir,
    };
  }

  // useEffect(() => {
  //   if (submitting && !isCreatePending) {
  //     setShowAddCategory(false);
  //     setSubmitting(false);
  //   }
  // }, [isCreatePending, submitting, setShowAddCategory]);

  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="relative flex flex-col w-[500px] rounded-lg gap-8 bg-white px-12 pt-12 pb-6 ">
        <span
          className="absolute top-2 right-4 cursor-pointer"
          onClick={() => showEditForm(false)}
        >
          &times;
        </span>
        <span className="text-2xl text-center">Ažuriraj atribut</span>
        {!facilityType && !kitchenType && !paymentType && !attribute && (
          <div>Učitavanje atributa..</div>
        )}
        {(facilityType || kitchenType || paymentType || attribute) && (
          <Formik
            initialValues={initialValues}
            // initialValues={{
            //   attribute_group: attribute_group,
            //   name_en: facilityType.name_en,
            //   name_sr_lat: facilityType.name_sr_lat,
            //   name_sr_cir: facilityType.name_sr_cir,
            // }}
            onSubmit={(values, { setErrors, resetForm }) => {
              const payload = {
                name_en: values.name_en,
                name_sr_lat: values.name_sr_lat,
                name_sr_cir: values.name_sr_cir,
              };
              if (values.attribute_group === "attribute") {
                onSubmitAttribute(attribute.id, payload, setErrors, resetForm);
              }
              if (values.attribute_group === "facilityType") {
                // console.log("ONSUBMIT: ", facilityType.id, payload);
                onSubmitFacilityType(
                  facilityType.id,
                  payload,
                  setErrors,
                  resetForm
                );
              }
              if (values.attribute_group === "kitchenType") {
                onSubmitKitchenType(
                  kitchenType.id,
                  payload,
                  setErrors,
                  resetForm
                );
              }
              if (values.attribute_group === "paymentType") {
                onSubmitPaymentType(
                  paymentType.id,
                  payload,
                  setErrors,
                  resetForm
                );
              }

              setSubmitting(true);
            }}
            // validate={(values) => {
            //   const errors = {};
            //   if (!values.parent_id) {
            //     errors.parent_id = "Naziv kategorije roditelj je obavezno polje";
            //   }
            //   if (!values.name) {
            //     errors.name = "Naziv nove kategorije je obavezno polje";
            //   }
            //   return errors;
            // }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => {
              //console.log("IS SUBMITTING?", isSubmitting);
              //console.log("VALUES: ", values);
              return (
                <form onSubmit={handleSubmit} className="flex flex-col gap-8">
                  <div className="flex flex-col">
                    <label>Grupa atributa</label>
                    <select
                      name="attribute_group"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.attribute_group}
                      className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm text-opacity-30"
                    >
                      <option value="" disabled selected>
                        Izaberi grupu atributa
                      </option>
                      <option value="facilityType">Tip objekta</option>
                      <option value="kitchenType">Vrsta kuhinje</option>
                      <option value="paymentType">Način plaćanja</option>
                      <option value="attribute">Ostali atributi</option>
                      {/* {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))} */}
                    </select>
                    {/* <p className="text-red-500 text-sm">
                    {errors.attribute_group && touched.attribute_group && errors.attribute_group}
                  </p> */}
                  </div>
                  <div className="flex flex-col">
                    <label>Naziv atributa engleski*</label>
                    <input
                      type="text"
                      name="name_en"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name_en}
                      placeholder="Unesi naziv atributa engleski"
                      className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                    />
                    <p className="text-red-500 text-sm">
                      {errors.name_en && touched.name_en && errors.name_en}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <label>Naziv atributa latinica*</label>
                    <input
                      type="text"
                      name="name_sr_lat"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name_sr_lat}
                      placeholder="Unesi naziv atributa latinica"
                      className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                    />
                    <p className="text-red-500 text-sm">
                      {errors.name_sr_lat &&
                        touched.name_sr_lat &&
                        errors.name_sr_lat}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <label>Naziv atributa ćirilica*</label>
                    <input
                      type="text"
                      name="name_sr_cir"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name_sr_cir}
                      placeholder="Unesi naziv atributa engleski ćirilica"
                      className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                    />
                    <p className="text-red-500 text-sm">
                      {errors.name_sr_cir &&
                        touched.name_sr_cir &&
                        errors.name_sr_cir}
                    </p>
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn-primary mx-auto "
                  >
                    {/* {isCreatePending ? "Dodavanje..." : "Dodaj"} */}
                    Ažuriraj
                  </button>
                </form>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
}
