import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectWorkingTimes } from "./redux/selectors";
import { useInjectReducer } from "../../utils/injectReducer";
import reducer from "./redux/reducer";
import { setWorkingTimes as setWorkingTimesRedux } from "./redux/actions";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { parseTime } from "../../utils/parseTime";

const daysOfWeek = [
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
  { id: 7, name: "Sunday" },
];

const key = "clients";

export default function WorkingTimesModal({
  setShowWorkingTimesModal,
  currentUserWorkingTimes,
}) {
  useInjectReducer({ key, reducer });
  const dispatch = useDispatch();
  const workingTimesRedux = useSelector(makeSelectWorkingTimes());

  useEffect(() => {
    if (currentUserWorkingTimes) {
      // Set local state with the currentUserWorkingTimes
      setWorkingTimes(currentUserWorkingTimes);

      // Dispatch action to update Redux store
      handleAddData(currentUserWorkingTimes);
    }
  }, []);

  const [workingTimes, setWorkingTimes] = useState(
    daysOfWeek.map((day, index) => ({
      day: day.name,
      start_time: workingTimesRedux.length
        ? workingTimesRedux[index].start_time
        : "",
      end_time: workingTimesRedux.length
        ? workingTimesRedux[index].end_time
        : "",
    }))
  );

  console.log("currentUserWorkingTimes:", workingTimes);

  const handleDayTimeChange = (dayName, startOrEnd, value) => {
    const formattedValue = dayjs(value).format("HH:mm");
    setWorkingTimes((current) =>
      current.map((day) =>
        day.day === dayName ? { ...day, [startOrEnd]: formattedValue } : day
      )
    );
    handleAddData(workingTimes);
  };

  const handleAddData = useCallback(
    (...args) => dispatch(setWorkingTimesRedux(...args)),
    [dispatch]
  );

  const handleSubmit = () => {
    handleAddData(workingTimes);
    setShowWorkingTimesModal(false);
  };

  // const parseTime = (timeString) => {
  //   if (!timeString) return null;
  //   let [hours, minutes] = timeString.split(":").map(Number);
  //   // Convert 12-hour format to 24-hour format
  //   if (timeString.includes("PM") && hours < 12) {
  //     hours += 12;
  //   } else if (timeString.includes("AM") && hours === 12) {
  //     hours = 0;
  //   }
  //   return dayjs().hour(hours).minute(minutes).second(0);
  // };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="fixed z-20 inset-0 bg-black bg-opacity-50 flex justify-center items-center cursor-default">
        <div className="relative flex flex-col w-[800px] rounded-lg gap-8 bg-white p-12">
          <span
            className="absolute top-2 right-4 cursor-pointer text-xl"
            onClick={() => setShowWorkingTimesModal(false)}
          >
            &times;
          </span>
          <h2 className="text-center text-2xl">Radno vreme</h2>
          <div className="flex gap-6">
            <div className="w-3/4 mx-auto">
              {daysOfWeek.map((day, index) => (
                <div
                  key={day.id}
                  className="flex items-center gap-2 my-2 w-full"
                >
                  <label className="w-1/5">{day.name}</label>
                  <TimePicker
                    ampm={false}
                    value={parseTime(workingTimes[index].start_time)}
                    onChange={(newValue) =>
                      handleDayTimeChange(day.name, "start_time", newValue)
                    }
                  />

                  <TimePicker
                    ampm={false}
                    value={parseTime(workingTimes[index].end_time)}
                    onChange={(newValue) =>
                      handleDayTimeChange(day.name, "end_time", newValue)
                    }
                  />
                  {/* <TimePicker
                  value={
                    workingTimesRedux.length
                      ? workingTimesRedux[index].start_time
                      : workingTimes[index].start_time
                  }
                  onChange={(newValue) =>
                    handleDayTimeChange(day.name, "start_time", newValue)
                  }
                  renderInput={(params) => <TextField {...params} />}
                />

                <TimePicker
                  value={
                    workingTimesRedux.length
                      ? workingTimesRedux[index].end_time
                      : workingTimes[index].end_time
                  }
                  onChange={(newValue) =>
                    handleDayTimeChange(day.name, "end_time", newValue)
                  }
                  renderInput={(params) => <TextField {...params} />}
                /> */}
                  {/* <TimePicker
                  value={
                    workingTimesRedux.length
                      ? workingTimesRedux[index].start_time
                      : workingTimes[index].start_time
                  }
                  onChange={(value) =>
                    handleDayTimeChange(day.name, "start_time", value)
                  }
                  //className="flex items-center border border-black border-opacity-20 rounded-md pl-2 text-black text-opacity-30 h-10"
                />
                <TimePicker
                  value={
                    workingTimesRedux.length
                      ? workingTimesRedux[index].end_time
                      : workingTimes[index].end_time
                  }
                  onChange={(value) =>
                    handleDayTimeChange(day.name, "end_time", value)
                  }
                  //className="flex items-center border border-black border-opacity-20 rounded-md pl-2 text-black text-opacity-30 h-10"
                /> */}

                  {/* <input
                  value={
                    workingTimesRedux.length
                      ? workingTimesRedux[index].start_time
                      : workingTimes[index].start_time
                  }
                  type="time"
                  placeholder="od"
                  className="flex items-center border border-black border-opacity-20 rounded-md pl-2 text-black text-opacity-30 h-10"
                  onChange={(e) =>
                    handleDayTimeChange(day.name, "start_time", e.target.value)
                  }
                ></input> */}

                  {/* <input
                  value={
                    workingTimesRedux.length
                      ? workingTimesRedux[index].end_time
                      : workingTimes[index].end_time
                  }
                  type="time"
                  placeholder="do"
                  className="flex items-center border border-black border-opacity-20 rounded-md pl-2 text-black text-opacity-30 h-10"
                  onChange={(e) =>
                    handleDayTimeChange(day.name, "end_time", e.target.value)
                  }
                ></input> */}
                </div>
              ))}
            </div>
          </div>
          <button className="btn-primary mx-auto" onClick={handleSubmit}>
            Dodaj
          </button>
        </div>
      </div>
    </LocalizationProvider>
  );
}
