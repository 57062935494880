import types from "./constants";

export const getClients = () => ({
  type: types.GET_CLIENTS_REQUEST,
});

export const getClientsSuccess = () => ({
  type: types.GET_CLIENTS_SUCCESS,
});

export const setClients = (payload) => ({
  type: types.SET_CLIENTS,
  payload,
});

export const addClient = (client_id) => ({
  type: types.ADD_CLIENT,
  client_id,
});

export const addClientError = (error) => ({
  type: types.ADD_CLIENT_ERROR,
  error,
});

export const changeOrder = (payload) => ({
  type: types.CHANGE_ORDER,
  payload,
});

export const deleteClient = (client_id) => ({
  type: types.DELETE_CLIENT,
  client_id,
});

export const setActiveDevice = (device) => ({
  type: types.SET_ACTIVE_DEVICE,
  device,
});
