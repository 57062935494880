const KEY = "[CMS_CLIENTS]";

const types = {
  FETCH_CLIENTS: `${KEY} FETCH_CLIENTS`,
  SET_CLIENTS: `${KEY} SET_CLIENTS`,

  FETCH_DELIVERY_PEOPLE: `${KEY} FETCH_DELIVERY_PEOPLE`,
  SET_DELIVERY_PEOPLE: `${KEY} SET_DELIVERY_PEOPLE`,

  FETCH_USERS: `${KEY} FETCH_USERS`,
  SET_USERS: `${KEY} SET_USERS`,

  GET_SINGLE_CLIENT: `${KEY} GET_SINGLE_CLIENT`,
  SET_SINGLE_CLIENT: `${KEY} SET_SINGLE_CLIENT`,
  CLEAR_SINGLE_CLIENT: `${KEY} CLEAR_SINGLE_CLIENT`,

  ADD_CLIENT_REQUEST: `${KEY} ADD_CLIENT_REQUEST`,
  ADD_CLIENT_SUCCESS: `${KEY} ADD_CLIENT_SUCCESS`,
  CLEAR_ADD_CLIENT_SUCCESS: `${KEY} CLEAR_ADD_CLIENT_SUCCESS`,
  ADD_CLIENT_ERROR: `${KEY} ADD_CLIENT_ERROR`,

  GET_PHOTOS: `${KEY} GET_PHOTOS`,
  SET_PHOTOS: `${KEY} SET_PHOTOS`,
  SEND_PHOTOS_REQUEST: `${KEY} SEND_PHOTOS_REQUEST`,
  CREATE_MAIN_PHOTO: `${KEY} CREATE_MAIN_PHOTO`,
  REMOVE_PHOTO: `${KEY} REMOVE_PHOTO`,

  CREATE_LOGO: `${KEY} CREATE_LOGO`,
  REMOVE_LOGO: `${KEY} REMOVE_LOGO`,

  CREATE_QR_CODE: `${KEY} CREATE_QR_CODE`,
  REMOVE_QR_CODE: `${KEY} REMOVE_QR_CODE`,

  CREATE_PRICELIST: `${KEY} CREATE_PRICELIST`,
  REMOVE_PRICELIST: `${KEY} REMOVE_PRICELIST`,

  UPDATE_CLIENT_REQUEST: `${KEY} UPDATE_CLIENT_REQUEST`,
  UPDATE_CLIENT_SUCCESS: `${KEY} UPDATE_CLIENT_SUCCESS`,
  UPDATE_CLIENT_ERROR: `${KEY} UPDATE_CLIENT_ERROR`,

  ACTIVATE_CLIENT_REQUEST: `${KEY} ACTIVATE_CLIENT_REQUEST`,
  DEACTIVATE_CLIENT_REQUEST: `${KEY} DEACTIVATE_CLIENT_REQUEST`,

  DELETE_CLIENT_REQUEST: `${KEY} DELETE_CLIENT_REQUEST`,

  ADD_CLIENT_USER_REQUEST: `${KEY} ADD_CLIENT_USER_REQUEST`,
  REMOVE_CLIENT_USER_REQUEST: `${KEY} REMOVE_CLIENT_USER_REQUEST`,

  FETCH_ATTRIBUTES: `${KEY} FETCH_ATTRIBUTES`,
  SET_ATTRIBUTES: `${KEY} SET_ATTRIBUTES`,

  FETCH_KITCHEN_TYPES: `${KEY} FETCH_KITCHEN_TYPES`,
  SET_KITCHEN_TYPES: `${KEY} SET_KITCHEN_TYPES`,

  FETCH_FACILITY_TYPES: `${KEY} FETCH_FACILITY_TYPES`,
  SET_FACILITY_TYPES: `${KEY} SET_FACILITY_TYPES`,

  FETCH_PAYMENT_TYPES: `${KEY} FETCH_PAYMENT_TYPES`,
  SET_PAYMENT_TYPES: `${KEY} SET_PAYMENT_TYPES`,

  SET_CATERING_INFO: `${KEY} SET_CATERING_INFO`,
  CLEAR_CATERING_INFO: `${KEY} CLEAR_CATERING_INFO`,

  SET_DELIVERY_INFO: `${KEY} SET_DELIVERY_INFO`,
  CLEAR_DELIVERY_INFO: `${KEY} CLEAR_DELIVERY_INFO`,

  SET_WORKING_TIMES: `${KEY} SET_WORKING_TIMES`,
  CLEAR_WORKING_TIMES: `${KEY} CLEAR_WORKING_TIMES`,
};

export default types;
