import { useSelector } from "react-redux";
import PersonImage from "../images/person.png";
import { makeSelectUser } from "../containers/App/selectors";
import { Link } from "react-router-dom";

export default function Header() {
  const user = useSelector(makeSelectUser());

  return (
    <header className="flex justify-end   pr-10 py-5 bg-white border-b border-black border-opacity-20">
      <div className="flex gap-5 items-start">
        <div className="flex flex-col">
          <span className="text-xs font-light">Izaberi jezik</span>
          <select>
            <option>Srpski</option>
          </select>
        </div>
        {user && (
          <div className="flex gap-2">
            <div className="flex flex-col ">
              <span>{user.name}</span>
              <span className="text-xs">{user.roles[0]}</span>
            </div>
            <img
              src={PersonImage}
              alt="Person"
              className="h-11 w-11 rounded-full"
            ></img>
          </div>
        )}
        {!user && (
          <Link to="/login" className="btn-primary">
            Uloguj se
          </Link>
        )}
      </div>
    </header>
  );
}
