import types from "./constants";

export const getSections = () => ({
  type: types.GET_SECTIONS_REQUEST,
});

export const getSectionsSuccess = () => ({
  type: types.GET_SECTIONS_SUCCESS,
});

export const setSections = (payload) => ({
  type: types.SET_SECTIONS,
  payload,
});

export const getSection = (section_id) => ({
  type: types.GET_SECTION_REQUEST,
  section_id,
});

export const getSectionSuccess = () => ({
  type: types.GET_SECTION_SUCCESS,
});

export const setSection = (payload) => ({
  type: types.SET_SECTION,
  payload,
});

export const getSectionError = (error) => ({
  type: types.GET_SECTION_ERROR,
  error,
});

export const clearSection = () => ({
  type: types.CLEAR_SECTION,
});

export const createSection = (payload, setErrors) => ({
  type: types.CREATE_SECTION,
  payload,
  meta: { setErrors },
});

export const updateSection = (section_id, payload, close, setErrors) => ({
  type: types.UPDATE_SECTION,
  section_id,
  payload,
  close,
  meta: { setErrors },
});

export const deleteSection = (section_id) => ({
  type: types.DELETE_SECTION,
  section_id,
});

export const changeSectionOrder = (payload) => ({
  type: types.CHANGE_SECTION_ORDER,
  payload,
});

export const createClient = (section_id, payload, clearForm) => ({
  type: types.CREATE_CLIENT,
  section_id,
  payload,
  clearForm,
});

export const createClientError = (error) => ({
  type: types.CREATE_CLIENT_ERROR,
  error,
});

export const deleteClient = (section_id, client_id) => ({
  type: types.DELETE_CLIENT,
  section_id,
  client_id,
});

export const changeClientOrder = (section_id, payload) => ({
  type: types.CHANGE_CLIENT_ORDER,
  section_id,
  payload,
});

export const createCategory = (section_id, payload, clearForm) => ({
  type: types.CREATE_CATEGORY,
  section_id,
  payload,
  clearForm,
});

export const createCategoryError = (error) => ({
  type: types.CREATE_CATEGORY_ERROR,
  error,
});

export const deleteCategory = (section_id, category_id) => ({
  type: types.DELETE_CATEGORY,
  section_id,
  category_id,
});

export const changeCategoryOrder = (section_id, payload) => ({
  type: types.CHANGE_CATEGORY_ORDER,
  section_id,
  payload,
});

export const setActiveDevice = (device) => ({
  type: types.SET_ACTIVE_DEVICE,
  device,
});
