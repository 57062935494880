import types from "./constants";

export const fetchClients = (payload) => ({
  type: types.FETCH_CLIENTS,
  payload,
});

export const fetchDeliveryPeople = (payload) => ({
  type: types.FETCH_DELIVERY_PEOPLE,
  payload,
});

export const setDeliveryPeople = (payload) => ({
  type: types.SET_DELIVERY_PEOPLE,
  payload,
});

export const setClients = (payload) => ({
  type: types.SET_CLIENTS,
  payload,
});

export const fetchUsers = (payload) => ({
  type: types.FETCH_USERS,
  payload,
});

export const setUsers = (payload) => ({
  type: types.SET_USERS,
  payload,
});

export const fetchSingleClient = (payload) => ({
  type: types.GET_SINGLE_CLIENT,
  payload,
});

export const clearSingleClient = (payload) => ({
  type: types.CLEAR_SINGLE_CLIENT,
  payload,
});

export const setSingleClient = (payload) => ({
  type: types.SET_SINGLE_CLIENT,
  payload,
});

export const addClient = (
  payload,
  main_photo,
  photos,
  logo,
  QRCode,
  pricelist,
  setErrors
) => ({
  type: types.ADD_CLIENT_REQUEST,
  payload,
  main_photo,
  photos,
  logo,
  QRCode,
  pricelist,
  meta: { setErrors },
});

export const addClientSuccess = () => ({
  type: types.ADD_CLIENT_SUCCESS,
});

export const clearAddClientSuccess = () => ({
  type: types.CLEAR_ADD_CLIENT_SUCCESS,
});

export const addClientError = () => ({
  type: types.ADD_CLIENT_ERROR,
});

export const getPhotos = (client_id) => ({
  type: types.GET_PHOTOS,
  client_id,
});

export const setPhotos = (payload) => ({
  type: types.SET_PHOTOS,
  payload,
});

export const sendPhotosRequest = (id, payload, method) => ({
  type: types.SEND_PHOTOS_REQUEST,
  id,
  payload,
  method,
});

export const createMainPhoto = (id, photo, method) => ({
  type: types.CREATE_MAIN_PHOTO,
  id,
  photo,
  method,
});

export const removePhoto = (client_id, img_id) => ({
  type: types.REMOVE_PHOTO,
  client_id,
  img_id,
});

export const createLogo = (id, photo, method) => ({
  type: types.CREATE_LOGO,
  id,
  photo,
  method,
});

export const removeLogo = (client_id) => ({
  type: types.REMOVE_LOGO,
  client_id,
});

export const createQRCode = (id, photo, method) => ({
  type: types.CREATE_QR_CODE,
  id,
  photo,
  method,
});

export const removeQRCode = (client_id) => ({
  type: types.REMOVE_QR_CODE,
  client_id,
});

export const createPricelist = (id, file, method) => ({
  type: types.CREATE_PRICELIST,
  id,
  file,
  method,
});

export const removePricelist = (client_id) => ({
  type: types.REMOVE_PRICELIST,
  client_id,
});

export const updateClient = (id, payload, setErrors) => ({
  type: types.UPDATE_CLIENT_REQUEST,
  id,
  payload,
  meta: { setErrors },
});

export const activateClient = (id, payload) => ({
  type: types.ACTIVATE_CLIENT_REQUEST,
  id,
  payload,
});

export const updateClientSuccess = () => ({
  type: types.UPDATE_CLIENT_SUCCESS,
});

export const updateClientError = () => ({
  type: types.UPDATE_CLIENT_ERROR,
});

export const deleteClient = (payload) => ({
  type: types.DELETE_CLIENT_REQUEST,
  payload,
  //items,
  //setItems
});

export const addClientUser = (client_id, payload) => ({
  type: types.ADD_CLIENT_USER_REQUEST,
  client_id,
  payload,
});

export const removeClientUser = (client_id, payload) => ({
  type: types.REMOVE_CLIENT_USER_REQUEST,
  client_id,
  payload,
});

export const fetchAttributes = (payload) => ({
  type: types.FETCH_ATTRIBUTES,
  payload,
});

export const setAttributes = (payload) => ({
  type: types.SET_ATTRIBUTES,
  payload,
});

export const fetchKitchenTypes = (payload) => ({
  type: types.FETCH_KITCHEN_TYPES,
  payload,
});

export const setKitchenTypes = (payload) => ({
  type: types.SET_KITCHEN_TYPES,
  payload,
});

export const fetchFacilityTypes = (payload) => ({
  type: types.FETCH_FACILITY_TYPES,
  payload,
});

export const setFacilityTypes = (payload) => ({
  type: types.SET_FACILITY_TYPES,
  payload,
});

export const fetchPaymentTypes = (payload) => ({
  type: types.FETCH_PAYMENT_TYPES,
  payload,
});

export const setPaymentTypes = (payload) => ({
  type: types.SET_PAYMENT_TYPES,
  payload,
});

export const setCateringInfo = (payload) => ({
  type: types.SET_CATERING_INFO,
  payload,
});

export const clearCateringInfo = () => ({
  type: types.CLEAR_CATERING_INFO,
});

export const setDeliveryInfo = (payload) => ({
  type: types.SET_DELIVERY_INFO,
  payload,
});

export const clearDeliveryInfo = () => ({
  type: types.CLEAR_DELIVERY_INFO,
});

export const setWorkingTimes = (payload) => ({
  type: types.SET_WORKING_TIMES,
  payload,
});

export const clearWorkingTimes = () => ({
  type: types.CLEAR_WORKING_TIMES,
});
