import types from "./constants";

export const login = (payload, setErrors) => ({
  type: types.LOGIN_REQUEST,
  payload,
  meta: { setErrors },
});

export const loginSuccess = () => ({
  type: types.LOGIN_SUCCESS,
});

export const loginError = () => ({
  type: types.LOGIN_ERROR,
});
