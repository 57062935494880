import produce from 'immer';
import types from './constants';

export const initialState = {
  isPending: false
};

/* eslint-disable default-case */
const cmsAuthReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.LOGIN_REQUEST:
        draft.isPending = true;
        break;
      case types.LOGIN_SUCCESS:
      case types.LOGIN_ERROR:
        draft.isPending = false;
        break;
    }
  });

export default cmsAuthReducer;
