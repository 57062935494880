import { LuNetwork } from "react-icons/lu";
import { useInjectReducer } from "../../utils/injectReducer";
import { useInjectSaga } from "../../utils/injectSaga";
import { useCallback, useEffect, useState } from "react";
import AddClient from "./addClient";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectAttributes,
  makeSelectClients,
  makeSelectFacilityTypes,
  makeSelectKitchenTypes,
  makeSelectPaymentTypes,
  makeSelectUsers,
} from "./redux/selectors";
import {
  activateClient,
  addClient,
  addClientUser,
  clearSingleClient,
  deleteClient,
  fetchAttributes,
  fetchClients,
  fetchFacilityTypes,
  fetchKitchenTypes,
  fetchPaymentTypes,
  fetchSingleClient,
  fetchUsers,
  removeClientUser,
  setSingleClient,
  updateClient,
} from "./redux/actions";
import { makeSelectUser } from "../../containers/App/selectors";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import EditClient from "./editClient";
import { CiUser } from "react-icons/ci";
import UsersForm from "./usersForm";
import { Checkbox, ToggleButton } from "@mui/material";

const key = "clients";

export default function ClientsPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showUsersForm, setShowUsersForm] = useState(false);

  const dispatch = useDispatch();
  const clients = useSelector(makeSelectClients()).data;
  const attributes = useSelector(makeSelectAttributes());
  const kitchenTypes = useSelector(makeSelectKitchenTypes());
  const facilityTypes = useSelector(makeSelectFacilityTypes());
  const paymentTypes = useSelector(makeSelectPaymentTypes());
  const user = useSelector(makeSelectUser());

  useEffect(() => {
    dispatch(fetchClients());
    dispatch(fetchUsers());
    dispatch(fetchAttributes());
    dispatch(fetchKitchenTypes());
    dispatch(fetchFacilityTypes());
    dispatch(fetchPaymentTypes());
  }, [dispatch]);

  // const handleAddClient = useCallback(
  //   (...args) => dispatch(addClient(...args)),
  //   [dispatch]
  // );

  const handleSetSingleClient = useCallback(
    (...args) => {
      dispatch(clearSingleClient());
      dispatch(fetchSingleClient(...args));
    },
    [dispatch]
  );

  const handleDelete = useCallback(
    (...args) => dispatch(deleteClient(...args)),
    [dispatch]
  );

  const handleAddClientUser = useCallback(
    (...args) => dispatch(addClientUser(...args)),
    [dispatch]
  );

  const handleRemoveClientUser = useCallback(
    (...args) => dispatch(removeClientUser(...args)),
    [dispatch]
  );

  const handleActivateClient = useCallback(
    (...args) => dispatch(activateClient(...args)),
    [dispatch]
  );

  if (!user) {
    return null;
  }

  return (
    <div className="flex-col">
      {!showCreateForm && !showUpdateForm && (
        <>
          <div className="flex justify-between bg-white p-5 mb-4 rounded-md">
            <div className="left flex gap-1">
              <LuNetwork className="rotate-90" size={25} />
              <div className=" flex flex-col">
                <span className="text-2xl">Klijenti</span>
                <span className="text-opacity-30 text-sm">
                  {clients && clients.length} klijenata ukupno
                </span>
              </div>
            </div>
            {user.roles[0] !== "Korisnik" && (
              <div className="right">
                <button
                  onClick={() => setShowCreateForm(true)}
                  className="btn-primary"
                >
                  Dodaj novog klijenta
                </button>
              </div>
            )}
          </div>

          <div className="bg-white">
            <div className="bg-white flex justify-between p-5  border-b border-black border-opacity-20">
              <div className="grid grid-cols-5 w-[1400px]">
                <span>Ime firme/radnje</span>
                <span>Agent</span>
                <span>Adresa klijenta </span>
                <span>Korisnici</span>
                <span>Aktivan</span>
              </div>

              <span>Akcija </span>
            </div>
            {clients &&
              clients.map((client) => (
                <div className="client_row px-5 flex justify-between items-center ">
                  <div className="grid grid-cols-5 w-[1400px] py-4 text-sm">
                    <span>{client.company_name}</span>
                    <span>{client.agent.name}</span>
                    <span>{client.company_address}</span>
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        handleSetSingleClient(client.id);
                        setShowUsersForm(true);
                      }}
                    >
                      {client.users.length}
                    </span>

                    <span>
                      <Checkbox onChange={(e)=>{
                        handleActivateClient(client.id, {active: e.target.checked ? 1 : 0})
                      }} checked={client?.active ? true :false} />
                      </span>
                  </div>
                  <div className="flex gap-3">
                    <CiUser
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        handleSetSingleClient(client.id);
                        setShowUsersForm(true);
                      }}
                    />

                    <FiEdit
                      size={20}
                      color="gray"
                      onClick={() => {
                        handleSetSingleClient(client.id);
                        setShowUpdateForm(true);
                      }}
                      className="cursor-pointer"
                    />
                    {user.roles[0] !== "Agent" &&
                      user.roles[0] !== "Korisnik" && (
                        <RiDeleteBin6Line
                          size={20}
                          color="gray"
                          onClick={() => handleDelete(client.id)}
                          className="cursor-pointer"
                        />
                      )}
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
      {showCreateForm && (
        <AddClient
          showCreateForm={setShowCreateForm}
          attributes={attributes}
          kitchenTypes={kitchenTypes.data}
          facilityTypes={facilityTypes.data}
          paymentTypes={paymentTypes.data}
          //onAddClient={handleAddClient}
        />
      )}
      {showUpdateForm && (
        <EditClient
          showUpdateForm={setShowUpdateForm}
          attributes={attributes}
          kitchenTypes={kitchenTypes.data}
          facilityTypes={facilityTypes.data}
          paymentTypes={paymentTypes.data}
          //onEditClient={handleUpdateClient}
        />
      )}
      {showUsersForm && (
        <UsersForm
          setShowUsersForm={setShowUsersForm}
          onAddUser={handleAddClientUser}
          onRemoveUser={handleRemoveClientUser}
        />
      )}
    </div>
  );
}
