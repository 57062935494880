import { Link, useNavigate } from "react-router-dom";
import LogoImage from "../../images/logo.png";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { loginSchema } from "./validations";
import { useCallback, useEffect } from "react";
import { useInjectReducer } from "../../utils/injectReducer";
import { useInjectSaga } from "../../utils/injectSaga";
import { useDispatch, useSelector } from "react-redux";
import reducer from "./reducer";
import saga from "./saga";
import { login } from "./actions";
import {
  makeSelectToken,
  makeSelectUser,
} from "../../containers/App/selectors";
import { fetchAuthenticatedUser } from "../../containers/App/actions";

const key = "cmsAuth";

export default function LoginPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const token = useSelector(makeSelectToken());
  const user = useSelector(makeSelectUser());
  const navigate = useNavigate();

  if (user) {
    navigate("/categories");
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) dispatch(fetchAuthenticatedUser());
  }, [token, dispatch]);

  const submitLoginForm = useCallback(
    (...args) => dispatch(login(...args)),
    [dispatch]
  );

  return (
    <div className="loginPage">
      <div className=" bg-red-500 absolute w-full h-full top-0 left-0 flex justify-center items-center">
        <div className="flex flex-col gap-8 bg-white  z-10 w-[497px] p-12 rounded-lg">
          <img
            className="w-[220px] h-[164px] mx-auto"
            src={LogoImage}
            alt="logo"
          ></img>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginSchema}
            onSubmit={(values, { setErrors }) =>
              submitLoginForm(values, setErrors)
            }
          >
            {({ errors, touched }) => (
              <Form className="flex flex-col gap-8">
                <h1 className="text-2xl text-center">Uloguj se</h1>
                <div className="flex flex-col">
                  <label htmlFor="email">Adresa e-pošte*</label>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Unesi vašu e-mail adresu"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error_message"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="password">Lozinka*</label>
                  <Field
                    type="password"
                    name="password"
                    placeholder="Unesi vašu lozinku"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error_message"
                  />
                </div>

                <div className="bottom flex justify-between items-center">
                  <button
                    type="submit"
                    //disabled={isSubmitting}
                    className="btn-primary"
                  >
                    Uloguj se
                  </button>

                  <span className="text-opacity-30 text-sm cursor-pointer">
                    Zaboravio si lozinku?
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
