import { takeLatest, call, put, select } from "redux-saga/effects";

import request from "../../../../utils/request";
import {
  getSections,
  setSections,
  getSection,
  setSection,
  getSectionError,
  createCategoryError,
  getSectionsSuccess,
  getSectionSuccess,
  createClientError,
} from "./actions";
import types from "./constants";
import { enqueueNotification } from "../../../../notifications/actions";
import parseApiErrorsToFormik from "../../../../utils/parseApiErrorsToFormik";

const BASE_URL = "admin/home-sections";

export function* fetchSections() {
  try {
    const { data } = yield call(request, {
      url: `admin/home-page/web/home-sections`,
      method: "get",
    });
    yield put(setSections(data));
    yield put(getSectionsSuccess());
  } catch (error) {}
}

export function* fetchSection({ section_id }) {
  try {
    const { data } = yield call(request, {
      url: `admin/home-page/web/home-sections/${section_id}`,
      method: "get",
    });
    yield put(setSection(data));
    yield put(getSectionSuccess());
  } catch (error) {
    if (error.status === 404)
      yield put(getSectionError("Sekcija ne postoji ili je izbrisana."));
  }
}

export function* createSection({ payload, meta: { setErrors } }) {
  try {
    const res = yield call(request, {
      url: `admin/home-page/web/home-sections`,
      method: "post",
      data: payload,
    });
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(getSections());
    //yield put(push(routes.CMS_HOME_SCREEN_SECTIONS));
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}

export function* updateSection({
  section_id,
  payload,
  close,
  meta: { setErrors },
}) {
  try {
    const res = yield call(request, {
      url: `admin/home-page/web/home-sections/${section_id}`,
      method: "patch",
      data: payload,
    });
    yield put(getSections());
    if (close) yield close();
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}

export function* deleteSection({ section_id }) {
  try {
    const res = yield call(request, {
      url: `admin/home-page/web/home-sections/${section_id}`,
      method: "delete",
    });
    yield put(getSections());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {}
}

export function* changeSectionOrder({ payload }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/order`,
      method: "patch",
      data: payload,
    });
    yield put(getSections());
  } catch (error) {}
}

export function* addClient({ section_id, payload, clearForm }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${section_id}/clients`,
      method: "post",
      data: payload,
    });
    yield put(getSection(section_id));
    //yield clearForm();
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    //yield put(createClientError(null));
  } catch (error) {
    yield put(
      enqueueNotification({ message: error.data.message, type: "error" })
    );
  }
}

export function* changeClientOrder({ section_id, payload }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/${section_id}/clients`,
      method: "patch",
      data: payload,
    });
    yield put(getSection(section_id));
  } catch (error) {}
}

export function* deleteClient({ section_id, client_id }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${section_id}/clients/${client_id}`,
      method: "delete",
    });
    yield put(getSection(section_id));
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {}
}

export function* addCategory({ section_id, payload, clearForm }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${section_id}/categories`,
      method: "post",
      data: payload,
    });
    yield put(getSection(section_id));
    //yield clearForm();
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(createCategoryError(null));
  } catch (error) {
    yield put(createCategoryError(parseApiErrorsToFormik(error.data.errors)));
  }
}

export function* changeCategoryOrder({ section_id, payload }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/${section_id}/categories`,
      method: "patch",
      data: payload,
    });
    yield put(getSection(section_id));
  } catch (error) {}
}

export function* deleteCategory({ section_id, category_id }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${section_id}/categories/${category_id}`,
      method: "delete",
    });
    yield put(getSection(section_id));
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {}
}

export default function* cmsHomeScreenSectionsSaga() {
  yield takeLatest(types.GET_SECTIONS_REQUEST, fetchSections);
  yield takeLatest(types.GET_SECTION_REQUEST, fetchSection);
  yield takeLatest(types.CREATE_SECTION, createSection);
  yield takeLatest(types.UPDATE_SECTION, updateSection);
  yield takeLatest(types.DELETE_SECTION, deleteSection);
  yield takeLatest(types.CHANGE_SECTION_ORDER, changeSectionOrder);
  yield takeLatest(types.CREATE_CATEGORY, addCategory);
  yield takeLatest(types.CHANGE_CATEGORY_ORDER, changeCategoryOrder);
  yield takeLatest(types.DELETE_CATEGORY, deleteCategory);
  yield takeLatest(types.CREATE_CLIENT, addClient);
  yield takeLatest(types.CHANGE_CLIENT_ORDER, changeClientOrder);
  yield takeLatest(types.DELETE_CLIENT, deleteClient);
}
