import { useCallback, useEffect, useState } from "react";
import { LuNetwork } from "react-icons/lu";

import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "../../utils/injectReducer";
import { useInjectSaga } from "../../utils/injectSaga";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import { fetchCategories, addCategory } from "./redux/actions";
import { makeSelectCategories } from "./redux/selectors";
import TreeNode from "./treeNode";
import AddCategory from "./addCategory";
import { makeSelectUser } from "../../containers/App/selectors";

const key = "categories";

export default function CategoriesPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const [showAddCategory, setShowAddCategory] = useState(false);
  // const [showEditCategory, setShowEditCategory] = useState(false);
  //const [editCategory, setEditCategory] = useState(null);

  const dispatch = useDispatch();
  const categories = useSelector(makeSelectCategories());
  const user = useSelector(makeSelectUser());

  const { data } = categories;

  let topLevelItems;

  if (data) {
    topLevelItems = data.filter((item) => item.parent_id === null);
  }

  //console.log("CATEGORIES data", data);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleAddCategory = useCallback(
    (...args) => dispatch(addCategory(...args)),
    [dispatch]
  );

  if (!user) {
    return null;
  }

  // function handleEditClick(node) {
  //   setEditCategory(node);
  //   setShowEditCategory(!showEditCategory);
  // }

  return (
    <div className="flex flex-col">
      <div className="flex justify-between bg-white p-5 mb-4 rounded-md">
        <div className="left flex gap-1">
          <LuNetwork className="rotate-90" size={25} />
          <div className=" flex flex-col">
            <span className="text-2xl">Kategorije</span>
            <span className="text-opacity-30 text-sm">
              80 kategorija ukupno
            </span>
          </div>
        </div>

        <div className="right">
          <button
            onClick={() => setShowAddCategory(true)}
            className="btn-primary"
          >
            Dodaj novu kategoriju
          </button>
        </div>
      </div>
      {showAddCategory && (
        <div>
          <AddCategory
            setShowAddCategory={setShowAddCategory}
            categories={data}
            onAddCategory={handleAddCategory}
          />
        </div>
      )}
      {/* {showEditCategory && (
        <div>
          <EditCategory
            categories={data}
            setShowEditCategory={handleEditClick}
            node={editCategory}
          />
        </div>
      )} */}
      <div className="bg-white flex justify-between  p-5 border-b border-black border-opacity-20">
        <span>Naziv kategorije</span>
        <span>Akcija</span>
      </div>

      {data &&
        topLevelItems.map((item) => (
          <TreeNode
            key={item.id}
            node={item}
            data={data}
            //onEditClick={handleEditClick}
          />
        ))}
    </div>
  );
}
