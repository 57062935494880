import React, { useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { inlineBG } from "../../utils/inlineBackground";

const UploadPhotos = ({ photos, setPhotos }) => {
  const [previews, setPreviews] = useState([]);
  const [error, setError] = useState("");

  const uploadPhotos = (e) => {
    let files = e.target.files;
    let arr = [];
    let arr2 = [];

    for (let i = 0; i < files.length; i++) {
      setError("");
      arr.push(files[i]);
      arr2.push(URL.createObjectURL(files[i]));
    }

    setPhotos(photos.concat(arr));
    setPreviews(previews.concat(arr2));
  };

  const removePhoto = (img, i) => {
    let copy = [...photos];
    copy.splice(i, 1);
    setPhotos(copy);
    setPreviews(previews.filter((item) => item !== img));
    setError("");
  };

  return (
    <div className="w-full">
      <div className="flex items-center gap-2 text-sm">
        <input type="file" title="" multiple onChange={uploadPhotos} />
        <span>Izaberi slike</span>
      </div>
      {previews.length ? (
        <div className="flex gap-2 flex-wrap">
          {previews.map((item, i) => (
            <div
              className="h-[150px] w-[150px]"
              key={i}
              style={{
                backgroundImage: `url(${item})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <FaTimesCircle onClick={() => removePhoto(item, i)} />
            </div>
          ))}
        </div>
      ) : null}
      {error && <p className="error_msg">{error}</p>}
    </div>
  );
};

export default UploadPhotos;
