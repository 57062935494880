import { takeLatest, call, put, select } from "redux-saga/effects";
import request from "../../../../utils/request";
import {
  getCategories,
  setCategories,
  addCategoryError,
  getCategoriesSuccess,
  changeImageSuccess,
} from "./actions";
import types from "./constants";
import { enqueueNotification } from "../../../../notifications/actions";

export function* fetchCategories() {
  try {
    const res = yield call(request, {
      url: `admin/home-page/web/home-categories`,
      method: "get",
    });
    yield put(setCategories(res.data));
    yield put(getCategoriesSuccess());
  } catch (error) {}
}

export function* addCategory({ payload, closeModal }) {
  console.log("addCategory SAGA payload", payload);
  const { category_id, photo } = payload;
  const formData = new FormData();
  formData.append("category_id", category_id);
  formData.append(`image`, photo, photo.name);

  try {
    const res = yield call(request, {
      url: `admin/home-page/web/home-categories`,
      method: "post",
      data: formData,
    });
    yield put(getCategories());
    yield closeModal();
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(addCategoryError(null));
  } catch (error) {
    //yield put(addCategoryError(parseApiErrorsToFormik(error.data.errors)));
  }
}

export function* changePhoto({ category_id, image, close }) {
  const formData = new FormData();
  formData.append(`image`, image, image.name);

  try {
    const res = yield call(request, {
      url: `admin/home-page/web/home-categories/${category_id}/image`,
      method: "post",
      data: formData,
    });
    yield put(getCategories());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    //yield put(changeImageSuccess());
    yield close();
  } catch (error) {}
}

export function* changeOrder({ payload }) {
  try {
    yield call(request, {
      url: `admin/home-page/web/home-categories`,
      method: "patch",
      data: payload,
    });
    yield put(getCategories());
  } catch (error) {}
}

export function* deleteCategory({ category_id }) {
  try {
    const res = yield call(request, {
      url: `admin/home-page/web/home-categories/${category_id}`,
      method: "delete",
    });
    yield put(getCategories());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
  } catch (error) {}
}

export default function* cmsHomeScreenCategoriesSaga() {
  yield takeLatest(types.GET_CATEGORIES_REQUEST, fetchCategories);
  yield takeLatest(types.ADD_CATEGORY, addCategory);
  yield takeLatest(types.CHANGE_IMAGE_REQUEST, changePhoto);
  yield takeLatest(types.CHANGE_ORDER, changeOrder);
  yield takeLatest(types.DELETE_CATEGORY, deleteCategory);
}
