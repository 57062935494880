import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectCategories = (state) =>
  state.home_screen_categories || initialState;

const makeSelectCategories = () =>
  createSelector(selectCategories, (substate) => substate.categories);

const makeSelectCategoriesLoading = () =>
  createSelector(selectCategories, (substate) => substate.categoriesLoading);

const makeSelectError = () =>
  createSelector(selectCategories, (substate) => substate.error);

const makeSelectActiveDevice = () =>
  createSelector(selectCategories, (substate) => substate.activeDevice);

export {
  makeSelectCategories,
  makeSelectCategoriesLoading,
  makeSelectError,
  makeSelectActiveDevice,
};
