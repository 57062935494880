import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectBanners = (state) => state.cms_banners || initialState;

const makeSelectBanners = () =>
  createSelector(selectBanners, (substate) => substate.banners);

const makeSelectBannersLoading = () =>
  createSelector(selectBanners, (substate) => substate.bannersLoading);

const makeSelectBanner = () =>
  createSelector(selectBanners, (substate) => substate.banner);

const makeSelectCategory = () =>
  createSelector(selectBanners, (substate) => substate.category);

const makeSelectBannerError = () =>
  createSelector(selectBanners, (substate) => substate.bannerError);

const makeSelectBannersPositions = () =>
  createSelector(selectBanners, (substate) => substate.bannersPositions);

const makeSelectParams = () =>
  createSelector(selectBanners, (substate) => substate.params);

const makeSelectPosition = () =>
  createSelector(selectBanners, (substate) => substate.position);

export {
  makeSelectBanners,
  makeSelectBannersLoading,
  makeSelectBanner,
  makeSelectBannerError,
  makeSelectBannersPositions,
  makeSelectParams,
  makeSelectPosition,
  makeSelectCategory,
};
