import { produce } from "immer";
import types from "./constants";

export const initialState = {
  clients: {},
  singleClient: null,
  attributes: {},
  kitchenTypes: {},
  facilityTypes: {},
  paymentTypes: {},
  cateringInfo: 0,
  deliveryInfo: 0,
  workingTimes: [],
  deliverers: [],
  createClientIsPending: false,
  updateClientIsPending: false,
  addClientSuccess: null,
};

/* eslint-disable default-case */
const clientsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_CLIENTS:
        draft.clients = action.payload;
        break;
      case types.SET_DELIVERY_PEOPLE:
        draft.deliverers = action.payload;
        break;
      case types.ADD_CLIENT_REQUEST:
        draft.createClientIsPending = true;
        break;
      case types.ADD_CLIENT_SUCCESS:
        draft.addClientSuccess = true;
        draft.createClientIsPending = false;
        break;
      case types.CLEAR_ADD_CLIENT_SUCCESS:
        draft.addClientSuccess = null;
        break;
      case types.ADD_CLIENT_ERROR:
        draft.createClientIsPending = false;
        break;
      case types.SET_USERS:
        draft.users = action.payload;
        break;
      case types.SET_SINGLE_CLIENT:
        draft.singleClient = action.payload;
        break;
      case types.CLEAR_SINGLE_CLIENT:
        draft.singleClient = null;
        break;
      case types.SET_PHOTOS:
        draft.photos = action.payload;
        break;
      case types.UPDATE_CLIENT_REQUEST:
        draft.updateClientIsPending = true;
        break;
      case types.UPDATE_CLIENT_SUCCESS:
      case types.UPDATE_CLIENT_ERROR:
        draft.updateClientIsPending = false;
        break;
      case types.SET_ATTRIBUTES:
        draft.attributes = action.payload;
        break;
      case types.SET_KITCHEN_TYPES:
        draft.kitchenTypes = action.payload;
        break;
      case types.SET_FACILITY_TYPES:
        draft.facilityTypes = action.payload;
        break;
      case types.SET_PAYMENT_TYPES:
        draft.paymentTypes = action.payload;
        break;
      case types.SET_CATERING_INFO:
        draft.cateringInfo = action.payload;
        break;
      case types.CLEAR_CATERING_INFO:
        draft.cateringInfo = 0;
        break;
      case types.SET_DELIVERY_INFO:
        draft.deliveryInfo = action.payload;
        break;
      case types.CLEAR_DELIVERY_INFO:
        draft.deliveryInfo = 0;
        break;
      case types.SET_WORKING_TIMES:
        draft.workingTimes = action.payload;
        break;
      case types.CLEAR_WORKING_TIMES:
        draft.workingTimes = [];
        break;
    }
  });

export default clientsReducer;
