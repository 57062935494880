import { Formik } from "formik";
import { useState } from "react";
import ClientsDropdown from "./clients-dropdown";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FaTimes } from "react-icons/fa";
import CategoriesDropdown from "./categories-dropdown";

export default function AddSection({ setShowAddSection, onSubmit }) {
  const [showClients, setShowClients] = useState(false);
  const [showCategories, setShowCategories] = useState(false);

  const handleSubmit = (values, { setErrors }) => {
    const { name, clients, categories } = values;
    let clientsIds = clients.map((item) => item.id);
    let categoriesIds = categories.map((item) => item.id);
    let payload = {
      name,
      clients: clientsIds,
      categories: categoriesIds,
    };
    onSubmit(payload, setErrors);
  };

  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="relative flex flex-col w-2/3 rounded-lg gap-8 bg-white px-12 pt-12 pb-6 ">
        <span
          className="absolute top-2 right-4 cursor-pointer"
          onClick={() => setShowAddSection(false)}
        >
          &times;
        </span>
        <span className="text-2xl text-center">Dodaj novu sekciju</span>
        <Formik
          initialValues={{ name: "", clients: [], categories: [] }}
          onSubmit={(values, { setErrors, resetForm }) => {
            handleSubmit(values, setErrors);
            //setSubmitting(true);
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Naziv sekcije je obavezno polje";
            }

            return errors;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => {
            // console.log("IS SUBMITTING?", isSubmitting);
            const listOfAddedClients = () => (
              <div className="flex flex-col gap-1 my-1">
                {values.clients.map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center justify-between bg-zinc-100 p-1 rounded-md cursor-pointer text-sm"
                  >
                    {item.company_name}
                    <FaTimes
                      onClick={() =>
                        setFieldValue(
                          "clients",
                          values.clients.filter((el) => el.id !== item.id)
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            );
            const listOfAddedCategories = () => (
              <div className="flex flex-col gap-1 my-1">
                {values.categories.map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center justify-between bg-zinc-100 p-1 rounded-md cursor-pointer text-sm"
                  >
                    {item.name}
                    <FaTimes
                      onClick={() =>
                        setFieldValue(
                          "categories",
                          values.categories.filter((el) => el.id !== item.id)
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            );
            console.log("VALUES: ", values);
            return (
              <form onSubmit={handleSubmit} className="flex flex-col gap-8">
                <div className="flex flex-col">
                  <label>Ime sekcije*</label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    placeholder="Unesi ime sekcije"
                    className="px-4 py-2 border border-black border-opacity-20 rounded-md text-sm "
                  />
                  <p className="text-red-500 text-sm">
                    {errors.name && touched.name && errors.name}
                  </p>
                </div>
                <div className="flex  gap-2">
                  <div className="flex flex-col w-1/2 relative">
                    <div>Klijenti*</div>
                    <div
                      className="flex items-center px-4 py-2 border border-black border-opacity-20 rounded-md text-sm text-black text-opacity-40 cursor-pointer"
                      onClick={() => setShowClients(!showClients)}
                    >
                      Izaberi klijente
                      {showClients ? (
                        <MdKeyboardArrowUp className="ml-auto" />
                      ) : (
                        <MdKeyboardArrowDown className="ml-auto" />
                      )}
                    </div>
                    {showClients && (
                      <ClientsDropdown
                        value={values.clients}
                        setValue={(data) => setFieldValue("clients", data)}
                        setShowClients={setShowClients}
                      />
                    )}
                    {values.clients.length ? listOfAddedClients() : null}
                  </div>
                  <div className="flex flex-col w-1/2 relative">
                    <div>Kategorije*</div>
                    <div
                      className="flex items-center px-4 py-2 border border-black border-opacity-20 rounded-md text-sm text-black text-opacity-40 cursor-pointer"
                      onClick={() => setShowCategories(!showCategories)}
                    >
                      Izaberi kategorije
                      {showCategories ? (
                        <MdKeyboardArrowUp className="ml-auto" />
                      ) : (
                        <MdKeyboardArrowDown className="ml-auto" />
                      )}
                    </div>
                    {showCategories && (
                      <CategoriesDropdown
                        value={values.categories}
                        setValue={(data) => setFieldValue("categories", data)}
                        setShowCategories={setShowCategories}
                      />
                    )}
                    {values.categories.length ? listOfAddedCategories() : null}
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn-primary mx-auto "
                >
                  {/* {isCreatePending ? "Dodavanje..." : "Dodaj"} */}
                  Dodaj
                </button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
