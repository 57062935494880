const KEY = "[CMS_HOME_PAGE_SECTIONS]";

const types = {
  GET_SECTIONS_REQUEST: `${KEY} GET_SECTIONS_REQUEST`,
  GET_SECTIONS_SUCCESS: `${KEY} GET_SECTIONS_SUCCESS`,
  SET_SECTIONS: `${KEY} SET_SECTIONS`,

  GET_SECTION_REQUEST: `${KEY} GET_SECTION_REQUEST`,
  GET_SECTION_SUCCESS: `${KEY} GET_SECTION_SUCCESS`,
  SET_SECTION: `${KEY} SET_SECTION`,
  GET_SECTION_ERROR: `${KEY} GET_SECTION_ERROR`,
  CLEAR_SECTION: `${KEY} CLEAR_SECTION`,

  CREATE_SECTION: `${KEY} CREATE_SECTION`,
  UPDATE_SECTION: `${KEY} UPDATE_SECTION`,
  DELETE_SECTION: `${KEY} DELETE_SECTION`,
  CHANGE_SECTION_ORDER: `${KEY} CHANGE_SECTION_ORDER`,

  CREATE_CLIENT: `${KEY} CREATE_CLIENT`,
  CREATE_CLIENT_ERROR: `${KEY} CREATE_CLIENT_ERROR`,
  DELETE_CLIENT: `${KEY} DELETE_CLIENT`,
  CHANGE_CLIENT_ORDER: `${KEY} CHANGE_CLIENT_ORDER`,

  CREATE_CATEGORY: `${KEY} CREATE_CATEGORY`,
  CREATE_CATEGORY_ERROR: `${KEY} CREATE_CATEGORY_ERROR`,
  DELETE_CATEGORY: `${KEY} DELETE_CATEGORY`,
  CHANGE_CATEGORY_ORDER: `${KEY} CHANGE_CATEGORY_ORDER`,

  SET_ACTIVE_DEVICE: `${KEY} SET_ACTIVE_DEVICE`,
};

export default types;
