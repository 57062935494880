import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../../utils/request";
import {
  addUserError,
  addUserSuccess,
  fetchSingleUserError,
  fetchUsers,
  setSingleUser,
  setUsers,
  updateUserError,
  updateUserSuccess,
} from "./actions";
import types from "./constants";
import { type } from "@testing-library/user-event/dist/type";
import parseApiErrorsToFormik from "../../../utils/parseApiErrorsToFormik";
import { toast } from "react-toastify";
import { enqueueNotification } from "../../../notifications/actions";

const BASE_URL = "admin/users";

export function* getUsers() {
  try {
    const res = yield call(request, {
      url: BASE_URL,
      method: "get",
    });

    yield put(setUsers(res));
  } catch (error) {}
}

export function* getSingleUser({ payload }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${payload}`,
      method: "get",
    });

    yield put(setSingleUser(res.data));
  } catch (error) {
    if (error.status === 404)
      yield put(fetchSingleUserError("Korisnik ne postoji ili je izbrisan."));
  }
}

export function* addUser({ payload, meta: { setErrors } }) {
  try {
    //console.log("SAGA FORMDATA:", formData);
    const res = yield call(request, {
      url: BASE_URL,
      method: "post",
      data: payload,
    });
    yield put(addUserSuccess());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchUsers());
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(addUserError());
  }
}

export function* updateUser({ id, payload, meta: { setErrors } }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${id}`,
      method: "patch",
      data: payload,
    });
    yield put(updateUserSuccess());
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchUsers());
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(
      enqueueNotification({ message: error.data.errors, type: "error" })
    );
    yield put(updateUserError());
  }
}

export function* deleteUser({ payload }) {
  //console.log("INSIDE SAGA DELETE");
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${payload}`,
      method: "delete",
    });
    yield put(
      enqueueNotification({ message: res.data.message, type: "success" })
    );
    yield put(fetchUsers());
  } catch (error) {
    if (error.status === 422) {
      if (error.data.errors.message) {
        yield put();
        //enqueueSnackbar({ message: error.data.errors.message[0] }, "danger")
      }
    }
  }
}

export default function* usersSaga() {
  yield takeLatest(types.FETCH_USERS, getUsers);
  yield takeLatest(types.FETCH_SINGLE_USER, getSingleUser);

  yield takeLatest(types.ADD_USER_REQUEST, addUser);
  yield takeLatest(types.UPDATE_USER_REQUEST, updateUser);
  yield takeLatest(types.DELETE_USER_REQUEST, deleteUser);
}
