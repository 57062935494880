import React from "react";
import { IoCheckmark } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";

const RadioInput = ({
  attribute,
  name,
  value,
  checkedValue,
  onChange,
  labelText,
}) => {
  // console.log(
  //   "ATTRIBUTE NAME:",
  //   name,
  //   "ATTRIBUTE VALUE:",
  //   value,
  //   "ATTRIBUTE CHECKED VALUE",
  //   checkedValue
  // );
  return (
    <label className="custom-option">
      <input
        className="custom_radio"
        type="radio"
        name={name}
        value={value}
        checked={checkedValue === value}
        onChange={() => onChange(name, value, "")}
      />
      <span
        className={`custom-radio flex items-center justify-center ${
          checkedValue === value ? "bg-red-500" : "bg-white"
        }`}
      >
        {checkedValue === value ? (
          value === attribute.values[0].value ? (
            <IoCheckmark
              color="white"
              className="rounded-xl p-[2px]"
              size={20}
            />
          ) : (
            <IoIosClose color="white" className="rounded-xl" size={20} />
          )
        ) : (
          ""
        )}
      </span>
      {labelText}
    </label>
  );
};

export default RadioInput;
