import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectUsers = (state) => state.users || initialState;

export const makeSelectUsers = () =>
  createSelector(selectUsers, (substate) => substate.users);

export const makeSelectSingleUser = () =>
  createSelector(selectUsers, (substate) => substate.singleUser);

export const makeSelectCreateUserIsPending = () =>
  createSelector(selectUsers, (substate) => substate.createUserIsPending);

export const makeSelectUpdateUserIsPending = () =>
  createSelector(selectUsers, (substate) => substate.updateUserIsPending);
