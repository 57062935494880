const KEY = "[CMS_USERS]";

const types = {
  FETCH_USERS: `${KEY} FETCH_USERS`,
  SET_USERS: `${KEY} SET_USERS`,

  FETCH_SINGLE_USER: `${KEY} FETCH_SINGLE_USER`,
  SET_SINGLE_USER: `${KEY} SET_SINGLE_USER`,
  CLEAR_SINGLE_USER: `${KEY} CLEAR_SINGLE_USER`,
  FETCH_SINGLE_USER_ERROR: `${KEY} FETCH_SINGLE_USER_ERROR`,

  ADD_USER_REQUEST: `${KEY} ADD_USER_REQUEST`,
  ADD_USER_SUCCESS: `${KEY} ADD_USER_SUCCESS`,
  ADD_USER_ERROR: `${KEY} ADD_USER_ERROR`,

  UPDATE_USER_REQUEST: `${KEY} UPDATE_USER_REQUEST`,
  UPDATE_USER_SUCCESS: `${KEY} UPDATE_USER_SUCCESS`,
  UPDATE_USER_ERROR: `${KEY} UPDATE_USER_ERROR`,

  DELETE_USER_REQUEST: `${KEY} DELETE_USER_REQUEST`,
};

export default types;
